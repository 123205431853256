import React from "react";

const NumberButton = ({ label, jobAction, className }) => {
  return (
    <div className={className} onClick={jobAction}>
      {label}
    </div>
  );
};

export default NumberButton;
