import React from "react";
import useRequest from "../../../base/hooks/useRequest";
import CrewNameOptions from "../CrewNameOptions";
import Loader from "../Loader";
import SelectAgeOptions from "./SelectAgeOptions";
import SelectContactOptions from "./SelectContactOptions";
import SelectTypeOptions from "./SelectTypeOptions";
import EstimatesStats from "./EstimatesStats";

const FiltersEstSumm = ({ tasks, register }) => {
  //const { data, loading } = useRequest("/api/crews/driver_list");
  const loading = false;
  const data = ["Terrence Hughes", "Joel Caprani"];
  return loading ? (
    <Loader />
  ) : (
    <div className="filters-container">
      <div className="filters">
        <div className="filters--set">
          <SelectTypeOptions register={register} />
          <SelectContactOptions register={register} />
        </div>
      </div>
      <div className="filters__title">
        <EstimatesStats tasks={tasks} />
      </div>
    </div>
  );
};

export default FiltersEstSumm;
