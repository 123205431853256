import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import trash from "../../assets/trashcan.jpg";

const TransactionPanel = ({ tran, onDeleteTransaction }) => {
  const [jobdescClass, setJobdescClass] = useState("is-size-4");

  return (
    <div className="panel p-2">
      <Link to={`/transactions/${tran.id}/edit`}>
        <div className="level">
          <span className="level-item is-size-4">{tran.date}</span>
          <span className="level-item is-size-4">
            {tran.location}
          </span>
          <span className="level-item is-size-4">
            {tran.typeIncome}
          </span>
          <span className="level-item is-size-4">
            ${parseFloat(tran.amount).toFixed(2)}
          </span>
        </div>
      </Link>
      <div className="level">
        <span className={jobdescClass}>
          {tran.description} {tran.notes ? ` : ${tran.notes}` : ""}
        </span>
        <span className={jobdescClass}>
          <img
            className="icon"
            src={trash}
            onClick={() => onDeleteTransaction(tran.id)}
          />
        </span>
      </div>
    </div>
  );
};

export default TransactionPanel;
