import React from "react";
import { Navigate } from "react-router";
import { useStateReducer } from "../../base/context/authContext";
import Loader from "../../base/layout/Loader";
import Header from "./Header";

const Layout = ({ children }) => {
  const [{ user, userLoading }] = useStateReducer();

  if (userLoading) {
    return <Loader />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <title>White Shark Ltd</title>
      <Header />
      <main>{children}</main>
    </div>
  );
};

export default Layout;
