import React from 'react'


const footerStyle = {
    position: 'fixed',
    bottom: '0px',
    flex: '0 0 auto'
}


const Footer = () => {

  return (
    <footer className='footer has-background-info has-text-black is-small mt-6'>
      <div className='container'>
        <div className='columns'>
          <div className='column is-one-third-tablet has-text-black'>
            <p>White Shark</p>
            <p>Window Cleaning</p>
            <p>Eaves Cleaning</p>
            <p>Painting</p>
          </div>
          <div className='column is-one-third-tablet has-text-black'>
            <p><a target="_blank" href="https://vimeo.com/539779658/5deaf1588f" rel="noopener">Window Cleaning (Video)</a></p>
            <p><a target="_blank" href="https://vimeo.com/567523393/36eeeb2e04" rel="noopener">Painting/Odd Jobs/Lawn (Video)</a></p>
          </div>
          <div className='column is-one-third-tablet has-text-black'>
            <p>Locations</p>
            <p>88 Osler St</p>
            <p>36 Morse St</p>
          </div>
        </div>

      </div>
    </footer>

  )
}

export default Footer
