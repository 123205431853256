import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import { formatCurrency, formatDate } from "../../helpers/helper"


const PastJobs = ({pastJobs}) => {

  console.log("Past Jobs: ", pastJobs);

  const showPastJobs = () => {
    const data = pastJobs.map(job =>
      <tr key={ job.jobid }>
        <td>{ job.jobid }</td>
        <td>{ formatDate(job.datebi) }</td>
        <td>{ job.jobdesc }</td>
        <td>{ formatCurrency(job.price) }</td>
        <td>{ job.crewname }</td>
        <td>{ job.minutes }</td>
      </tr>
    )

    return (
      <React.Fragment>
      <div class="box has-background-link">
        <h2 className='subtitle has-text-warning has-text-centered has-text-weight-bold'>Past Job History</h2><br/>
        <table className='table is-striped'>
          <thead>
            <tr>
              <th>Job ID</th>
              <th>Date</th>
              <th>Job Desc</th>
              <th>Price</th>
              <th>Crew</th>
              <th>Minutes</th>
            </tr>
          </thead>
          <tbody>
            { data }
          </tbody>
        </table>
      </div>  
      </React.Fragment>
    )
  }

  return (
    <div>
      { showPastJobs() }
    </div>
  );
};

export default PastJobs;
