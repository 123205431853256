import ActionType from "./actionType";

export const initialState = {
  env: null,
  systemSource: null,
  type: null,
  user: null,
  crew: null,
  email: null,
  mgr: null,
  balance_2023: 0,
  balance_2024: 0,
  props: [],
  jobinfoid: null,
  client: null,
  balance15: null,
  userLoading: true,
  vehicleOptions: [],
  logInClass: "modal is-large is-clipped",
  resetPasswordClass: "modal is-large is-clipped",
  stripePromise: null,
  dashboardShowFunction: null
};

export const stateReducer = (state = initialState, action) => {
  //console.log('PAYLOAD', action.payload);
  switch (action.type) {
    case ActionType.SetBalance2024:
      return {
        ...state,
        balance_2024: action.payload,
      };
    case ActionType.SetBalance2023:
      return {
        ...state,
        balance_2023: action.payload,
      };
    case ActionType.SetEnv:
      return {
        ...state,
        env: action.payload,
      };
    case ActionType.SetSystemSource:
      return {
        ...state,
        systemSource: action.payload,
      };
    case ActionType.SetType:
      return {
        ...state,
        type: action.payload,
      };
    case ActionType.SetUser:
      return {
        ...state,
        user: action.payload,
        userLoading: false,
      };
    case ActionType.SetMgr:
      return {
        ...state,
        mgr: action.payload,
      };
    case ActionType.SetCrew:
      return {
        ...state,
        crew: action.payload,
      };
    case ActionType.SetClient:
      return {
        ...state,
        client: action.payload,
      };
    case ActionType.SetEmail:
      return {
        ...state,
        email: action.payload,
      };

    case ActionType.SetVehicleList:
      return {
        ...state,
        vehicleOptions: action.payload,
      };

    case ActionType.SetLogInClass:
      return {
        ...state,
        logInClass: action.payload,
      };

    case ActionType.SetResetPasswordClass:
      return {
        ...state,
        resetPasswordClass: action.payload,
      };

    case ActionType.SetProps:
      return {
        ...state,
        props: action.payload,
      };

    case ActionType.SetSelectedJobInfoId:
      return {
        ...state,
        jobinfoid: action.payload,
      };

    case ActionType.SetStripePromise:
      return {
        ...state,
        stripePromise: action.payload,
      };

    case ActionType.SetDashboardShowFunction:
      return {
        ...state,
        dashboardShowFunction: action.payload,
      };



    case ActionType.SetUserLoading:
      return {
        ...state,
        userLoading: action.payload,
      };



    case ActionType.Reset:
      return initialState;

    default:
      return state;
  }
};

//export default stateReducer;
