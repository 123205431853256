import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";

import Home from "../layout/Home";
import DailyReport from "../views/DailyReport";

export const history = createBrowserHistory({ basename: "/crews/react_reports" });

const AppRouter = () => (
  <Router history={history}>
    <Routes>
      <Route path="/" component={Home} exact="true" />
      <Route path="/daily_reports" component={DailyReport} exact="true" />
    </Routes>
  </Router>
);

export default AppRouter;
