import React from "react";
import { formatCurrency } from "../helpers/helper";
import dayjs from "dayjs";

const Dnfs = ({ dnfs }) => {
  return (
    <div className="box mb-4">
      <table className="table">
        <thead>
          <tr>
            <th>Address</th>
            <th>DNF Desc</th>
            <th>Start Time</th>
            <th>Finish Time</th>
          </tr>
        </thead>
        <tbody>
          {dnfs.map((dnf) => {
            return (
              <tr key={dnf.dnfid}>
                <td>{ dnf.address }</td>
                <td>{ dnf.dnfdesc }</td>
                <td>{ dnf.reportstime }</td>
                <td>{ dnf.reportftime }</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Dnfs;
