import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import { useStateReducer } from "../../base/context/authContext";
import Layout from "../layout/Layout";
import ApiClient from "../../base/api/client";
import RadioField from "../components/forms/RadioField";

const ReturnSign = (props) => {
  const history = useNavigate();
  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState("");
  const [{ user, userLoading }] = useStateReducer();

  const params = useParams();

  console.log("Params:", params);

  const signForm = useForm({
    defaultValues: { signid: props.match.params.id, user: user },
  });
  const { register, handleSubmit, errors } = signForm;

  useEffect(() => {
    if (user) {
      signForm.setValue("user", user.hrid);
    }
  }, [user]);

  const onSubmit = async (data) => {
    console.log("DATA FROM RETURN SIGN OUTGOING", data);
    console.log("Errors", errors);
    setShowError(false);
    const updatedData = {
      ...data,
      signid: props.match.params.id,
      user: user.hrid,
    };
    console.log("UPDATED_DATA FROM RETURN SIGN OUTGOING", updatedData);
    const result = await ApiClient.patch(
      `/api/tasks/${props.match.params.id}`,
      updatedData
    );
    setMessage(result.message);
  };

  return (
    <Layout>
      <div className="container-medium">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="has-text-weight-bold is-size-3">Return Sign</h2>
          <button className="button is-info" onClick={() => history.goBack()}>
            Go Back
          </button>
          {showError ? <p>Must Select An Option</p> : null}
          {message ? <p className="message">{message}</p> : null}
          <div className="is-flex-direction-column pt-5">
            <div className="control">
              <RadioField
                label="Pick Up Successful And Send Email"
                name="result"
                {...register("result")}
              />
            </div>
            <div className="control">
              <RadioField
                label="Pick Up NOT Successful And Send Email"
                name="result"
                {...register("result")}
              />
            </div>
            <div className="control">
              <RadioField
                label="Pick Up Successful And NO Email"
                name="result"
                {...register("result")}
              />
            </div>
            <div className="control pb-5 ">
              <RadioField
                label="Pick Up NOT Successful And NO Email"
                name="result"
                {...register("result")}
              />
            </div>
            <div className="control">
              <button className="button is-info" type="submit">
                Return Sign
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default ReturnSign;
