import React, { useState, useEffect, useReducer } from "react";
import { useLocation, useParams } from "react-router";
import { Link, Redirect, useNavigate } from "react-router-dom";
import Layout from "../layout/Layout";
import Loader from "../../base/layout/Loader";
import { useStateReducer } from "../../base/context/authContext";
import { useForm } from "react-hook-form";
import { hours, mins } from "../helpers/lists";
import { ErrorMsg } from "../components/ErrorMsg"
import { formatCurrencyNoSign, validateData, validateStartData } from "../helpers/helper";
import dayjs from "dayjs";

import ApiClient from "../../base/api/client";
import CancelJobForm from "./Partner/forms/CancelJobForm";
import RescheduleJobForm from "./Partner/forms/RescheduleJobForm";
import EavesPlusFiveForm from "./Partner/forms/EavesPlusFiveForm";
import PastJobs from "./components/PastJobs"


const JobDetails = () => {

  const [{ crew: state_crew }] = useStateReducer();
  const navigate = useNavigate();

  const params = useParams();
  const [crew, setCrew] = useState("");
  const [job, setJob] = useState("");
  const [pastJobs, setPastJobs] = useState([]);
  const [property, setProperty] = useState("");
  const [cfid, setCfid] = useState("");
  const [client, setClient] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [nextDests, setNextDests] = useState([]);
  const [prices, setPrices] = useState("");
 // const [times, setTimes] = useState(false);
  const [chqNumClass, setChqNumClass] = useState('column is-3 is-5-mobile is-hidden');
  const [errorMsgs, setErrorMsgs] = useState({});
  const [showSubmitButton, setShowSubmitButton] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [message, setMessage] = useState("");

  const [notes, setNotes] = useState("");
  const [jobPics, setJobPics] = useState([]);
  const [gallerySent, setGallerySent] = useState([]);
  const [propertyPics, setPropertyPics] = useState([]);
  const [preJobNote, setPreJobNote] = useState("");
  const [postJobNote, setPostJobNote] = useState("");
  const [holdingCell, setHoldingCell] = useState(false);
  const [showCash, setShowCash] = useState('is-hidden');
  const [showCashAmount, setShowCashAmount] = useState('is-hidden');
  const [email, setEmail] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [unStartClass, setUnStartClass] = useState('button is-danger is-hidden');
  const [unReportClass, setUnReportClass] = useState('button is-danger is-hidden');
  const [cancelClass, setCancelClass] = useState('button is-danger is-hidden');
  const [rescheduleClass, setRescheduleClass] = useState('button is-danger is-hidden');
  const [eavesPlusFiveClass, setEavesPlusFiveClass] = useState('button is-danger is-hidden');
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [showRescheduleForm, setShowRescheduleForm] = useState(false);
  const [showEavesPlusFiveForm, setShowEavesPlusFiveForm] = useState(false);
  const [workMinutes, setWorkMinutes] = useState("");
  const [estMinutes, setEstMinutes] = useState(0);
  const [w1Checked, setW1Checked] = useState("");
  const [w2Checked, setW2Checked] = useState("");
  const [w3Checked, setW3Checked] = useState("");
  const [w4Checked, setW4Checked] = useState("");
  const [ehChecked, setEhChecked] = useState("");
  const [egChecked, setEgChecked] = useState("");
  const [cagesChecked, setCagesChecked] = useState("");
  const [customChecked, setCustomChecked] = useState("");
  const [customDescState, setCustomDescState] = useState("");
  const [startHourPriorState, setStartHourPriorState] = useState("");
  const [startMinutePriorState, setStartMinutePriorState] = useState("");
  const [startHourState, setStartHourState] = useState("");
  const [startMinuteState, setStartMinuteState] = useState("");
  const [finishHourState, setFinishHourState] = useState("");
  const [finishMinuteState, setFinishMinuteState] = useState("");

  const [recStatusState, setRecStatusState] = useState("");
  const [lawnSignState, setLawnSignState] = useState("");
  const [clientHomeState, setClientHomeState] = useState("");
  const [nextDestState, setNextDestState] = useState("");
  const checkBoxFont = "width: '30px'; height: '30px'; background: 'white'; borderRadius: '5px';border: '2px solid #555'";


  const ccForm = useForm();
  const { register, handleSubmit, setValue, getValues } = ccForm;
   const {
  //   startHour,
  //   startMinute,
  //   finishHour,
  //   finishMinute,
       w1Check,
       w2Check, 
       w3Check,
       w4Check, 
       ehCheck,
       egCheck, 
       cagesCheck,
       customCheck, 
   } = getValues();
  
  const pad = 2;

  const fetchJobData = async () => {
    setShowLoader(true);
 //   console.log('HRID  ', params.id);
    const result = await ApiClient.request(`/api/jobs/${params.id}`);
    console.log('Result', result);
    const job_pics = await ApiClient.request(`/api/ben/${params.id}/job_images`);
   // console.log('Job Pics', job_pics);
    setJobPics(job_pics.stuff);
    setGallerySent(job_pics.gallery_sent);
    const property_pics = await ApiClient.request(`/api/ben/${result.property.jobinfoid}/property_images`);
   // console.log('Property Pics', property_pics);
    setPropertyPics(property_pics);
    setJob(result.job);
    setProperty(result.property);
    setCfid(result.cfid);
    setClient(result.client);
    setAddress(result.address);
    setPhone(result.phone);
    setCellPhone(result.cellphone);
    setPrices(result.prices);
    setNextDests(result.nextDests);
    setNotes(result.notes);
    setPreJobNote(result.pre_job_note);
    setPostJobNote(result.post_job_note);
    setHoldingCell(result.holding_cell);
    setEmail(result.email);
    setPastJobs(result.completed_jobs);
    setEstMinutes(result.estMins);
  
    if(result.workTimes.startMinute !== null && result.workTimes.startMinute.toString().length<2){
      result.workTimes.startMinute = '0' + result.workTimes.startMinute.toString();
    }

    if(result.workTimes.finishMinute !== null && result.workTimes.finishMinute.toString().length<2){
      result.workTimes.finishMinute = '0' + result.workTimes.finishMinute.toString();
    }
    setStartHourPriorState(result.workTimes.startHour);
    setStartMinutePriorState(result.workTimes.startMinute);
    setStartHourState(result.workTimes.startHour);
    setStartMinuteState(result.workTimes.startMinute);
    setFinishHourState(result.workTimes.finishHour);
    setFinishMinuteState(result.workTimes.finishMinute);

    setValue("startHour", result.workTimes.startHour)
    setValue("startMinute", result.workTimes.startMinute)
    setValue("startHourPrior", result.workTimes.startHour)
    setValue("startMinutePrior", result.workTimes.startMinute)
    setValue("finishHour", result.workTimes.finishHour)
    setValue("finishMinute", result.workTimes.finishMinute)
    setCustomDescState(result.job.customdesc);

    // if(result.job.recstatus === null){
    //   setValue('recStatus', "");
    // } else {
    //   setValue('recStatus', result.job.recstatus);
    // }
   
    // if(result.job.lawnsign === null){
    //   setValue('lawnSign', "");
    // } else {
    //   setValue('lawnSign', result.job.lawnsign);
    // }

    // if(result.job.clienthm === null){
    //   setValue('clientHome', "");
    // } else {
    //   setValue('clientHome', result.job.clienthm);
    // }

    // if(result.job.nextdestination === null){
    //   setValue('nextDestination', "");
    // } else {
    //   setValue('nextDestination', result.job.nextdestination);
    // }
    console.log("W1 THE VALUE FROM DATABASE", result.job.w1);
    if(result.job.w1 !== null){
      setValue('w1Check', true);
      setW1Checked("checked");
    } else {
      setValue('w1Check', false);
      setW1Checked("");
    }
    if(result.job.w2 !== null){
      setValue('w2Check', true);
      setW2Checked("checked");
    } else {
      setValue('w2Check', false);
      setW2Checked("");
    }
    if(result.job.w3 !== null){
      setValue('w3Check', "checked");
      setW3Checked("checked");
    } else {
      setValue('w3Check', false);
      setW3Checked("");
    }
    if(result.job.w4 !== null){
      setValue('w4Check', "checked");
      setW4Checked("checked");
    } else {
      setValue('w4Check', false);
      setW4Checked("");
    }
    if(result.job.eh !== null){
      setValue('ehCheck', "checked");
      setEhChecked("checked");
    } else {
      setValue('ehCheck', false);
      setEhChecked("");
    }
    if(result.job.eg !== null){
      setValue('egCheck', "checked");
      setEgChecked("checked");
    } else {
      setValue('egCheck', false);
      setEgChecked("");
    }
    if(result.job.custom !== null){
      setValue('customCheck', "checked");
      setCustomChecked("checked");
    } else {
      setValue('customCheck', false);
      setCustomChecked("");
    }
    if(result.job.cages !== null){
      setValue('cagesCheck', "checked");
      setCagesChecked("checked");
    } else {
      setValue('cagesCheck', false);
      setCagesChecked("");
    }
  
   // console.log("EST MINS%%%%%", result.estMins);
    if(!result.job.reportstime){
      setShowSubmitButton(false);
      setButtonText('Start Job First');
    }else{
      setShowSubmitButton(true);
      setButtonText('Return Job');
    }
    setShowLoader(false);
  }

  useEffect(() => {
   // console.log('State CREW in USe Effect:', state_crew);
    if(state_crew){
      setCrew(state_crew.crew_name);
      fetchJobData();
    }
  }, [state_crew])

  // useEffect(() => {
  //   setTimes(false);
  // }, [times])

  useEffect(() => {
    if(prices && job){
      createInitialJobDesc();
    }
    if(startHourState !== "" && startMinuteState !== "" && finishHourState !== "" && finishMinuteState !== ""){
      fillHoursAndPay();
    } else {
      setValue("totMins", "");
    }
  
  }, [prices, job])

  useEffect(() => {
   // console.log("Peanut ", finishHour);
    if(startHourState !== "" && startMinuteState !== "" && finishHourState !== "" && finishMinuteState !== ""){
      fillHoursAndPay();
    } else {
      setValue("totMins", "");
    }
  }, [startHourState, startMinuteState, finishMinuteState, finishHourState])

  useEffect(() => {
      createjobdesc();
  }, [w1Checked, w2Checked, w3Checked, w4Checked, ehChecked, egChecked, customChecked, cagesChecked])

  const fillHoursAndPay = () => {
    // const stime = dayjs().hour(Number(startHour)).minute(Number(startMinute));
    // const ftime = dayjs().hour(Number(finishHour)).minute(Number(finishMinute));
    // const tempWorkMinutes = ftime.diff(stime, "minutes", true).toFixed(0)
    const stime = (parseInt(startHourState) * 60) + parseInt(startMinuteState);
    const ftime = (parseInt(finishHourState) * 60) + parseInt(finishMinuteState);
    console.log("stime minutes", parseInt(startHourState));
    console.log("ftime minutes", ftime);
    if (isNaN(stime) || isNaN(ftime)){
      setWorkMinutes("");
    } else {
      const tempWorkMinutes = ftime - stime;
      setWorkMinutes(tempWorkMinutes);
    }
  };
  
  const createInitialJobDesc = () => {
   //  console.log("Running createInitialJobDesc...")
      // Set Permanent Prices   
      console.log("prices...", prices);   
      (prices && prices.w1!=='nil' && parseFloat(prices.w1)!==0)  ? setValue('w1Text', formatCurrencyNoSign(prices.w1)) : setValue('w1Text', '0.00');
      prices && prices.w2 && parseFloat(prices.w2)!==0  ? setValue('w2Text', formatCurrencyNoSign(prices.w2)) : setValue('w2Text', '0.00');
      prices && prices.w3 && parseFloat(prices.w3)!==0  ? setValue('w3Text', formatCurrencyNoSign(prices.w3)) : setValue('w3Text', '0.00');
      prices && prices.w4 && parseFloat(prices.w4)!==0  ? setValue('w4Text', formatCurrencyNoSign(prices.w4)) : setValue('w4Text', '0.00');
      prices && prices.eh!=='nil' && parseFloat(prices.eh)!==0 ? setValue('ehText', formatCurrencyNoSign(prices.eh)) : setValue('ehText', '0.00');
      prices && prices.eg!=='nil' && parseFloat(prices.eg)!==0 ? setValue('egText', formatCurrencyNoSign(prices.eg)) : setValue('egText', '0.00');

      if(job.w1 !== null) {
        setValue('w1Text', formatCurrencyNoSign(job.w1));
        setW1Checked('checked');
        setValue('w1Check', true);
     
      }
      if(job.w2 !== null) {
        setValue('w2Text', formatCurrencyNoSign(job.w2));
        setW2Checked('checked');
        setValue('w2Check', true);
      }
      if(job.w3 !== null) {
        setValue('w3Text', formatCurrencyNoSign(job.w3));
        setW3Checked('checked');
        setValue('w3Check', true);
      }
      if(job.w4 !== null) {
        setValue('w4Text', formatCurrencyNoSign(job.w4));
        setW4Checked('checked');
        setValue('w4Check', true);
      }
      if(job.eh !== null) {
        setValue('ehText', formatCurrencyNoSign(job.eh));
        setEhChecked('checked');
        setValue('ehCheck', true);
      //  console.log("Just checked EH");
      } 

      if(job.eg !== null) {
        setValue('egText', formatCurrencyNoSign(job.eg));
        setEgChecked('checked');
        setValue('egCheck', true);
      }

      if (job.custom !== null && job.customdesc !== null){
        setCustomChecked("checked");
        setValue('customCheck', true);
        setValue('customText', formatCurrencyNoSign(job.custom));
        setValue('customDesc', job.customdesc);
      }  

      if(job.cages && job.cages === '-1') {
        setCagesChecked("checked")
        setValue('cagesCheck', true);
      }
      setValue('jobDesc', job.jobdesc);

      setValue('estMins', estMinutes);
      setValue('clientHome', job.clienthm ? job.clienthm : 'None Selected');
      setValue('lawnSign', job.lawnsign ? job.lawnsign : 'None Selected');
      setValue('recStatus', 'None Selected');

      // job.w2 && parseFloat(job.w2) > 0 ? setValue('w2Text', job.w2) : setValue('w2Text', '0.00');
      // job.w3 && parseFloat(job.w3) > 0 ? setValue('w3Text', job.w2) : setValue('w3Text', '0.00');
      // job.w4 && parseFloat(job.w4) > 0 ? setValue('w4Text', job.w2) : setValue('w4Text', '0.00');
    }

  const createjobdesc = () => {
  //  console.log("State: ", state);
      let totalPrice = 0.00;
      let oneZeroPrice = false;
      let jobDescription = '';
      
      console.log('w1Check -> ', getValues('w1Check'));
      console.log('w2Check -> ', getValues('w2Check'));
      console.log('w1Checked -> ', getValues('w1Checked'));
      console.log('w2Checked -> ', getValues('w2Checked'));
      console.log('__________________________________');
    //  console.log('ehChecked -> ', ehChecked);
    //  console.log('customChecked -> ', customChecked);

      if(w1Checked === 'checked'){
        let w1Price = getValues('w1Text');
        if(parseFloat(prices.w1) >0){
          setValue('w1Text', formatCurrencyNoSign(prices.w1))
        }
        if(formatCurrencyNoSign(w1Price) === "0.00" || w1Price === null){
          oneZeroPrice = true;
        } else {
          totalPrice += parseFloat(w1Price);
        }
        (jobDescription === '' ? jobDescription = 'W1' : jobDescription += ' + W1');
      }
      if(w2Checked === 'checked'){
        const w2Price = getValues('w2Text');
        formatCurrencyNoSign(w2Price) === "0.00" ? oneZeroPrice = true : totalPrice += parseFloat(w2Price);
        (jobDescription === '' ? jobDescription = 'W2' : jobDescription += ' + W2');
      }
      if(w3Checked === 'checked'){
        const w3Price = getValues('w3Text');
        formatCurrencyNoSign(w3Price) === "0.00" ? oneZeroPrice = true :totalPrice += parseFloat(w3Price);
        (jobDescription === '' ? jobDescription = 'W3' : jobDescription += ' + W3');
      }
      if(w4Checked === 'checked'){
        const w4Price = getValues('w4Text');
        (w4Price && formatCurrencyNoSign(w4Price) === "0.00") ? oneZeroPrice = true : totalPrice += parseFloat(w4Price);
        (jobDescription === '' ? jobDescription = 'W4' : jobDescription += ' + W4');
      }
      if(ehChecked === 'checked'){
        let ehPrice = getValues('ehText');
        if(parseFloat(prices.eh) >0){
           setValue('ehText', formatCurrencyNoSign(prices.eh))
        }
        if(formatCurrencyNoSign(ehPrice) === "0.00"){
          oneZeroPrice = true;
        } else {
          totalPrice += parseFloat(ehPrice);
        } 
        (jobDescription === '' ? jobDescription = 'EH' : jobDescription += ' + EH');
      }
      if(egChecked === 'checked'){
        const egPrice = getValues('egText');
        if(formatCurrencyNoSign(egPrice) === "0.00"){
          oneZeroPrice = true;
        } else {  
         totalPrice += parseFloat(egPrice);
        }
        (jobDescription === '' ? jobDescription = 'EG' : jobDescription += ' + EG');
      }
      if(customChecked === 'checked'){
        let priceCustom = getValues('customText');

        setCustomDescState(getValues('customDesc'));    

        if(formatCurrencyNoSign(priceCustom) === "0.00"){
          oneZeroPrice = true 
        } else {
          console.log("Adding custom price...")
          totalPrice += parseFloat(priceCustom);
        } 
        (jobDescription === '' ? jobDescription = '[Custom]' + getValues('customDesc') : jobDescription += ' + ' + getValues('customDesc'));
      }

      if(cagesChecked === 'checked'){
        (jobDescription === '' ? (jobDescription = 'Install cages(to be supplied by client)') : (jobDescription += ' + ' + 'install cages(to be supplied by client)'));
      }

      if(oneZeroPrice){
        totalPrice = 0;
      }
      setValue('jobDesc', jobDescription);
      setValue('price', formatCurrencyNoSign(totalPrice));
      setValue('hst', formatCurrencyNoSign(totalPrice * .13));
      setValue('totalPrice', formatCurrencyNoSign(totalPrice * 1.13));
  }

  const onChangeMe = (e) => {
    console.log("In On CHANGE YAYYYY", e.target.name + '  ' + e.target.value);
   // console.log("First W1 Checked", w1Checked);
    const value = e.target.value
    if(e.target.name==='w1Check'){
      (w1Checked === 'checked'  ? setW1Checked('') : setW1Checked('checked'));
      (w1Check ? setValue("w1Check", false) : setValue('w1Check', true));
    } else if(e.target.name==='w2Check'){
      (w2Checked==='checked' ? setW2Checked('') : setW2Checked('checked'));
      (w2Check ? setValue("w2Check", false) : setValue('w2Check', true));
    } else if(e.target.name==='w3Check'){
      (w3Checked==='checked' ? setW3Checked('') : setW3Checked('checked'));
      (w3Check ? setValue("w3Check", false) : setValue('w3Check', true));
    } else if(e.target.name==='w4Check'){
      (w4Checked==='checked' ? setW4Checked('') : setW4Checked('checked'));
      (w4Check ? setValue("w4Check", false) : setValue('w4Check', true));
    } else if(e.target.name==='ehCheck'){
      (ehChecked==='checked' ? setEhChecked('') : setEhChecked('checked'));
      (ehCheck ? setValue("ehCheck", false) : setValue('ehCheck', true));
    } else if(e.target.name==='egCheck'){
      (egChecked==='checked' ? setEgChecked('') : setEgChecked('checked'));
      (egCheck ? setValue("egCheck", false) : setValue('egCheck', true));
    } else if(e.target.name==='cagesCheck'){
      (cagesChecked==='checked' ? setCagesChecked('') : setCagesChecked('checked'));
      (cagesCheck ? setValue("cagesCheck", false) : setValue('cagesCheck', true));
    } else if(e.target.name==='customCheck'){
      (customChecked==='checked' ? setCustomChecked('') : setCustomChecked('checked'));
      (customCheck ? setValue("customCheck", false) : setValue('customCheck', true));
    } else if(e.target.name==='customDesc'){
        setCustomDescState(e.target.value);
        setValue("customDesc", e.temp.value)
    } else if(e.target.name==='startHourPrior'){
        setStartHourPriorState(e.target.value);
        setStartHourState(e.target.value);
    } else if(e.target.name==='startMinutePrior'){
        setStartMinutePriorState(e.target.value);
        setStartMinuteState(e.target.value);
    } else if(e.target.name==='startHour'){
        setStartHourPriorState(e.target.value);
        setStartHourState(e.target.value);
    } else if(e.target.name==='startMinute'){
        setStartMinutePriorState(e.target.value);
        setStartMinuteState(e.target.value);
    } else if(e.target.name==='finishMinute'){
        setFinishMinuteState(e.target.value);
    } else if(e.target.name==='finishHour'){
      //  console.log("Setting Finish Hour to ", e.target.value);
        setFinishHourState(e.target.value);
    }
  //  fillHoursAndPay();
  //  setTimes(true);
  };

  const editRecStatus = (e) => {
    const recStatus55 = e.target.value; //getValues('recStatus');
    if(recStatus55.includes('Cheque')){
      setChqNumClass('column is-3 is-5-mobile');
    } else {
      setChqNumClass('column is-3 is-5-mobile is-hidden');
    }
    if(recStatus55.includes('Cash')){
      setShowCash('column is-3 is-5-mobile');
      setShowCashAmount('column is-3 is-5-mobile');
    }else{
      setShowCash('is-hidden');
      setShowCashAmount('is-hidden');
    }
  }

  const nextDestOptions = () => {
    if(nextDests){
      return [<option key="None Selected" value="None Selected">None Selected</option>].concat(nextDests.slice(1).map((dest) => <option key={dest.jobid} value={dest.jobid}>{dest.address}[{dest.num}]-{dest.type}</option>));
    }
  }

  const sendStartJobData = async (updatedData) => {
  //  console.log('UPDATED DATA: ', updatedData);
    const result = await ApiClient.patch(`/api/jobs/${job.jobid}/start_job`, updatedData);
    const msg = 'Job at ' + address + ' started successfully';
    setMessage("");
    navigate(`/runsheet/${msg}`);
  }
  

  const startJob = (data) => {
    let temp = validateStartData(data);
    var count= Object.keys(temp).length;
    if(count===0){
      let updatedData = { ...data, hrid: state_crew.crew_hrid, jobid: job.jobid };
      sendStartJobData(updatedData)
    }else{
      setErrorMsgs(temp);
    }
  }

  const sendReturnJobData = async (updatedData) => {
    const result = await ApiClient.patch(`/api/jobs/update`, updatedData);
    let msg = 'Job at ' + address + ' returned successfully';
    if(result.cash_email){
      msg = 'Job at ' + address + ' returned and cash receipt email successfully';
    }
    setMessage("");
    navigate(`/runsheet/${msg}`);
  }

  const returnJob = (data) => {
    console.log("DATA: ", data);
    let temp = validateData(data, workMinutes);
    var count= Object.keys(temp).length;
    if(count===0){
      let updatedData = { ...data, hrid: state_crew.crew_hrid, jobid: job.jobid };
      sendReturnJobData(updatedData)
    }else{
      console.log("Errors...", temp);
      setErrorMsgs(temp);
    }
  }

  const showNotes = () => {
    const notesData = () => (notes.map(note => <tr key={note.id}>
      <td>{ note.notes }</td>
      <td>{ note.id10 }</td>
        <td>{ dayjs(note.ts).format('MMM DD, YYYY hh:mm') }</td>
      <td>{ note.recorder }</td>
    </tr>));

    return <section className="hero is-info">
        <div className="hero-body">
          <p className="title">
            Notes(client, property, job)
          </p>
          <div className='table-container'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Note</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Recorder</th>
                </tr>
              </thead>
              <tbody>
                { notesData() }
              </tbody>
            </table>
          </div>
        </div>
      </section>
  }

  const checkUnstartJob = () => {
    setUnStartClass(unStartClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
  }

  const unstartJob = async () => {
    const result = await ApiClient.request(`/api/jobs/${job.jobid}/unstart_job`);
    let msg = 'Job at ' + address + ' unstarted successfully';
    if(!result.success){
      msg = 'Job at ' + address + ' unstart FAILED, call Nat/Roger';
    }
    setMessage("");
    navigate(`/runsheet/${msg}`);
  }


  const checkUnreportJob = () => {
    setUnReportClass(unReportClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
  }

  const unreportJob = async () => {
    const result = await ApiClient.request(`/api/jobs/${job.jobid}/unreport_job`);
    let msg = 'Job at ' + address + ' unreported successfully';
    if(!result.success){
      msg = 'Job at ' + address + ' unreported FAILED, call Nat/Roger';
    }
    navigate(`/runsheet/${msg}`);
  }

  const checkCancelJob = () => {
    setCancelClass(cancelClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
    setShowCancelForm(true);
  }

  const handleCancelJob = async (data) => {
   // console.log('Data', data);
    setShowCancelForm(false);
    setCancelClass(cancelClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
    //    const result = await ApiClient.request(`/api/jobs/${job.jobid}/cancel_job`);
    //    navigate(`/runsheet/${result.msg}`);

    //let updatedData = { ...data, hrid: state_crew.crew_hrid, jobid: job.jobid };

    const result = await ApiClient.post(`/api/jobs/cancel_job`, data);
    navigate(`/runsheet/${result.msg}`);
  }

  const cancelCancelRequest = async () => {
    setShowCancelForm(false);
    setCancelClass(cancelClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
  }



  const checkEavesPlusFive = () => {
    setEavesPlusFiveClass(eavesPlusFiveClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
    setShowEavesPlusFiveForm(true);
  }

  const handleEavesPlusFive = async (data) => {
   // console.log('Data', data);
    setShowEavesPlusFiveForm(false);
    setEavesPlusFiveClass(eavesPlusFiveClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
    //    const result = await ApiClient.request(`/api/jobs/${job.jobid}/cancel_job`);
    //    navigate(`/runsheet/${result.msg}`);

    let updatedData = { ...data, hrid: state_crew.crew_hrid };

    const result = await ApiClient.post(`/api/jobs/eaves_plus_five_job`, updatedData);
    navigate(`/runsheet/${result.msg}`);
  }

  const cancelEavesPlusFive = async () => {
    setShowEavesPlusFiveForm(false);
    setEavesPlusFiveClass(eavesPlusFiveClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
  }



  const checkRescheduleJob = () => {
    setRescheduleClass(rescheduleClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
    setShowRescheduleForm(true);
  }

  const handleRescheduleJob = async (data) => {
   // console.log('Data', data);
    setShowRescheduleForm(false);
    setRescheduleClass(rescheduleClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
    //const result = await ApiClient.request(`/api/jobs/${job.jobid}/reschedule_job`);
    // let msg = 'Job at ' + address + ' rescheduled successfully';
    // if(!result.success){
    //   msg = 'Job at ' + address + ' reschedule FAILED, call Nat/Roger';
    // }
    const result = await ApiClient.post(`/api/jobs/reschedule_job`, data);
    navigate(`/runsheet/${result.msg}`);
  }

  const cancelRescheduleRequest = async () => {
    setShowRescheduleForm(false);
    setRescheduleClass(rescheduleClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
  }

  const showJobPics = () => {
   // console.log("In Job Pics");
    const job_pic_images = jobPics.map(pic => (
      <div key={ job.jobid + pic.created_at} className='column is-one-third'>
        <div className="card">
          <div className="card-image">
            <figure className="image is-4by3">
            <img src={pic.image_url} alt="Job Image" height='500' width="300"/>
            </figure>
          </div>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-4">{ pic.kind }</p>
                <p className="title is-4">{ pic.title }</p>
                <hr/>
                <time dateTime="2016-1-1">{ pic.created_at }</time>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))

    return(
      <div>
        <br/>
        <div className='columns is-multiline'>
          { job_pic_images }
        </div>
        <br/>
        </div>  
    )
  }

  const showPropertyPics = () => {
   // console.log("In Property Pics");
    const property_pic_images = propertyPics.map(pic => (
      <div key={ pic.created_at } className='column is-one-third'>
        <div className="card">
          <div className="card-image">
            <figure className="image is-4by3">
            <img src={pic.image_url} alt="Property Image" height='500' width="300"/>
            </figure>
          </div>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-4">{ pic.kind }</p>
                <p className="title is-4">{ pic.title }</p>
                <hr/>
                <time dateTime="2016-1-1">{ pic.created_at }</time>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))

    return(
      <div>
        <br/>
        <div className='columns is-multiline'>
          { property_pic_images }
        </div>
        <br/>
      </div>
    )
  }

  const sendGalleryLink = async () => {
    let updatedData = { jobid: job.jobid, hrid: state_crew.crew_hrid };
  //  console.log('Data', updatedData);

    const result = await ApiClient.post(`/api/jobs/send_link_to_gallery`, updatedData);
    //navigate(`/job-details/${job.jobid}`);
    setMessage(result.msg);
    if(result.num !== '0'){
      setGallerySent(true);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });

  }

  return (
    <Layout>
      <div className='section'>
        <div className="container content">
          <nav className='breadcrumb'>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li>
                <a href="/menu_items/crew_menu">
                  Back to Crew Menu
                </a>
              </li>
            <li><Link to="/runsheet">Runsheet</Link></li>
              <li className='is-active'><Link to={`/job-details/${job && job.jobid}`}>Job Details</Link></li>
            </ul>
          </nav>

          <div className="box has-background-light">
            {message ? <h3 className='title is-4 has-text-success has-text-centered'>{message}</h3> : null}
            <h2 className='title has-text-centered'>{crew}</h2>
            <hr/>
            <h3 className='subtitle is-4'>{client}</h3>
            <h3 className='subtitle is-4'>{address}</h3>
            <h3 className='subtitle is-4'>Ph:{phone}</h3>
            <h3 className='subtitle is-4'>Cell:{cellPhone}</h3>
                { job && job.prodcomm === '**Emergency**' ? <h3 className='subtitle is-4 has-text-danger'>**Emergency**</h3> : null }
            { job && holdingCell ? <h3 className='subtitle is-4 has-text-link'>**Holding Cell**</h3> : null }
          </div><br/>

          <div className="box">
            { notes && showNotes() }
          </div><br/>

          <div className="box">
            {property &&
            <u>
              <a className="button is-link" href={`/property_images?property_id=${property.jobinfoid}&source=react_job_details&delete_source=react_job_details&jobid=${job.jobid}`}>
                Add Property Image
              </a>
            </u>}
            { property && propertyPics && showPropertyPics() }
          </div><br/> 

          <div className="box">
            <div className='columns'>
              <div className='column'>
              {
                job &&
                <u>
                  <a className="button is-link" href={`/job_images?job_id=${job.jobid}&source=react_job_details&delete_source=react_job_details`}>
                    Add Job Image
                  </a>
                </u>
              }
              </div>
              <div className='column'>
                {job && (jobPics.length > 0) &&
                  <button className={ gallerySent ? "button is-danger" : "button is-link"} onClick={ sendGalleryLink }>
                    { gallerySent ? 'Email Job Images Gallery AGAIN[to Client!]' : 'Email Job Images Gallery[to Client!]'}
                  </button>
                }
                {job && (jobPics.length == 0) &&
                  <button className="button is-link" disabled onClick={ sendGalleryLink }>
                    Email Job Images Gallery(to client)
                  </button>
                }
              </div>
              <div className='column'>
              </div>
              <div className='column'>
              </div>
              { job && jobPics && showJobPics() }
            </div>
          </div><br/>


          <div class="box">
            <div className="columns is-multiline has-background-primary">
              <div className="column">
                <p className="control">
                  { job && job.reportstime!==null && job.reportstime!=='99:99:99' ?
                  <button className="button is-link" onClick={ checkUnstartJob }>
                    Unstart Job
                  </button> :
                  <button className="button is-link" disabled onClick={ checkUnstartJob }>
                    Unstart Job
                  </button>
                  }

                </p><br/>
                <p className="control">
                  <button className={unStartClass} onClick={ unstartJob }>
                    Confirm Unstart Job
                  </button>
                </p>
              </div>
              <div className="column">
                <p className="control">
                  { job && job.datebi !== null ?
                    <button className="button is-link" onClick={ checkUnreportJob }>
                      Unreport Job
                    </button> :
                    <button className="button is-link" disabled onClick={ checkUnreportJob }>
                      Unreport Job
                    </button>
                  }
                </p><br/>
                <p className="control">
                  <button className={unReportClass} onClick={ unreportJob }>
                    Confirm Unreport Job
                  </button>
                </p>
              </div>
              <div className="column">
                <p className="control">
                  { job && job.datebi === null ?
                    <button className="button is-link" onClick={ checkCancelJob }>
                      Cancel Job
                    </button> :
                    <button className="button is-link" disabled onClick={ checkCancelJob }>
                      Cancel Job
                    </button>                }
                </p><br/>
                <p className="control">
                  <button className={cancelClass} onClick={ cancelCancelRequest }>
                    Cancel Cancel Request
                  </button>
                </p>
              </div>
              <div className="column">
                <p className="control">
                  { job && job.datebi === null ?
                    <button className="button is-link" onClick={ checkRescheduleJob }>
                      Reschedule Job
                    </button> :
                    <button className="button is-link" disabled onClick={ checkRescheduleJob }>
                      Reschedule Job
                    </button>
                  }
                </p><br/>
                <p className="control">
                  <button className={rescheduleClass} onClick={ cancelRescheduleRequest }>
                    Cancel Reschedule Request
                  </button>
                </p>
              </div>
              <div className="column">
                <p className="control">
                  { job && job.datebi === null ?
                    <button className="button is-link" onClick={ checkEavesPlusFive }>
                      Eaves +5 days
                    </button> :
                    <button className="button is-link" disabled onClick={ checkEavesPlusFive }>
                      Eaves +5 days
                    </button>
                  }
                </p><br/>
                <p className="control">
                  <button className={eavesPlusFiveClass} onClick={ cancelEavesPlusFive }>
                    Cancel Eaves +5
                  </button>
                </p>
              </div>
            </div>
            { showCancelForm && <CancelJobForm handleCancelJob={handleCancelJob} jobid={job.jobid} />}
            { showRescheduleForm && <RescheduleJobForm handleRescheduleJob={handleRescheduleJob} jobid={job.jobid} />}
            { showEavesPlusFiveForm && <EavesPlusFiveForm handleEavesPlusFive={handleEavesPlusFive} jobid={job.jobid} />}
          </div><br/>

          <div className="box has-background-primary">
            <form className='form' onSubmit={handleSubmit(startJob)}>
              <h3 class="subtitle has-text-centered">Start Job</h3>
              <div className='columns multiline'>
                <div className='column is-half'>
                  <div className='field'>
                    <div className='control'>
                      <label className='label is-size-5' htmlFor='start_label'>Start Time:</label>
                      <div className='select' id='start-label'>
                        <select 
                          {...register("startHourPrior")} 
                          value={startHourPriorState}
                          onChange={onChangeMe} 
                        >
                          { hours.map((hour)=>(<option key={hour} value={hour}>{hour}</option>)) }
                        </select>
                      </div> :
                      <div className='select' >
                        <select 
                          {...register("startMinutePrior")} 
                          value={startMinutePriorState}
                          onChange={onChangeMe} 
                        >
                          { mins.map((min)=>(<option key={min} value={min}>{min}</option>)) }
                        </select>
                      </div>
                    </div>
                    <ErrorMsg msg={errorMsgs.startHourPriorCannotBeBlank} num={ Object.keys(errorMsgs).length }/>
                    <ErrorMsg msg={errorMsgs.startMinutePriorCannotBeBlank} num={ Object.keys(errorMsgs).length }/>
                  </div>
                <div className='field'>
                  <div className='control'>
                    <label className='label is-size-5' htmlFor='estmins_label'>Estimated Mins:</label>
                    <input 
                      id='estmins_label' 
                      size='10' 
                      className='input' 
                      type="text" 
                      {...register("estMins")}
                    />
                  </div>
                  <ErrorMsg msg={errorMsgs.estMinsEntered} num={ Object.keys(errorMsgs).length }/>
                  <ErrorMsg msg={errorMsgs.estMins} num={ Object.keys(errorMsgs).length }/>
                  <ErrorMsg msg={errorMsgs.estMinsGreaterThanZero} num={ Object.keys(errorMsgs).length }/>
                </div>
                <div className='field'>
                  <div className='control'>
                    <label className='label is-size-5' htmlFor='preJobNotes'>Pre Job Note:</label>
                    <textarea
                      className='textarea'
                      rows='3'
                      placeholder='Enter pre-job notes(optional)...'
                      {...register("preJobNote")}
                      defaultValue={ preJobNote }
                    />
                  </div>
                </div>
                </div>
              </div>

              <button name='start_job' type='submit' className='button is-link is-large is-rounded is-fullwidth has-text-weight-bold'>Start Job</button>
            </form><br/>
          </div><br/>

          <div className="box has-background-info">
            <h3 class="subtitle has-text-centered">Report Job</h3>
            <form className='form' onSubmit={handleSubmit(returnJob)}>
              <div className='columns multiline'>
                <div className='column is-3'>
                  <div className='field'>
                    <div className='control'>
                      <label className='label is-size-5' htmlFor='start_label'>Start Time:</label>
                      <div className='select is-small' id='start-label'>
                        <select 
                          {...register("startHour")} 
                          value={startHourState}
                          onSelect={onChangeMe} 
                        >
                          { hours.map((hour)=>(<option key={hour} value={hour}>{hour}</option>)) }
                        </select>
                      </div> :
                      <div className='select is-small' >
                        <select 
                          {...register("startMinute")} 
                          value={startMinuteState}
                          onChange={onChangeMe} 
                        >
                          { mins.map((min)=>(<option key={min} value={min}>{pad && min!=='' ? String(min).padStart(pad, "0") : min}</option>)) }
                        </select>
                      </div>
                    </div>
                    <ErrorMsg msg={errorMsgs.startHourCannotBeBlank} num={ Object.keys(errorMsgs).length-1 }/>
                    <ErrorMsg msg={errorMsgs.startMinuteCannotBeBlank} num={ Object.keys(errorMsgs).length-1 }/>
                  </div>
                </div>


                <div className='column is-3'>
                  <div className='field'>
                    <div className='control'>
                      <label className='label is-size-5' htmlFor='finish_label'>Finish Time:</label>
                      <div className='select is-small' id='finish_label'>
                        <select 
                          {...register("finishHour")} 
                          value={finishHourState}
                          onChange={onChangeMe} 
                        >
                          { hours.map((hour)=>(<option key={hour} value={hour}>{hour}</option>)) }
                        </select>
                      </div> :
                      <div className='select is-small' >
                        <select  
                          {...register("finishMinute")} 
                          value={finishMinuteState}
                          onChange={onChangeMe} 
                        >
                          { mins.map((min)=>(<option key={min} value={min}>{pad && min!=='' ? String(min).padStart(pad, "0") : min}</option>)) }
                        </select>
                      </div>
                    </div>
                    <ErrorMsg msg={errorMsgs.finishHourCannotBeBlank} num={ Object.keys(errorMsgs).length-1 }/>
                    <ErrorMsg msg={errorMsgs.finishMinuteCannotBeBlank} num={ Object.keys(errorMsgs).length-1 }/>
                  </div>
                </div>

                <div className='column is-2 is-5-mobile'>
                  <div className='field'>
                    <div className='control'>
                      <label className="label is-size-5" htmlFor='totmins'>Total Minutes</label>
                      <input 
                        id='totmins' 
                        size='10' 
                        className='input is-small' 
                        type="text" 
                        value={workMinutes} 
                        {...register("totMins")}
                        readOnly 
                      />
                    </div>
                  </div>
                  <ErrorMsg msg={errorMsgs.workMinutesGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
                </div>
              </div>

              <div className='columns multiline'>
                <div className='column'>
                  <div className='field'>
                    <div className='control'>
                      <label className="checkbox">
                        <div className="level-left">
                          <input 
                            type="checkbox" 
                            checked={w1Checked} 
                            style={{width: '30px', height: '30px', background: 'white', borderRadius: '5px', border: '2px solid #555'}}
                            className="level-item"
                            {...register("w1Check")}
                            onClick={onChangeMe} 
                          />
                          <span className="level-item has-text-weight-bold is-size-6 ">W1</span>
                        </div>
                        { w1Checked==='checked' ?
                        <input
                          type='text'
                          className='input'
                          {...register("w1Text")}
                          onKeyUp={ ()=>createjobdesc(false) }
                        /> :
                        <input
                          type='text'
                          className='input'
                          {...register("w1Text")}
                          onKeyUp={ ()=>createjobdesc(false) }
                          disabled
                        />
                      }
                      </label>
                    </div>
                    <ErrorMsg msg={errorMsgs.w1TextMustBeANumber} num={ Object.keys(errorMsgs).length-1 }/>
                    <ErrorMsg msg={errorMsgs.w1TextMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
                  </div>
                </div>

                <div className='column'>
                  <div className='field'>
                    <div className='control'>
                      <label className="checkbox">
                        <div className="level-left">
                          <input 
                            type="checkbox" 
                            checked={w2Checked} 
                            style={{width: '30px', height: '30px', background: 'white', borderRadius: '5px', border: '2px solid #555'}}
                            className="level-item"
                            {...register("w2Check")}
                            onClick={onChangeMe} 
                          />
                          <span className="level-item has-text-weight-bold is-size-5 ">W2</span>
                        </div>

                        { w2Checked==='checked' ?
                        <input
                          type='text'
                          className='input'
                          {...register("w2Text")}
                          onKeyUp={ ()=>createjobdesc(false) }
                        /> :
                        <input
                          type='text'
                          className='input'
                          {...register("w2Text")}
                          onKeyUp={ ()=>createjobdesc(false) }
                          disabled
                        /> }
                      </label>
                    </div>
                    <ErrorMsg msg={errorMsgs.w2TextMustBeANumber} num={ Object.keys(errorMsgs).length-1 }/>
                    <ErrorMsg msg={errorMsgs.w2TextMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
                  </div>
                </div>

                <div className='column'>
                  <div className='field'>
                    <div className='control'>
                      <label className="checkbox">
                      <div className="level-left">
                          <input 
                            type="checkbox" 
                            checked={w3Checked} 
                            style={{width: '30px', height: '30px', background: 'white', borderRadius: '5px', border: '2px solid #555'}}
                            className="level-item"
                            {...register("w3Check")}
                            onClick={onChangeMe} 
                          />
                          <span className="level-item has-text-weight-bold is-size-5 ">W3</span>
                        </div>
                        { w3Checked==='checked' ?
                        <input
                          type='text'
                          className='input'
                          {...register("w3Text")}
                          onKeyUp={ ()=>createjobdesc(false) }
                        /> :
                        <input
                          type='text'
                          className='input'
                          {...register("w3Text")}
                          onKeyUp={ ()=>createjobdesc(false) }
                          disabled
                          /> }
                      </label>
                    </div>
                    <ErrorMsg msg={errorMsgs.w3TextMustBeANumber} num={ Object.keys(errorMsgs).length-1 }/>
                    <ErrorMsg msg={errorMsgs.w3TextMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
                  </div>
                </div>

                <div className='column'>
                  <div className='field'>
                    <div className='control'>
                      <label className="checkbox">
                      <div className="level-left">
                          <input 
                            type="checkbox" 
                            checked={w4Checked} 
                            style={{width: '30px', height: '30px', background: 'white', borderRadius: '5px', border: '2px solid #555'}}
                            className="level-item"
                            {...register("w4Check")}
                            onClick={onChangeMe} 
                          />
                          <span className="level-item has-text-weight-bold is-size-5 ">W4</span>
                        </div>
                        { w4Checked==='checked' ?
                        <input
                          type='text'
                          className='input'
                          {...register("w4Text")}
                          onKeyUp={ ()=>createjobdesc(false) }
                          /> :
                        <input
                          type='text'
                          className='input'
                          {...register("w4Text")}
                          onKeyUp={ ()=>createjobdesc(false) }
                          disabled
                          /> }
                      </label>
                    </div>
                    <ErrorMsg msg={errorMsgs.w4TextMustBeANumber} num={ Object.keys(errorMsgs).length-1 }/>
                    <ErrorMsg msg={errorMsgs.w4TextMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
                  </div>
                </div>
              </div>

              <div className='columns multiline'>
                <div className='column is-one-quarter-tablet'>
                  <div className='field'>
                    <div className='control'>
                      <label className="checkbox">
                        <div className="level-left">
                          <input 
                            type="checkbox" 
                            checked={ehChecked} 
                            style={{width: '30px', height: '30px', background: 'white', borderRadius: '5px', border: '2px solid #555'}}
                            className="level-item"
                            {...register("ehCheck")}
                            onClick={onChangeMe} 
                          />
                          <span className="level-item has-text-weight-bold is-size-5 ">EH</span>
                        </div>
                          { ehChecked==='checked' ?
                          <input
                            type='text'
                            className='input'
                            {...register("ehText")}
                            onKeyUp={ ()=>createjobdesc(false) }
                            /> :
                          <input
                            type='text'
                            className='input'
                            {...register("ehText")}
                            onKeyUp={ ()=>createjobdesc(false) }
                            disabled
                            /> }
                      </label>
                    </div>
                    <ErrorMsg msg={errorMsgs.ehTextMustBeANumber} num={ Object.keys(errorMsgs).length-1 }/>
                    <ErrorMsg msg={errorMsgs.ehTextMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
                  </div>
                </div>

                <div className='column is-one-quarter-tablet'>
                  <div className='field'>
                    <div className='control'>
                      <label className="checkbox">
                        <div className="level-left">
                          <input 
                            type="checkbox" 
                            checked={egChecked} 
                            style={{width: '30px', height: '30px', background: 'white', borderRadius: '5px', border: '2px solid #555'}}
                            className="level-item"
                            {...register("egCheck")}
                            onClick={onChangeMe} 
                          />
                          <span className="level-item has-text-weight-bold is-size-5 ">EG</span>
                        </div>  
                        { egChecked==='checked' ?
                        <input
                          type='text'
                          className='input'
                          {...register("egText")}
                          onKeyUp={ ()=>createjobdesc(false) }
                          /> :
                        <input
                          type='text'
                          className='input'
                          {...register("egText")}
                          onKeyUp={ ()=>createjobdesc(false) }
                          disabled
                          /> }
                      </label>
                    </div>
                    <ErrorMsg msg={errorMsgs.egTextMustBeANumber} num={ Object.keys(errorMsgs).length-1 }/>
                    <ErrorMsg msg={errorMsgs.egTextMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
                  </div>
                </div>

                <div className='column is-half-tablet'>
                  <div className='field'>
                    <div className='control'>
                      <label className="checkbox">
                      <div className="level-left">
                          <input 
                            type="checkbox" 
                            checked={cagesChecked} 
                            style={{width: '30px', height: '30px', background: 'white', borderRadius: '5px', border: '2px solid #555'}}
                            className="level-item"
                            {...register("cagesCheck")}
                            onClick={onChangeMe} 
                          />
                          <span className="level-item has-text-weight-bold is-size-5 ">Cages</span>
                        </div>  
                        { cagesChecked ?
                        <div className='columns'>
                          <div className='column is-four-fifths is-size-5'>
                            Install cages(to be supplied by client)
                          </div>
                        </div> :
                        <div className='columns'>
                          <div className='column is-four-fifths is-size-5'>
                            Install cages(to be supplied by client)
                          </div>
                        </div>
                      }
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className='field'>
                <div className='control'>
                  <label className="checkbox">
                  <div className="level-left">
                          <input 
                            type="checkbox" 
                            checked={customChecked} 
                            style={{width: '30px', height: '30px', background: 'white', borderRadius: '5px', border: '2px solid #555'}}
                            className="level-item"
                            {...register("customCheck")}
                            onClick={onChangeMe} 
                          />
                          <span className="level-item has-text-weight-bold is-size-5 ">Custom Price</span>
                        </div>  
                    { customChecked ?
                    <input
                      type='text'
                      className='input is-fullwidth'
                      {...register("customText")}
                      onKeyUp={ ()=>createjobdesc(false) }
                    /> :
                    <input
                      type='text'
                      className='input is-fullwidth'
                      {...register("customText")}
                      onKeyUp={ ()=>createjobdesc(false) }
                      disabled
                    />
                    }
                  </label>
                </div>
                <ErrorMsg msg={errorMsgs.customTextMustBeANumber} num={ Object.keys(errorMsgs).length-1 }/>
                <ErrorMsg msg={errorMsgs.customTextMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
              </div>

              <div className='field'>
                <div className='control'>
                  { customChecked === 'checked' ?
                  <textarea
                    className='textarea'
                    rows='2'
                    {...register("customDesc")}
                    value={customDescState}
                    onKeyUp={ ()=>createjobdesc(false) }
                    placeHolder="Enter Custom Description Here..."
                  /> :
                  <textarea
                    className='textarea'
                    rows='2'
                    {...register("customDesc")}
                    value={customDescState}
                    onKeyUp={ ()=>createjobdesc(false) }
                    placeHolder="Enter Custom Description Here"
                    disabled
                  />
                  }
                </div>
              </div><br/>

              <div className='field'>
                <div className='control'>
                  <label className="label is-size-5" forhtml='jobdesc_input'>Job Description</label>
                    <textarea
                      className='textarea'
                      rows='4'
                      id='jobdesc_input'
                      {...register("jobDesc")}
                      readOnly
                    />
                </div>
              </div>

              <div className='columns'>
                <div className='column is-2 is-5-mobile'>
                  <div className='field'>
                    <div className='control'>
                      <label className="label is-size-5" htmlFor='price_label'>Price</label>
                      <input 
                        className='input' 
                        type='text' 
                        id='price_label' 
                        {...register("price")}
                        readOnly 
                      />
                    </div>
                  </div>
                </div>
                <div className='column is-2 is-5-mobile'>
                  <div className='field'>
                    <div className='control'>
                      <label className="label is-size-5" htmlFor='hst_label'>HST</label>
                      <input className='input' type='text' id='hst_label' {...register("hst")} readOnly />
                    </div>
                  </div>
                </div>
                <div className='column is-2 is-5-mobile'>
                  <div className='field'>
                    <div className='control'>
                      <label className="label is-size-5" htmlFor='totalPrice_label'>Total</label>
                      <input className='input' type='text' id='totalPrice_label' {...register("totalPrice")} readOnly />
                    </div>
                  </div>
                </div>
                <ErrorMsg msg={errorMsgs.totalPriceMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>

              </div>

              <div className='field'>
                <div className='control'>
                  <label className='label is-size-5' htmlFor='postJobNotes'>Post Job Notes:</label>
                  <textarea
                    className='textarea'
                    rows='3'
                    placeholder='Enter post-job notes(optional)...'
                    {...register("postJobNote")}
                    defaultValue = { postJobNote }
                  />
                </div>
              </div>

              <hr/>
              <div className='columns'>
                <div className='column is-fullwidth'>
                  <div className='field'>
                    <div className='control'>
                      <label className="label is-size-5" htmlFor='recStatus_label'>Rec Status</label>
                      <div className='select' id='recStatus_label'>
                        <select 
                          {...register("recStatus")}
                          onChange={editRecStatus}  
                        >
                          <option value="None Selected">None Selected</option>
                          <option value="Client Did Not Pay">Client Did Not Pay</option>
                          <option value="Client Paid By Cheque">Client Paid By Cheque</option>
                          <option value="Client Paid By Cash">Client Paid By Cash</option>
                        </select>
                      </div>
                      <ErrorMsg msg={errorMsgs.recStatusMustBeSelected} num={ Object.keys(errorMsgs).length-1 }/>
                    </div>
                  </div>
                  
                  
                  <div className={ showCash }>
                    <div className='field'>
                      <div className='control'>
                      <label className="checkbox">
                        <input type="checkbox" defaultChecked='checked' {...register("sendCashReceipt")} />
                        &nbsp;&nbsp;Send Paid Cash Receipt{ email ? '[' + email + ']' : null }
                      </label>
                      </div>
                    </div>
                  </div>

                  <div className={showCashAmount}>
                    <div className='field'>
                      <div className='control'>
                        <label className="label" htmlFor='cash_amount_label'>Cash Amount</label>
                        <input className='input' id='cash_amount_label' type='text' {...register("cashAmount")} />
                      </div>
                      <ErrorMsg msg={errorMsgs.cashAmountMustBeEntered} num={ Object.keys(errorMsgs).length-1 }/>
                      <ErrorMsg msg={errorMsgs.cashAmountMustBeANumber} num={ Object.keys(errorMsgs).length-1 }/>
                      <ErrorMsg msg={errorMsgs.cashAmountMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
                    </div>
                  </div>

                  <div className={chqNumClass}>
                    <div className='field'>
                      <div className='control'>
                        <label className="label" htmlFor='chqnum_label'>Chq Num</label>
                        <input className='input is-fullwidth' id='chqnum_label' type='text' {...register("chqNum")} />
                      </div>
                      <ErrorMsg msg={errorMsgs.chqNumberMustBeEntered} num={ Object.keys(errorMsgs).length-1 }/>
                    </div>
                  </div>
                </div>


              </div>




              <div className='field'>
                <div className='control'>
                  <label className="label is-size-5" htmlFor='lawnSign_label'>Lawn Sign</label>
                  <div className='select' id='lawnSign_label'>
                    <select 
                      {...register("lawnSign")} 
                      defaultValue={job.lawnsign}
                    >
                      <option value='None Selected'>None Selected</option>
                      <option value='Yes'>Yes</option>
                      <option value='No'>No</option>
                    </select>
                  </div>
                  <ErrorMsg msg={errorMsgs.lawnSignMustBeSelected} num={ Object.keys(errorMsgs).length-1 }/>
                </div>
              </div><br/>

              <div className='field'>
                <div className='control'>
                  <label className="label is-size-5" htmlFor='clientHome_label'>Client Home</label>
                  <div className='select' id='clientHome_label'>
                    <select 
                      {...register("clientHome")} 
                      defaultValue = {job.clienthm}
                    >
                      <option value='None Selected'>None Selected</option>
                      <option value='Yes'>Yes</option>
                      <option value='No'>No</option>
                    </select>
                  </div>
                  <ErrorMsg msg={errorMsgs.clientHomeMustBeSelected} num={ Object.keys(errorMsgs).length-1 }/>
                </div>
              </div><br/>

              <div className='field'>
                <div className='control'>
                  <label className="label is-size-5" htmlFor='nextDest_label'>Next Destination</label>
                  <div className='select' id='nextDest_label'>
                    <select {...register("nextDest")} >
                      { nextDestOptions() }
                    </select>
                  </div>
                  <ErrorMsg msg={errorMsgs.nextDestinationMustBeSelected} num={ Object.keys(errorMsgs).length-1 }/>
                </div>
              </div><br/>
              { Object.keys(errorMsgs).length-1 > 0 ?
                <ErrorMsg msg={errorMsgs.totalErrors} num={ Object.keys(errorMsgs).length-1 } /> : null }



              { showSubmitButton ?
                  <button name='return_job' type='submit' className='button is-link is-large is-rounded is-fullwidth has-text-weight-bold'>{buttonText}</button> :
                  <button name='return_job' type='submit' className='button is-link is-large is-rounded is-fullwidth has-text-weight-bold' disabled>{buttonText}</button>
              }
            </form>
          </div><br/>  
          { pastJobs && <PastJobs pastJobs={pastJobs} /> }
        </div>
      </div>
    </Layout>
  );
};


export default JobDetails;

