import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";

const ShopInventoryMgt = ({handleDailyReport, user, checkReportIssued, fireDateChanged, currentDate , area}) => {
  const ccForm = useForm();

  const { register, handleSubmit, setValue, getValues } = ccForm;
  const {
    daily_notes,
    daily_a_notes,
  } = getValues();

  return(
    <div>
      <h2 className='title is-3 has-text-centered'>Shop Inventory Mgt{area} [{ user && user.user_name }] </h2>
      <form className='form' onSubmit={handleSubmit(handleDailyReport)}>
        <input
          type='hidden'
          {...register("hrid")}
          defaultValue = { user && user.hrid }
        />
        <input
          type='hidden'
          {...register("area")}
          defaultValue = { 'Shop Inventory Mgt' + area}
        />

        <div className='field'>
          <div className='control'>
            <input
              type='date'
              {...register("date")}
              defaultValue = { currentDate }
              onChange = { () => fireDateChanged(getValues('date')) }
            />
          </div>
        </div><br/>

      <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line1")}
              defaultValue={ "[Rubber Gloves]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line2")}
              defaultValue={ "[Cotton Gloves]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line3")}
              defaultValue={ "[WS Signs]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line4")}
              defaultValue={ "[Danger Signs]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line5")}
              defaultValue={ "[Sign Frames]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line6")}
              defaultValue={ "[Clothing(pullovers, windbreakers, T-shorts, polos)]"}
            />
          </div>
        </div><br/>

        <div className='has-text-centered'>
            <button type='submit' className='button is-link is-rounded has-text-weight-bold'>Send Shop Inventory Mgt Report</button>
        </div>
      </form>
    </div>

  )


}

export default ShopInventoryMgt
