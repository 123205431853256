import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import ChqRegEntry from "./employee-details/ChqRegEntry";
import useRequest from "../hooks/useRequest";
import WeeklyPay from "./employee-details/WeeklyPay";

const EmployeeDetails = () => {
  const [name, setName] = useState(null);
  const [week, setWeek] = useState([]);

  const history = useNavigate();
  const location = useLocation();

  const newParams = new URLSearchParams(location.search);
  const goBackParams = new URLSearchParams(location.search);
  const empDetails = newParams.get("empDetails");
  const empHrid = newParams.get("empHrid");
  const range = newParams.get("range");
  goBackParams.delete("empDetails");
  goBackParams.delete("empHrid");

  const { data, loading } = useRequest(`/api/pays/member_pay_info`, {
    empDetails,
    empHrid,
    range,
  });

  useEffect(() => {
    setName(empDetails);
    history.push(`/employee_details?${newParams.toString()}`);
  }, []);

  useEffect(() => {
    if (data) {
      setWeek(data);
    }
  }, [data, loading]);

  return (
    <div className="container">
      <h3>
        <Link to={`/crewprofile?${goBackParams.toString()}`}>Nut Back</Link>
      </h3>
      <h1>Details for {name}</h1>
      <ChqRegEntry />
      {loading ? null : <WeeklyPay week={week} />}
    </div>
  );
};

export default EmployeeDetails;
