import React, { useEffect, useState } from "react";
import { useStateReducer } from "../../base/context/authContext";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import Layout from "../layout/Layout";
import ApiClient from "../../base/api/client";
import Loader from "../layout/Loader";
import InputField from "../components/forms/InputField";
import useRequest from "../../base/hooks/useRequest";
import useLazyRequest from "../../base/hooks/useLazyRequest";
import RadioField from "../components/forms/RadioField";
import Select from "../components/forms/Select";
import { prodNoteOptions } from "../helpers/lists";

const PropertyNotes = () => {
  const [address, setAddress] = useState("");
  const [prodNotes, setProdNotes] = useState([]);
  const history = useNavigate();
  const [{ user, userLoading }] = useStateReducer();
  const params = useParams();
  const { data: mess, loading } = useRequest(`/api/tasks/${params.id}`);
  const [
    fetchProdNotes,
    { data: newProdNotes, loading: prodNotesLoading },
  ] = useLazyRequest(`/api/tasks/${params.id}/fetch_property_notes`);

  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    if (mess) {
      setAddress(mess.address);
      setProdNotes(mess.notes);
    }
  }, [mess]);

  useEffect(() => {
    if (newProdNotes) {
      console.log("NP:", newProdNotes);
      setProdNotes(newProdNotes);
    }
  }, [newProdNotes]);

  const onSubmit = async (data) => {
    const response = await ApiClient.post("/api/tasks/save_property_note", {
      ...data,
      id: params.id,
      hrid: user.hrid,
    });
    fetchProdNotes();
    setValue("notes", "");
    setValue("prodNote", "Custom");
    setValue("access", "Public");
  };

  const deleteNote = async (id) => {
    const response = await ApiClient.request(
      `/api/tasks/${id}/delete_property_note`
    );
    fetchProdNotes();
  };

  return (
    <Layout>
      {userLoading || loading ? (
        <Loader />
      ) : (
        <div className="container-medium">
          <h1 className="margin-top has-text-weight-bold is-size-3">
            Property Notes For {address}
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <button className="button is-info" onClick={() => history.goBack()}>
              Go Back
            </button>
            <div className="control">
              <RadioField
                label="Public"
                checked={true}
                {...register("access")}
                />
              <RadioField 
                name="access" 
                label="Private" 
                {...register("access")} 
              />
            </div>
            {/* <CheckField name="40" label="40 Windows" /> */}
            <Select {...register("prodNote")} label="">
              {prodNoteOptions.map((note) => (
                <option key={note} value={note}>
                  {note}
                </option>
              ))}
            </Select>
            <InputField  {...register("notes")} />
            <button className="button is-info is-fullwidth" type="submit">
              Save Notes
            </button>
            <div className="margin-top">
              <table className="table">
                <tbody>
                  {prodNotes.map((note) => (
                    <tr key={note.id}>
                      <td>{note.ts}</td>
                      <td>{note.recorder}</td>
                      <td>{note.notes}</td>
                      <td>
                        <button
                          className="button"
                          onClick={() => deleteNote(note.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </form>
        </div>
      )}
    </Layout>
  );
}; 

export default PropertyNotes;
