import React, { useEffect, useState } from "react";
import SelectOptionsSmall from "./SelectOptionsSmall";
import SelectOptionsSmallTwo from "./SelectOptionsSmallTwo";
import SelectOptions from "./SelectOptions";
import { hours, mins, mgrs } from "../helpers/lists";
import dayjs from "dayjs";

const CrewPayForm = ({ onSubmit, member, form, payData, selectedMgr, className }) => {
  console.log('Member', member);
  console.log('selected Mgr', selectedMgr);
  const { register, handleSubmit, getValues, setValue } = form;
  const {
    name,
    startHour,
    startMinute,
    finishHour,
    finishMinute,
    mgr
  } = getValues();

  let buttonLabel = "Save Pay";
  payData?.forEach((pay) => {
    if (name === pay.name) {
      buttonLabel = "Edit Pay";
    }
  });

  const [times, setTimes] = useState(false);
  let workHours;

  useEffect(() => {
    setTimes(false);
  }, [times]);

  const fillHoursAndPay = () => {
    const stime = dayjs().hour(Number(startHour)).minute(Number(startMinute));
    const ftime = dayjs().hour(Number(finishHour)).minute(Number(finishMinute));
    workHours = ftime.diff(stime, "hours", true).toFixed(2);
  };
  fillHoursAndPay();

  if (!member?.rate) return null;
  let rate = Number(member.rate.replace(/[^\d.]/g, ""));
  let pay = (workHours * rate).toFixed(2);

  const onChange = (e) => {
    setValue(e.target.name, e.target.value);
    setTimes(true);
  };

  return (
    <div className={ className }>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register("crewType")} />
        <input type="hidden" {...register("hrid")} />
        <input type="hidden" {...register("rate")} />
        <input type="hidden" {...register("name")} />
        <input type="hidden" {...register("test")} />
        <table className="table is-size-5">
          <tr>
            <td colspan={2}>{member.name}</td>
          </tr>
          <tr>
            <td>
              Rate: {member.rate}
            </td>
          </tr>

          <tr>
            <td className="level">
                <span className="level-item">Mgr</span>
                <span className="level-item">
                <SelectOptionsSmallTwo
                options={mgrs}
                {...register("mgr")}
                onChange={onChange}
                selectedMgr={selectedMgr}
                />
                </span>
            </td>
          </tr>
          <tr>
            <td style={{width: "60px"}}>Hours</td>
            <td><span className="ml-0">{workHours}</span></td> 
          </tr>
          <tr>
            <td>Pay</td>
            <td>{pay}</td>
          </tr>

          <tr>
            <td>
              <nav className='level-left'>
                Start
                <SelectOptionsSmall
                  options={hours}
                  {...register("startHour")}
                  onChange={onChange}
                />
                :
                <SelectOptionsSmall
                  options={mins}
                  {...register("startMinute")}
                  onChange={onChange}
                />
              </nav>
            </td>
          </tr>

          <tr>
            <td>
              <nav className='level-left'>
                Finish
                <SelectOptionsSmall
                  options={hours}
                  {...register("finishHour")}
                  onChange={onChange}
                />
                :
                <SelectOptionsSmall
                  options={mins}
                  {...register("finishMinute")}
                  onChange={onChange}
                />
              </nav>
            </td>
          </tr><br/>
          <button className="button has-text-centered has-background-primary" type="submit">
            {buttonLabel}
          </button>
        </table>
      </form>
    </div>
  );
};

export default CrewPayForm;
