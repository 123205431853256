import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import FormFields from "./FormFields";
import FormCard from "../../components/FormCard";
import Layout from "../../layout/Layout";
import useRequest from "../../hooks/useRequest";
import ApiClient from "../../api/client";

import Loader from "../../components/Loader";

const EditPropertyTransaction = () => {
  const history = useNavigate();
  let { id } = useParams();
  console.log("ID", id);
  const { data: tran, loading } = useRequest(
    `/api/props/${id}/fetch_transaction`,
    null
  );

  const { register, handleSubmit, reset } = useForm({
    defaultValues: { date: dayjs().format("YYYY-MM-DD") },
  });

  useEffect(() => {
    if (tran) {
      reset({
        id: tran.id,
        date: tran.date,
        typeIncome: tran.typeIncome,
        description: tran.description,
        location: tran.location,
        amount: tran.amount,
        notes: tran.notes,
      });
    }
  }, [tran]);

  const onSubmit = async (params) => {
    console.log("PARAMS: ", params);
    const response = await ApiClient.post(`/api/props/add_transaction`, params);

    if (response) {
      history.push("/transactions");
    }
  };

  if (loading) {
    return <Loader />;
  }

  console.log("TRAN:", tran);
  return (
    <Layout>
      <button className="button button-assign" onClick={() => history.goBack()}>
        Back
      </button>
      <FormCard>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormFields register={register} />
          <button className="button button-assign">Edit Transaction</button>
        </form>
      </FormCard>
    </Layout>
  );
};

export default EditPropertyTransaction;
