
import React, { useState } from 'react';
import { useForm } from "react-hook-form";

import ApiClient from "../../base/api/client";
import ActionType from "../../base/context/actionType";
import { useStateReducer } from "../../base/context/authContext";

import Layout from "../layout/Layout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser } from '@fortawesome/free-regular-svg-icons';
import { faLock, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faCcVisa, faCcAmex, faCcMastercard, faCcStripe } from '@fortawesome/free-brands-svg-icons';


const ENVELOPE = <FontAwesomeIcon icon={ faEnvelope } />;
const LOCK = <FontAwesomeIcon icon={ faLock } />;
const USER = <FontAwesomeIcon icon={ faUser } />;
const PHONE = <FontAwesomeIcon icon={ faPhone } />;
const HOME = <FontAwesomeIcon icon={ faHome } />;
const VISA = <FontAwesomeIcon icon={ faCcVisa } />;
const AMEX = <FontAwesomeIcon icon={ faCcAmex } />;
const STRIPE = <FontAwesomeIcon icon={ faCcStripe } />;
const MC = <FontAwesomeIcon icon={ faCcMastercard } />;

const ResetPassword = () => {
  const [message, setMessage] = useState(null);
  const [{ client }, dispatch] = useStateReducer();

  const { register, setValue, getValues, handleSubmit } = useForm();

  const submitForm = async (data) => {
    if(data.pass === data.confirm_pass){
      const newData = {
        password: data.pass,
        cfid: client.cfid
      }
      const response = await ApiClient.post("/api/sessions/save_password", newData);
      if(response && response.success){
        setMessage('Password reset successful.')
        dispatch({
          type: ActionType.SetClient,
          payload: null,
        });
        dispatch({
          type: ActionType.SetDashboardShowFunction,
          payload: null,
        });
      }else{
        setMessage('Password reset was not successful. Please try again.')
      }
    }else{
      setMessage('Password and password confirmation do not match. Please try again.')
      setValue('pass', '');
      setValue('confirm_pass', '');
    }
  }

  return (
    <Layout>
      <section className='section is-fullheight'>
        <div className='container'>
          <h2 className='title is-3 has-text-centered'>Reset Password</h2>
          <div className='columns is-tablet is-centered'>
            <div className='column is-half'>
            { message? <h2 className='subtitle is-3 has-text-centered has-text-success'>{message}</h2> : null }


            <form onSubmit={handleSubmit(submitForm)}>
              <div className='field'>
                <div className='control'>
                  <label className='label' htmlFor='pass'>Password</label>
                  <input
                    type='password'
                    className='input'
                    id='pass'
                    {...register('pass')}
                />
                </div>
              </div><br/>
              <div className='field'>
                <div className='control'>
                  <label className='label' htmlFor='confirm_pass'>Confirm Password</label>
                  <input
                    type='password'
                    className='input'
                    id='confirm_pass'
                    {...register('confirm_pass')}
                  />
                </div>
              </div><br/>
              <div className='has-text-centered'>
                <button className='button is-primary' type='submit'>Save Password</button>
              </div>

            </form>


            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ResetPassword





// <div className='columns'>
//
//   <div className='column'>
//     <h4 className='title'>Send us a message:</h4>
//     <form>
//       <div className='columns'>
//         <div className='column'>
//           <div className='field'>
//             <label className='label' for="name">Name:</label>
//             <div className='control has-icons-left'>
//               <input type='text' id='name' name="name" className='input' placeholder="Firstname Lastname" required />
//               <span className='icon is-small is-left'>
//                 <i className='icon'>{ USER }</i>
//               </span>
//             </div>
//           </div>
//         </div>
//         <div className='column'>
//           <div className='field'>
//             <label className='label' for="name">Email:</label>
//             <div className='control has-icons-left'>
//               <input type='email' id='email' name="email" className='input' placeholder="example@example.com" required />
//               <span className='icon is-small is-left'>
//                 <i className='icon'>{ ENVELOPE }</i>
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className='columns'>
//         <div className='column'>
//           <div className='field'>
//             <label className='label' for="telephone">Telephone:</label>
//             <div className='control has-icons-left'>
//               <input type='text' id='telephone' name="phone" className='input' placeholder="123-456-7890" required />
//               <span className='icon is-small is-left'>
//                 <i className='icon'>{ PHONE }</i>
//               </span>
//             </div>
//           </div>
//         </div>
//         <div className='column'>
//           <div className='field'>
//             <label className='label' for="select">Department:</label>
//             <div className='control has-icons-left'>
//               <div className='select'>
//                 <select id='select' name="dept" required>
//                   <option value='0'>- select here -</option>
//                   <option value='1'>Sales</option>
//                   <option value='2'>Production</option>
//                   <option value='3'>Receivables</option>
//                 </select>
//               </div>
//               <span className='icon is-small is-left'>
//                 <i className='icon'>{ HOME }</i>
//               </span>
//             </div>
//             <small className='form-text'>select a department</small>
//           </div>
//         </div>
//       </div>
//       <div className='field'>
//         <label className='label' for="message">Message:</label>
//         <div className='control'>
//           <textarea rows='5' className='textarea' id='message' placeholder='Message...' required></textarea>
//         </div>
//       </div>
//       <button className='button is-primary' type='submit'>Submit</button>
//       <button className='button' type='reset'>Reset</button>
//     </form>
//   </div>
//
//
//   <div className='column'>
//     <h4 className='title'>Where to find us:</h4>
//     <figure className='image is-4by3'>
//       <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.617643370283!2d-79.3417694845891!3d43.65612286025985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb74935e61a3%3A0x3be1b39226fe9b37!2s36%20Morse%20St%2C%20Toronto%2C%20ON%20M4M%202P6%2C%20Canada!5e0!3m2!1sen!2sus!4v1641848821905!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
//     </figure>
//   </div>
// </div>
