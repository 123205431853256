import React from "react";
import { formatCurrency } from "../helpers/helper";
import dayjs from "dayjs";

const Tasks = ({ tasks }) => {
  console.log('Tasks', tasks);
  return (
    <div className="box mb-4">
      <table className="table">
        <thead>
          <tr>
            <th>Task Notes</th>
            <th>Start Time</th>
            <th>Finish Time</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => {
            return (
              <tr key={task.stime}>
                <td>{ task.task_desc }</td>
                <td>{ task.stime }</td>
                <td>{ task.ftime }</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Tasks;
