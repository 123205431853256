import React from "react";
import useRequest from "../hooks/useRequest";
import CrewNameOptions from "./CrewNameOptions";
import Loader from "./Loader";
import SelectOptions from "./SelectOptions";
import SelectRangeOptions from "./SelectRangeOptions";

const Filters = ({
  onUpdateParams,
  numberJobs,
  schDate,
  soldBefore,
  onPostCodeChange,
  onAddressChange,
  onPerlyChange,
  view,
  crewName,
}) => {
  const { data, loading } = useRequest(`/api/crews/${schDate}/driver_list`);

  
  return loading ? (
    <Loader />
  ) : (
    <div className="container">
      <div className="box is-fullwidth">
        <div className="is-flex is-justify-content-space-between">  
          <input
            type="date"
            className="is-size-4"
            value={schDate}
            onChange={onUpdateParams}
            name="schDate"
          />
          <input
            type="date"
            className="is-size-4"
            value={soldBefore}
            onChange={onUpdateParams}
            name="soldBefore"
          />
          <CrewNameOptions
            className="is-size-4"
            onChange={onUpdateParams}
            value={crewName === "" || crewName === null ? "" : crewName}
            options={data}
            name="crewName"
          />

          <SelectRangeOptions 
            onChange={onUpdateParams} 
            value={view} 
          />

        </div><br/>

        <div className="is-flex is-justify-content-space-between">
          <input
            className="is-size-4"
            onKeyUp={onPostCodeChange}
            placeholder="Postal Code"
          />
          <input
            className="is-size-4"
            onKeyUp={onAddressChange}
            placeholder="Address"
          />
          <input
            className="is-size-4"
            onKeyUp={onPerlyChange}
            placeholder="Perly"
          />
        </div><br/>
        <h1 className="subtitle has-text-weight-bold has-text-centered"># Jobs: {numberJobs}</h1>
      </div><br/>
    </div>
  );
};

export default Filters;
