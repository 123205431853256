import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useForm } from "react-hook-form";
// import { createBrowserHistory } from "history";
import { useNavigate } from "react-router-dom";
//import { useNavigate } from "react-router";
import { useStateReducer } from "../../base/context/authContext";
import ActionType from "../../base/context/actionType";
import ApiClient from "../../base/api/client";
import LoginAPI from "../../base/api/login";

import WSLOGO from "../../base/assets/white_shark.png";
import SHANTI from "../assets/images/natAction.jpg";
import JAREER from "../assets/images/jareerAction.jpg";

import Layout from "./Layout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faUser, faCogs, faDiceFour, faAd, faMapMarker } from '@fortawesome/free-regular-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'

const ENVELOPE = <FontAwesomeIcon icon={faEnvelope} />
const LOCK = <FontAwesomeIcon icon={faLock} />
const USER = <FontAwesomeIcon icon={faUser} />


const style = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}

const styleFooter = {
  marginTop: 'auto'
}

const photoStyle = {
  height: 'auto',
  width: '200px',
};



const Home = () => {
  const [resetPassword, setResetPassword] = useState('initial');
  const [client, setClient] = useState(null);
  const [validEmail, setValidEmail] = useState(true);
  const [failedLogin, setFailedLogin] = useState(false);
  const [{ user, logInClass, resetPasswordClass, email, dashboardShowFunction }, dispatch] = useStateReducer();
  const { register, setValue, getValues, handleSubmit } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if(navigate && (dashboardShowFunction || user)){
      console.log("Ran Navigate line #53 in Home.js");

      navigate('/dashboard');
      if(user && (dashboardShowFunction === 'stripeJobPay' || dashboardShowFunction === 'internalUser' || dashboardShowFunction === 'clientUser')) {
        navigate('/dashboard');
        console.log("Ran Navigate line #58in Home.js");

      } else if(dashboardShowFunction === 'resetPassword'){
        navigate('/reset_password');
      } else if(user) {
        navigate('/dashboard');
        console.log("Ran Navigate line #64 in Home.js");

      }
    }
  }, [dashboardShowFunction, user, navigate])



  const userLogOut = async () => {
    await ApiClient.logOut();

    dispatch({
      type: ActionType.SetUser,
      payload: null,
    });
    dispatch({
      type: ActionType.SetEmail,
      payload: null,
    });
    dispatch({
      type: ActionType.SetType,
      payload: null,
    });
    dispatch({
      type: ActionType.SetClient,
      payload: null,
    });
    dispatch({
      type: ActionType.SetDashboardShowFunction,
      payload: null,
    });
    dispatch({
      type: ActionType.SetProps,
      payload: [],
    });

    navigate('/dashboard');
    console.log("Ran Navigate line #100 in Home.js");

  }


  const onChangeLogIn = () => {
    logInClass === "modal is-large is-clipped" && !user ?
    dispatch({type: ActionType.SetLogInClass, payload: "modal is-large is-clipped"}) :
    dispatch({type: ActionType.SetLogInClass, payload: "modal is-active is-large is-clipped"});
    if( !user ){
      userLogOut();
    }
  }

  const killLogInModal = () => {
    dispatch({type: ActionType.SetLogInClass, payload: "modal is-large is-clipped"});
  }

  const killResetPasswordModal = () => {
    dispatch({type: ActionType.SetResetPasswordClass, payload: "modal is-large is-clipped"});
    setResetPassword('initial');
  }

  const authenticateEmail = async (data) => {
    if(!data.email.includes(".") || data.email.length<=6){
      setValidEmail(false);
    }else{
      setValidEmail(true);
      const response = await ApiClient.post("/api/sessions/send_password_reset_email", data);
      //console.log('Response', response);
      if(response.success){
        setClient(response.client);
        dispatch({type: ActionType.SetClient, payload: response.client});
        setResetPassword('success');
      }else{
        setResetPassword('fail');
      }
    }
  }

  const displayResetPasswordContent = () => {
    if(resetPassword === 'initial'){
      return (
        <form className="box" onSubmit={handleSubmit(authenticateEmail)}>
          <div className="field">
            { !validEmail ? <span className='has-text-danger has-text-weight-bold'>Invalid Email</span> : <label htmlFor='email' className="label">Email</label> }
            <div className="control has-icons-left">
              <input id='email' type="email" name='email' placeholder="e.g. bobsmith@gmail.com" className="input" {...register("email")} noValidate />
              <span className="icon is-small is-left">
                <i className='icon is-large mt-2 ml-2'>{ENVELOPE}</i>
              </span>
            </div>
          </div>
          <div className="field">
            <button type='submit' className="button is-success">
              Set/Reset My Password
            </button>
          </div>
        </form>
      )
    }else if(resetPassword === 'success'){
      return (
        <div className='box'>A password reset email has been sent to {client.email}</div>
      )
    }else if(resetPassword === 'fail'){
      return (
        <div className='box'>Please contact our office at customerservice@whiteshark.ca or 416-363-7420 for assistance</div>
      )
    }else if(resetPassword === 'invalid'){
      setResetPassword('fail');
    }
  }

  const authenticateUser = async (data) => {
    const { user, email, client, type, props } = await LoginAPI.logIn(data.username, data.password);
    if(type === 'UNAUTHORIZED_USER'){
      setFailedLogin(true);
      dispatch({ type: ActionType.SetLogInClass, payload: "modal is-active is-large is-clipped" });
      navigate('/');
      console.log("Ran Navigate line #180 in Home.js");

    }
    if (user === null ){
      return;
    }
    dispatch({
      type: ActionType.SetType,
      payload: type,
    });
    dispatch({
      type: ActionType.SetUser,
      payload: user,
    });
    dispatch({
      type: ActionType.SetClient,
      payload: client,
    });
    dispatch({
      type: ActionType.SetEmail,
      payload: email,
    });
    dispatch({
      type: ActionType.SetProps,
      payload: props,
    });
    dispatch({ type: ActionType.SetLogInClass, payload: "modal is-large is-clipped" });
    navigate('/dashboard');
    console.log("Ran Navigate line #207in Home.js");
  }

  const forgotPassword = () => {
    dispatch({ type: ActionType.SetLogInClass, payload: "modal is-large is-clipped" });
    dispatch({ type: ActionType.SetResetPasswordClass, payload: "modal is-active is-large is-clipped" });
  }

  const killLogIn = () => {
    dispatch({ type: ActionType.SetLogInClass, payload: "modal is-large is-clipped" })
  }

  const killPassword = () => {
    dispatch({ type: ActionType.SetResetPasswordClass, payload: "modal is-large is-clipped" })
  }

  return (
    <Layout>
      <section className='hero is-light is-bold'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column'>
                <figure className='image is-1by1'>
                  <img src={WSLOGO} />
                </figure>

              </div>
              <div className='column'>
                <h1 className='title is-1'>White Shark Ltd</h1>
                <p className='subtitle'>Window Cleaning, Eaves Cleaning, Painting, Odd Jobs, Lawn Care</p>
                <button onClick={()=>navigate('/about')} type='button' className='button is-primary'>Read More</button>
                <button onClick={()=>navigate('/contact')} type='button' className='button is-primary is-outlined'>Contact Us</button>
              </div>
            </div>
          </div>
        </div>
      </section>



      <div className={ logInClass }>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Client Dashboard Log In</p>
            <button className="delete" aria-label="close" onClick={killLogIn}></button>
          </header>
          <form onSubmit={handleSubmit(authenticateUser)}>
            <section className="modal-card-body">
              <h2 className={ failedLogin ? 'has-text-weight-bold has-text-danger is-size-4' : 'is-invisible'}>{ failedLogin ? 'Incorrect username/password' : null }</h2><br/>
              <div className="field">
                <label htmlFor='email' className="label">Email</label>
                <div className="control has-icons-left">
                  <input id='username' type="email" name='username' placeholder="e.g. bobsmith@gmail.com" className="input" {...register("username")} />
                  <span className="icon is-small is-left">
                    <i className='icon is-large mb-3 ml-2'>{ENVELOPE}</i>
                  </span>
                </div><br/>
              </div>
              <div className="field">
                <label htmlFor='password' className="label">Password</label>
                <div className="control has-icons-left">
                  <input className="input" id='password' type="password" name='password' placeholder="***********" {...register("password")} />
                  <span className="icon is-left">
                    <i className='icon mb-3 ml-2'>{LOCK}</i>
                  </span>
                </div>
              </div>
              <div className="field">
                <a className="pl-0 panel-block is-active has-text-link has-text-weight-bold" onClick={() => forgotPassword()}>
                  Forgot Password
                </a><br/>
              </div>
              <div className="field has-text-centered">
                <button type='submit' className="button is-success is-fullwidth">
                  Log In
                </button>
              </div>
            </section>
          </form>
       
        </div>
      </div>
      
      <div className={ resetPasswordClass }>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Reset Password</p>
            <button className="delete" aria-label="close" onClick={killPassword}></button>
          </header>
            <section className="modal-card-body">
              <h2 className='has-text-weight-bold is-size-4'>
                Please contact customerservice@whiteshark.ca to reset your password
              </h2><br/>
            </section>
        </div>
      </div>


      <section className='section'>
        <div className='container'>
          <div className='notification is-info'>
            <h2 className='title'>Now Hiring!!</h2>
            <p>
              Looking for quality people to work in our office and in the field. Full training is provided. Our business provides an excellent training ground to build a strong work ethic and solid work habits. Full-time, part-time and students are welcome to apply.
              <a href="#0">More info</a>
            </p>
          </div>
          <div className='columns'>
            <div className='column'>
              <div className='columns is-desktop'>
                <div className='column'>
                  <figure style={photoStyle} className='image'>
                    <img src={JAREER} />
                  </figure>
                </div>
                <div className='column content'>
                  <h2 className='title'>What We Do</h2>
                  <p>White Shark Ltd serves residential and commercial customers. Services include window cleaning, eaves cleaning, interior/exterior painting, odd jobs, power washing and lawn care.</p>
                  <button type='button' className='button is-primary'>Read More</button>
                </div>
              </div>

            </div>
            <div className='column'>
              <div className='columns is-desktop'>
                <div className='column'>
                  <figure style={photoStyle} className='image is-1by1 is-clipped'>
                    <img src={SHANTI} />
                  </figure>
                </div>
                <div className='column content'>
                  <h2 className='title'>Who We Are</h2>
                  <p>Our team consists of hard working and dedicated staff members. Our core is primarily full time. Our crews pride themselves in bringing their years of experience to every job they undertake.</p>
                  <button type='button' className='button is-primary'>Read More</button>

                </div>
              </div>
            </div>
          </div>

          <div className='columns is-multiline'>
            <div className='column is-half-tablet is-one-quarter-widescreen'>
              <div style={style} className='card'>
                <header className='card-header'>
                  <p className='card-header-title is-centered'>
                    Exterior Windows
                  </p>
                </header>
                <div className='card-content content'>
                  <h2 className='card-title'></h2>
                  <p className='card-text'>
                    Polite and professional; work done quickly and done well
                  </p>
                  <p>
                    - Ms Carrie Brown (Oct 25, 2021)
                  </p>
                </div>
                <footer style={styleFooter} className='card-footer'>
                  <p className='card-footer-item'>72 Rushbrooke Avenue</p>
                </footer>
              </div>
            </div>

            <div className='column is-half-tablet is-one-quarter-widescreen'>
              <div style={style} className='card'>
                <header className='card-header'>
                  <p className='card-header-title is-centered'>
                    Windows both sides/screens/eaves
                  </p>
                </header>
                <div className='card-content content'>
                  <h2 className='card-title'></h2>
                  <p className='card-text'>
                    Just wanted to let you know that the Christina and Josh were here this morning and did a great job on the windows. We can hardly see our screens anymore, they are clean.
                  </p>
                  <p>
                    - Ms Cheryl Gaster (Oct 19, 2021)
                  </p>
                </div>
                <footer style={styleFooter} className='card-footer margin'>
                  <p className='card-footer-item'>175 Ashdale Avenue</p>
                </footer>
              </div>
            </div>

            <div className='column is-half-tablet is-one-quarter-widescreen'>
              <div className='card'>
                <header className='card-header'>
                  <p className='card-header-title is-centered'>
                    Exterior Windows and Eaves
                  </p>
                </header>
                <div className='card-content content'>
                  <h2 className='card-title'></h2>
                  <p className='card-text'>
                    The two young men who removed the winter storm windows and installed the screens were excellent! I will have White Shark again in the fall.
                  </p>
                  <p>
                    - Ms Marilyn Job (June 14, 2021)
                  </p>
                </div>
                <footer className='card-footer'>
                  <p className='card-footer-item'>41 Glen Manor Drive</p>
                </footer>
              </div>
            </div>

            <div className='column is-half-tablet is-one-quarter-widescreen'>
              <div className='card'>
                <header className='card-header'>
                  <p className='card-header-title is-centered'>
                    Eaves
                  </p>
                </header>
                <div className='card-content content'>
                  <h2 className='card-title'></h2>
                  <p className='card-text'>
                    Great job by the team today - they were friendly and efficient, and did a great job!
                  </p><br/>
                  <p>
                    - Ms Sharon Foster (May 12, 2021)
                  </p>
                </div>
                <footer className='card-footer'>
                  <p className='card-footer-item'>43 Brooklyn Avenue</p>
                </footer>
              </div>
            </div>

          </div>
        </div>
      </section>




    </Layout>
  );
};

export default Home;



// It became evident right from the start that they were a competent experienced team who carried out the tasks with commendable care and efficiency. Their cheerful attitude was an added bonus!
// We are very happy with their work. Thank you.
//
// Kind regards, Navroz.  206 Bedford Road




// <label htmlFor='remember' className="checkbox">
//   <input id='remember' type="checkbox" />
//  Remember me
// </label>



{/* <div className={ logInClass }>
<div className="modal-background"></div>
  <div className="modal-content">

        <button className="modal-close is-large is-warning" aria-label="close" onClick={ killLogInModal }>X</button>
        <form className="box has-text-centered" onSubmit={handleSubmit(authenticateUser)}>
          <h2 className='has-text-weight-bold has-text-danger is-size-4'>{ failedLogin ? 'Incorrect username/password' : null }</h2><br/>
       
          <div className="field">
            <label htmlFor='email' className="label">Email</label>
            <div className="control has-icons-left">
              <input id='username' type="email" name='username' placeholder="e.g. bobsmith@gmail.com" className="input" {...register("username")} />
              <span className="icon is-small is-left">
                <i className='icon is-large mt-2 ml-2'>{ENVELOPE}</i>
              </span>
            </div>
          </div>
          <div className="field">
            <label htmlFor='password' className="label">Password</label>
            <div className="control has-icons-left">
              <input id='password' type="password" name='password' placeholder="*******" className="input" {...register("password")} />
              <span className="icon is-left">
                <i className='icon mt-2 ml-2'>{LOCK}</i>
              </span>
            </div>
          </div>
          <div className="field">
            <a className="panel-block is-active has-text-link has-text-weight-bold" onClick={() => forgotPassword()}>
              Set/Reset Password
            </a>
          </div>
          <div className="field">
            <button type='submit' className="button is-success">
              Log In
            </button>
          </div>
        </form>
</div>
</div> */}

