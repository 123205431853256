import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { Link, Redirect } from "react-router-dom";

import Layout from "../layout/Layout";
import Loader from "../../base/layout/Loader";
import { useStateReducer } from "../../base/context/authContext";

import ActionType from "../../base/context/actionType";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faCogs, faDiceFour, faAd, faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { faAmazon } from '@fortawesome/free-brands-svg-icons'
import { formatCurrency, validateData, validateStartData, makeNiceDate } from "../helpers/helper";
import ApiClient from "../../base/api/client";
import JobCard from '../components/JobCard'
import DnfCard from '../components/DnfCard'
import TaskForm from "./Partner/forms/TaskForm";


const Runsheet = () => {
  const params = useParams();
  const { search } = useLocation();
  const [fallJobsLeft, setFallJobsLeft] = useState(null);
  const [fallJobsDoneToday, setFallJobsDoneToday] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [dnfs, setDnfs] = useState([]);
  const [crew, setCrew] = useState(null);
  const [mikerName, setMikerName] = useState(null);
  const [mikerCell, setMikerCell] = useState(null);
  const [crewOptions, setCrewOptions] = useState([]);
  const [ocs, setOcs] = useState([]);
//  const [driver, setDriver] = useState(null);
//  const [hrid, setHrid] = useState(null);
  const [date, setDate] = useState(null);
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [taskClass, setTaskClass] = useState('button is-danger is-hidden');
  const [showTaskForm, setShowTaskForm] = useState(false);
  const [safetyOk, setSafetyOk] = useState(true);

  const [{ user, crew: state_crew }, dispatch] = useStateReducer();
  const ccForm = useForm();
  const { register, setValue, getValues, watch } = ccForm;

  const watchCrewOption = watch("crew_option");


  useEffect(() => {
    console.log("USER Hi Ho: ", user);
  }, [user])

  useEffect(() => {
    console.log("NEW EFFECT Hi Ho: ", watchCrewOption)
  }, [watchCrewOption])

  const fetchData = async (hrid) => {
    if (hrid) {
      setShowLoader(true)
      let result = await ApiClient.request(`/api/crews/${hrid}/daily_jobs`);
      console.log('RESULT:', result);

      setFallJobsLeft(result.fall_jobs_left);
      setFallJobsDoneToday(result.fall_jobs_done_today);

      setJobs(result.jobs);
      setCrew(result.today_crew);
      setMikerName(result.miker_name);
      setMikerCell(result.miker_cell);
      setTasks(result.tasks);
      setCrewOptions(result.crew_options);
      setDate(result.date);
      setSafetyOk(result.safety_ok);
      setOcs(result.ocs);
      if(result.safety_ok === false){
        setMessage("All crew members must sign in and fill Safety Form");
      }else{
        let result_dnfs = await ApiClient.request(`/api/dnfs/${hrid}/daily_dnfs`);
        setDnfs(result_dnfs.dnfs);
        if(params.success){
          setMessage(params.success);
        }
      }
      setShowLoader(false);
    }
  }

  useEffect(() => {
    if(state_crew && state_crew.crew_hrid){
      fetchData(state_crew.crew_hrid);
      setValue("crew_option", state_crew.crew_hrid);
    }
  }, [state_crew])

  useEffect(() => {
    if(state_crew && state_crew.crew_hrid){
      setValue("crew_option", state_crew.crew_hrid);
    }
  },[])


  const changeCrew = async (e) => {
    const hrid = e.target.value // getValues("crew_option");
    console.log("HRID-----", hrid);
    console.log("E-----", e.target.value);
    if(hrid !== 'None Selected'){
      const result = await ApiClient.request(`/api/crews/${hrid}/daily_driver_name`)
      const payload = {
        crew_hrid: hrid,
        crew_name: result.driver,
        crew_master: state_crew.crew_master
      }
      console.log("PAYLOAD", payload);
      dispatch({
        type: ActionType.SetCrew,
        payload
      });
      setMessage('Crews switched successfully');
    }
  }

  const changeCrewSelect = () => {
    console.log('Crew Options:', crewOptions);
    const changeCrewOptions = () => crewOptions.map(crewOption => <option key={ crewOption.crew_hrid } value={ crewOption.crew_hrid }>{ crewOption.crew_name }</option>)

    return (
      <React.Fragment>
        <div className="select">
          <select {...register("crew_option")} value={ state_crew ? state_crew.crew_hrid:''} onChange={ changeCrew }>
            { crewOptions && changeCrewOptions() }
          </select>
        </div>
        <br/><br/>
      </React.Fragment>
    )
  }


  const getJobsLabel = (type) => {
    let resultLabel = '';
    let appts = 0;
    let floats = 0;
    if(type == 'Started'){
      const jobResult = jobs.filter(job => job.reportstime!=='99:99:99' && job.reportftime === '99:99:99')
      jobResult.forEach((job) => {
        job.sdate === job.fdate ? appts += 1 : floats += 1;
      });
      resultLabel = "Appointments: " + appts.toString() + " Floaters: " + floats.toString()
    }else if(type == 'Pending'){
      const jobResult = jobs.filter(job => job.reportstime==='99:99:99' && job.reportftime === '99:99:99')
      jobResult.forEach((job) => {
        job.sdate === job.fdate ? appts += 1 : floats += 1;
      });
      resultLabel = "Appointments: " + appts.toString() + " Floaters: " + floats.toString()
    }else if(type == 'Completed'){
      const jobResult = jobs.filter(job => job.reportstime!=='99:99:99' && job.reportftime !== '99:99:99')
      let totalProd = 0;
      jobResult.forEach((job) => {
        job.sdate === job.fdate ? appts += 1 : floats += 1;
        totalProd += parseFloat(job.price);
      });
      resultLabel = "Appointments: " + appts.toString() + " Floaters: " + floats.toString() + " Total Prod:" + formatCurrency(totalProd);
    }
    return resultLabel;
  }

  const displayJobsStart = () => {
    const ans = jobs.filter((job) => job.reportstime!=='99:99:99' && job.reportftime === '99:99:99');
    const data =  jobs.filter((job) => job.reportstime!=='99:99:99' && job.reportftime === '99:99:99').map(job => <div key={job.jobid} className='column is-one-quarter-desktop'>
      <JobCard jobid={job.jobid} num={job.num} client={job.client} address={job.address} jobdesc={job.jobdesc}
            price={job.price} perly={job.perly} sdate={job.sdate} fdate={job.fdate}
            stime={job.stime} reportstime={job.reportstime} reportftime={job.reportftime}
            address = {job.address} postcode={job.postcode} city={job.city}
            holding_cell={job.holding_cell} prodcomm={job.prodcomm} status='Started' />
    </div>
    )
    return <React.Fragment>
      <h1 className='subtitle has-text-weight-bold has-text-danger'>{ data.length === 0 ? 'No Started Job' : 'Started Job' } [{getJobsLabel('Started')}]</h1>
      <div className='columns is-multiline'>
        { data }
      </div>
    </React.Fragment>;
  }

  const displayJobsCompleted = () => {
    const data = jobs.filter((job) => job.reportstime!=='99:99:99' && job.reportftime!=='99:99:99').map(job => <div key={job.jobid} className='column is-one-quarter-desktop'>
      <JobCard jobid={job.jobid} num={job.num} client={job.client} email={job.email}
            address={job.address} jobdesc={job.jobdesc}
            price={job.price} perly={job.perly} sdate={job.sdate} fdate={job.fdate}
            stime={job.stime} reportstime={job.reportstime} reportftime={job.reportftime}
            recstatus={job.recstatus} nextdest={job.nextdest} address = {job.address}
            postcode={job.postcode} city={job.city} cfid={job.cfid}
            holding_cell={job.holding_cell} prodcomm={job.prodcomm} status='Completed'
            setMessage={setMessage} user={user} state_crew={state_crew} ApiClient={ApiClient} />
    </div>
    )
    return <React.Fragment>
      <h1 className='subtitle has-text-weight-bold has-text-black'>{ data.length === 0 ? 'No Completed Jobs' : (data.length === 1 ? 'Completed Job' : 'Completed Jobs') } [{getJobsLabel('Completed')}]</h1>
      <div className='columns is-multiline'>
        { data }
      </div>
    </React.Fragment>;
  }

  const displayJobsPending = () => {
    const data =  jobs.filter((job) => job.reportstime ==='99:99:99' && job.reportftime==='99:99:99').map(job => <div key={job.jobid} className='column is-one-quarter-desktop'>
      <JobCard jobid={job.jobid} num={job.num} client={job.client} address={job.address} jobdesc={job.jobdesc}
            price={job.price} perly={job.perly} sdate={job.sdate} fdate={job.fdate}
            stime={job.stime} reportstime={job.reportstime} reportftime={job.reportftime}
            postcode={job.postcode} city={job.city}
            holding_cell={job.holding_cell} prodcomm={job.prodcomm} status='Pending' />
    </div>
    )
    return <React.Fragment>
      <h1 className='subtitle has-text-weight-bold has-text-primary'>{ data.length === 0 ? 'No Pending Jobs' : (data.length === 1 ? '1 Pending Job' : data.length.toString() + ' Pending Jobs') } [{getJobsLabel('Pending')}]</h1>
        <div className='columns is-multiline'>
      { data }
    </div>
    </React.Fragment>;
  }



  const displayDnfs = () => {
    return dnfs.map(dnf => <div key={dnf.dnfid} className='column is-one-quarter-desktop'>
      <DnfCard dnfid={dnf.dnfid} client={dnf.client} address={dnf.address} dnfdesc={dnf.dnfdesc}
        jobdesc={dnf.jobdesc} price={dnf.price} postcode={dnf.postcode} perly={dnf.perly} sdate={dnf.sdate} fdate={dnf.fdate}
        stime={dnf.stime} reportstime={dnf.reportstime} datebi={dnf.datebi} prodcomm={dnf.prodcomm} />
    </div>
    )
  }

  const checkRecordTask = () => {
    setTaskClass(taskClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
    setShowTaskForm(true);
  }

  const handleRecordTask = async (data) => {
    let updatedData = {...data, hrid: state_crew.crew_hrid}
    setShowTaskForm(false);
    setTaskClass(taskClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
    const result = await ApiClient.post(`/api/jobs/record_task`, updatedData);
    setMessage(result.msg);
    fetchData(state_crew.crew_hrid);
  }

  const cancelRecordTask = async () => {
    setShowTaskForm(false);
    setTaskClass(taskClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
  }

  const showTasks = () => {
    const taskData = tasks.map(task => <tr key={task.id}>
      <td>{ task.stime }</td>
      <td>{ task.ftime }</td>
      <td>{ task.task_desc }</td>
    </tr>)
    return (
      <table className='table'>
        <thead>
          <tr>
            <th>Start Time</th>
            <th>Finish Time</th>
            <th>Task Desc</th>
          </tr>
        </thead>
        <tbody>
          { taskData }
        </tbody>
      </table>
    )
  }

  const getOC = (type) => {
    let ans = null;
    ocs.forEach(oc => {
      if(type==='mo' && oc.shop==='Morse' && oc.action === 'Open'){
        ans = oc.name + '[' + oc.cell + ']';
      }else if(type==='oo' && oc.shop==='Osler' && oc.action === 'Open'){
        ans = oc.name + '[' + oc.cell + ']';
      }else if(type==='mc' && oc.shop==='Morse' && oc.action === 'Close'){
        ans = oc.name + '[' + oc.cell + ']';
      }else if(type==='oc' && oc.shop==='Osler' && oc.action === 'Close'){
        ans = oc.name + '[' + oc.cell + ']';
      }
    })
    return ans;
  }

  return (
    <Layout>
      <div className="container"><br/>
        <nav className='breadcrumb'>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li>
              <a href="/menu_items/crew_menu">
                Back to Crew Menu
              </a>
            </li>
            <li className='is-active'><Link to="/runsheet">Runsheet</Link></li>
          </ul>
        </nav>



        <div className='box has-background-info'>
          <h1 className='title has-text-centered has-text-weight-bold has-text-link'>
            { crew } [{makeNiceDate(date)}]
          </h1><br/>

          <div className="level">
            <div className="level-left">
              <strong>
                <p className="level-item is-size-3">
                  Miker: { mikerName }[{ mikerCell }]
                </p>
              </strong>
            </div>
          </div>

          <div className="level is-size-4">
            <div className="level-left">
              <p className="level-item"><strong>Morse Close:</strong></p>
              <p className="level-item">{getOC('mc')}</p>
              <p className="level-item"><strong>Osler Close:</strong></p>
              <p className="level-item">{getOC('oc')}</p>
            </div>
          </div>
        </div><br/>

        <div class="box">
          { showLoader ? <Loader /> : null }
          {message ? <h3 className='title is-4 has-text-success has-text-centered'>{message}</h3> : null}
          { !safetyOk ? (<React.Fragment><h1>Safety Points Not Acknowledged</h1></React.Fragment>) :
            (<React.Fragment>
            { state_crew && state_crew.crew_master && changeCrewSelect() }
            { tasks && tasks.length>0 && showTasks() }

            <div className='field is-grouped'>
              <p className='control'>
                <button className="button is-link" onClick={ checkRecordTask }>
                  Record Task
                </button>
              </p>
              <p className='control'>
                <button className={taskClass} onClick={ cancelRecordTask }>
                  Cancel Record Task
                </button>
              </p>
            </div><br/>

            { showTaskForm ? <TaskForm handleRecordTask={handleRecordTask} /> : null }



            <div className='columns is-multiline'>
              { dnfs && displayDnfs() }
            </div>
            <hr/>
              { jobs && displayJobsStart() }
            <hr/>
              { jobs && displayJobsPending() }
            <hr/>
              { jobs && displayJobsCompleted() }
          </React.Fragment>)}
        </div>
      </div>
    </Layout>
  );
};

export default Runsheet;

//
// <section className="hero is-warning">
//   <div className="hero-body">
//     <div className="columns is-multiline">
//       <div className="title column has-text-centered">
//         Fall 2023 EH Done Today: { fallJobsDoneToday ? fallJobsDoneToday : 0}
//       </div>
//       <div className="title column has-text-centered">
//         Fall 2023 EH Left: { fallJobsLeft ? fallJobsLeft : 0}
//       </div>
//     </div>
//   </div>
// </section>
//


//         <h1 className='title has-text-weight-bold has-text-black has-text-centered is-family-sans-serif'>Official Spring 2023 Return To Work Date: Mon, April 10<span className='ml-3 has-text-danger'>[STOP EI]</span></h1><br/>
// code 123 :
