import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import useRequest from "../../hooks/useRequest";
import ApiClient from "../../api/client";
import { formatDate } from "../../helpers/helper";

import Layout from "../../layout/Layout";
import PropertyFilters from "../../components/property-management/PropertyFilters";
import Loader from "../../components/Loader";
import TransactionPanel from "../../components/property-management/TransactionPanel";

const PropertyManagement = () => {
  const [type, setType] = useState("All");
  const [startDate, setStartDate] = useState("2020-01-01");
  const [finishDate, setFinishDate] = useState(formatDate());
  const [location, setLocation] = useState("");
  const [searchText, setSearchText] = useState("");
  const { data: trans, loading: loadingTrans, refetch } = useRequest(
    `/api/props/fetch_transactions`,
    null
  );

  const onSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };
  const onTypeChange = (e) => {
    setType(e.target.value);
  };
  const onLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const onStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const onFinishDateChange = (e) => {
    setFinishDate(e.target.value);
  };

  const applyFilters = () =>
    trans.filter((tran) => {
      let passedFilters = true;
      if (!tran.location.includes(location)) {
        passedFilters = false;
      }
      if (
        !tran.location?.includes(searchText) &&
        !tran.notes?.includes(searchText) &&
        !tran.description?.includes(searchText) &&
        !tran.amount?.includes(searchText)
      ) {
        passedFilters = false;
      }
      console.log("Tran Type:", tran.typeIncome);
      console.log("Type:", type);

      if (dayjs(tran.date).diff(dayjs(startDate)) < 0) {
        passedFilters = false;
      }
      if (dayjs(tran.date).diff(dayjs(finishDate)) > 0) {
        passedFilters = false;
      }
      if (type === "Revenue" && tran.typeIncome !== "Revenue") {
        passedFilters = false;
      }
      if (type === "Expense" && tran.typeIncome !== "Expense") {
        passedFilters = false;
      }
      return passedFilters;
    });

  const onSubmit = (params) => {
    console.log("PARAMS: ", params);
  };

  const onDeleteTransaction = async (id) => {
    const response = await ApiClient.delete(
      `/api/props/${id}/delete_transaction`
    );
    console.log("R", response);
    refetch();
  };

  return (
    <Layout>
      <div className="container">
        <PropertyFilters
          onSearchTextChange={onSearchTextChange}
          onTypeChange={onTypeChange}
          numberTrans={trans?.length}
          onLocationChange={onLocationChange}
          onStartDateChange={onStartDateChange}
          onFinishDateChange={onFinishDateChange}
          searchText={searchText}
          type={type}
          location={location}
          startDate={startDate}
          finishDate={finishDate}
        />
        {!loadingTrans ? (
          <div>
            {applyFilters()?.map((tran) => (
              <TransactionPanel
                tran={tran}
                key={tran.id}
                onDeleteTransaction={onDeleteTransaction}
              />
            ))}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </Layout>
  );
};

export default PropertyManagement;
