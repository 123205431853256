import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import dayjs from "dayjs";

import FormFields from "./FormFields";
import FormCard from "../../components/FormCard";
import Layout from "../../layout/Layout";
import ApiClient from "../../api/client";

const NewPropertyTransaction = () => {
  const history = useNavigate();
  const { register, handleSubmit, reset } = useForm({
    defaultValues: { date: dayjs().format("YYYY-MM-DD") },
  });

  const onSubmit = async (params) => {
    console.log("PARAMS: ", params);
    const response = await ApiClient.post(`/api/props/add_transaction`, params);

    if (response) {
      history.push("/transactions");
    }
  };

  return (
    <Layout>
      <div className="container">
        <button className="button button-assign" onClick={() => history.goBack()}>
          Back
        </button><br/>
        <div className="columns">
          <div className="column is-one-third">
            <FormCard>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormFields register={register} />
              </form>
            </FormCard>
          </div>
        </div>
      </div>  
    </Layout>
  );
};

export default NewPropertyTransaction;
