import React, { useState, useEffect } from 'react';
import { Link, Redirect, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import Loader from "../../../../base/layout/Loader";
import { useStateReducer } from "../../../../base/context/authContext";
import ApiClient from "../../../../base/api/client";
import { makeNiceDate, formatCurrency } from "../../../helpers/helper";
import { hours, mins } from "../../../helpers/lists";
import { ErrorMsg } from "../../../components/ErrorMsg"




const TaskForm = ({ handleRecordTask }) => {
  const [info, setInfo] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [buttonState, setButtonState] = useState('hide');
  const [showGas, setShowGas] = useState('is-hidden');
  const [errorMsgs, setErrorMsgs] = useState({});
 
  const taskForm = useForm();
  const { register, handleSubmit, setValue, getValues, watch } = taskForm;
  const pad = 2;
 
  const checkData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log('NAME', name);
    console.log('VALUE', value);
    console.log('START MINUTE: ', getValues('startMinute'));
    console.log('START HOUR: ', getValues('startHour'));
    let dataOK = true;
    if(name === 'startHour' && value ==='' || (getValues('startMinute') === '' || getValues('finishHour') === ''  || getValues('finishMinute') === '')){
      dataOK = false;
    }
    if(name === 'startMinute' && value ==='' || (getValues('startHour') === '' || getValues('finishHour') === ''  || getValues('finishMinute') === '')){
      dataOK = false;
    }
    if(name === 'finishHour' && value ==='' || (getValues('startMinute') === '' || getValues('startHour') === ''  || getValues('finishMinute') === '')){
      dataOK = false;
    }
    if(name === 'finishMinute' && value ==='' || (getValues('startMinute') === '' || getValues('startHour') === ''  || getValues('finishHour') === '')){
      dataOK = false;
    }
    if(name === 'taskType' && value === 'None Selected'){
      dataOK = false;
    }
    if(name === 'taskType' && value === 'Gas' && (getValues('truckNum') === 'None Selected' || getValues('odometer') === '' || getValues('gasCard') === 'None Selected' || getValues('gasAmount') === '')){
      console.log('Truck Num:', getValues('truckNum'));
      console.log('Odometer', getValues('odometer'));
      console.log('Gas Card', getValues('gasCard'));
      console.log('Gas Amount', getValues('gasAmount'));
      dataOK = false;
    }
    if(getValues('taskType') === 'Gas' && (getValues('truckNum') === 'None Selected' || getValues('odometer') === '' || getValues('gasCard') === 'None Selected' || getValues('gasAmount') === '')){
      console.log('Truck Num:', getValues('truckNum'));
      console.log('Odometer', getValues('odometer'));
      console.log('Gas Card', getValues('gasCard'));
      console.log('Gas Amount', getValues('gasAmount'));
      dataOK = false;
    }
    if(dataOK === true){
      setButtonState("show");
    }else{
      setButtonState("hide");
    }
  }

  const gasData = (e) => {
    const value =  e.target.value;
    if(value === 'Gas'){
      setShowGas('');
    } else {
      setShowGas('is-hidden');
    }
    checkData(e);
  }

  return(
      <div className='container box has-background-info is-white'>
        <h1 className='title has-text-centered'>Record Task</h1>

        <form className='form' onSubmit={handleSubmit(handleRecordTask)}>


        <div className='columns multiline'>
          <div className='column is-3'>
            <div className='field'>
              <div className='control'>
                <label className='label' htmlFor='start_label'>Start Time:</label>
                <div className='select is-small' id='start-label'>
                  <select {...register("startHour")} onChange={checkData}>
                    { hours.map((hour)=>(<option key={hour} value={hour}>{hour}</option>)) }
                  </select>
                </div> :
                <div className='select is-small' >
                  <select {...register("startMinute")} onChange={checkData} >
                    { mins.map((min)=>(<option key={min} value={min}>{pad && min!=='' ? String(min).padStart(pad, "0") : min}</option>)) }
                  </select>
                </div>
              </div>
              <ErrorMsg msg={errorMsgs.startHourCannotBeBlank} num={ Object.keys(errorMsgs).length-1 }/>
              <ErrorMsg msg={errorMsgs.startMinuteCannotBeBlank} num={ Object.keys(errorMsgs).length-1 }/>
            </div>
          </div>


          <div className='column is-3'>
            <div className='field'>
              <div className='control'>
                <label className='label' htmlFor='finish_label'>Finish Time:</label>
                <div className='select is-small' id='finish_label'>
                  <select name='finishHour' {...register("finishHour")} onChange={checkData}>
                    { hours.map((hour)=>(<option key={hour} value={hour}>{hour}</option>)) }
                  </select>
                </div> :
                <div className='select is-small' >
                  <select {...register("finishMinute")} onChange={checkData}>
                    { mins.map((min)=>(<option key={min} value={min}>{pad && min!=='' ? String(min).padStart(pad, "0") : min}</option>)) }
                  </select>
                </div>
              </div>
              <ErrorMsg msg={errorMsgs.finishHourCannotBeBlank} num={ Object.keys(errorMsgs).length-1 }/>
              <ErrorMsg msg={errorMsgs.finishMinuteCannotBeBlank} num={ Object.keys(errorMsgs).length-1 }/>
            </div>
          </div>
        </div>


        <div className='field'>
          <div className='control'>
            <label className='label' htmlFor='task_type'>Task Type:</label>
            <div className='select' id='task_type'>
              <select {...register("taskType")} onChange={gasData} >
                <option key='1' value='None Selected'>None Selected</option>
                <option key='2' value='Gas'>Gas</option>
                <option key='3' value='Traffic'>Traffic</option>
                <option key='4' value='Client'>Client</option>
                <option key='5' value='Snack'>Snack</option>
                <option key='6' value='Washroom Break'>Washroom Break</option>
              </select>
            </div>
          </div>
        </div>


        <div className={showGas}>

          <div className='field'>
            <div className='control'>
              <label className='label' htmlFor='truck_num'>Truck #:</label>
              <div className='select' id='truck_num'>
                <select {...register("truckNum")} onChange={checkData}>
                  <option key='1' value='None Selected'>None Selected</option>
                  <option key='2' value='25'>Truck 25</option>
                  <option key='3' value='27'>Truck 27</option>
                  <option key='4' value='29'>Truck 29</option>
                  <option key='5' value='32'>Truck 32</option>
                  <option key='6' value='33'>Truck 33</option>
                  <option key='7' value='35'>Truck 35</option>
                  <option key='8' value='36'>Truck 36</option>
                  <option key='9' value='37'>Truck 37</option>
                  <option key='10' value='38'>Truck 38</option>
                  <option key='11' value='39'>Truck 39</option>
                  <option key='12' value='40'>Truck 40</option>
                  <option key='13' value='41'>Truck 41</option>
                  <option key='14' value='42'>Truck 42</option>
                  <option key='15' value='44'>Truck 44</option>
                  <option key='16' value='45'>Truck 45</option>
                  <option key='17' value='46'>Truck 46</option>
                </select>
              </div>
            </div>
          </div>

          <div className='field'>
            <div className='control'>
              <label className="label" forhtml='odometer'>Odometer Reading</label>
              <input
                className='input'
                type='text'
                id='odometer'
                {...register("odometer")}
                placeholder="Odometer Reading..."
                onKeyUp={checkData}
              />
            </div>
          </div>

          <div className='field'>
            <div className='control'>
              <label className='label' htmlFor='task_type'>Gas Card #:</label>
              <div className='select' id='task_type'>
                <select {...register("gasCard")} onChange={checkData}>
                  <option key='1' value='None Selected'>None Selected</option>
                  <option key='2' value='25'>25</option>
                  <option key='3' value='27'>27</option>
                  <option key='4' value='29'>29</option>
                  <option key='5' value='32'>32</option>
                  <option key='6' value='33'>33</option>
                  <option key='7' value='35'>35</option>
                  <option key='8' value='36'>36</option>
                  <option key='9' value='37'>37</option>
                  <option key='10' value='38'>38</option>
                  <option key='11' value='39'>39</option>
                  <option key='12' value='40'>40</option>
                  <option key='13' value='41'>41</option>
                  <option key='14' value='42'>42</option>
                  <option key='15' value='44'>44</option>
                  <option key='16' value='45'>45</option>
                  <option key='17' value='46'>46</option>
                  <option key='18' value='Visa'>Visa</option>
                  <option key='19' value='Personal'>Personal</option>
                </select>
              </div>
            </div>
          </div>

          <div className='field'>
            <div className='control'>
              <label className="label" forhtml='gas_amount'>Gas Amount</label>
              <input
                className='input'
                type='text'
                id='gas_amount'
                {...register("gasAmount")}
                placeholder="Gas Amount..."
                onKeyUp={checkData}
              />
            </div>
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <label className="label" forhtml='task _notes'>Task Notes</label>
            <textarea
              className='textarea'
              rows='2'
              id='task_notes'
              {...register("taskNotes")}
              placeholder="Task Notes..."
            />
          </div>
        </div><br/>
        <div className='has-text-centered'>
          { buttonState === 'show' ?
            <button name='record_task' type='submit' className='button is-link is-rounded has-text-weight-bold'>Record Task</button> :
            <button name='record_task' type='submit' className='button is-link is-rounded has-text-weight-bold' disabled>Record Task Incomplete</button>
          }
        </div>
      </form>

    </div>
  )

}

export default TaskForm;


