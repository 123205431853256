import React from "react";
import { formatCurrency } from "../helpers/helper";
import dayjs from "dayjs";

const CrewActivity = ({ crewActivity }) => {
  let totalProd = 0;
  crewActivity.forEach(job => totalProd += parseFloat(job.price));

  return (
    <div class="box">
      <table className="table">
        <thead>
          <tr>
            <th>JobID</th>
            <th>Job Desc</th>
            <th>Address</th>
            <th>Price</th>
            <th>Start</th>
            <th>Finish</th>
            <th>Minutes</th>
          </tr>
        </thead>
        <tbody>
          {crewActivity.map((job) => {
            const start = dayjs(job.datebi + "T" + job.reportstime);
            const finish = dayjs(job.datebi + "T" + job.reportftime);
            const minutes = finish.diff(start, "minutes");

            return (
              <tr key={job.jobid}>
                <td>{job.jobid}</td>
                <td>{job.jobdesc}</td>
                <td>{job.address}</td>
                <td>
                  {formatCurrency(job.price)}
                </td>
                <td>
                  {job.reportstime}
                </td>
                <td>
                  {job.reportftime}
                </td>
                <td>
                  {minutes}
                </td>
              </tr>
            );
          })}

          <tr key='total'>
            <th colSpan={7} className="has-text-weight-bold">
              Total Prod: {formatCurrency(totalProd)}
            </th>
          </tr>

        </tbody>
      </table>
    </div>
  );
};

export default CrewActivity;
