import React from "react";

import OPEN from "../assets/open.png";
import { calcPercentage } from "../helpers/helper";

const ShowPayData = ({ payData, dailyProfileDate, openDayProfile }) => {
  return (
    <div className='box'>
    <table className="table">
      <thead>
        <tr>
          <th>Days</th>
          <th>Amount</th>
          <th>Labour</th>
          <th>%</th>
          <th>No Pay</th>
          <th>No Audit</th>
          <th>Open</th>
        </tr>
      </thead>
      <tbody>
        {payData.map((item) => (
          <tr key={item.date} className={item.date === (dailyProfileDate ? "has-backgropund-danger" : undefined)}>
            <td>
              {item.date}
            </td>
            <td>
              {item.daily[0].total}
            </td>
            <td>
              {item.labour[0].total_labour}
            </td>
            <td>
              {calcPercentage(item.labour[0].total_labour, item.daily[0].total)}
            </td>
            <td>
              {item.no_pays.length}
            </td>
            <td>
              {item.names_unaudited.length}
            </td>
            <td>
              <img
                className="image is-32x32"
                src={OPEN}
                alt="open"
                onClick={() => openDayProfile(item.date)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
};

export default ShowPayData;



// name="dailyProfileDate"
// value={item.date}

