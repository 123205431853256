import React from "react";

const SelectOptionsSmallTwo = React.forwardRef(
  ({ options, name, errors, onChange, pad = 2, selectedMgr }, ref) => {
    const error = (errors || {})[name]?.[0];
    return (
      <div>
        {error ? <p className="has-text-danger">{error}</p> : null}
        <select
          className="is-size-6"
          name={name}
          ref = {ref}
          onChange={onChange}
          value={selectedMgr ? selectedMgr : ''}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {pad ? String(option).padStart(pad, "0") : option}
            </option>
          ))}
        </select>
      </div>
    );
  }
);

export default SelectOptionsSmallTwo;
