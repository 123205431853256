import React, { useState, useEffect } from "react";
import Layout from "../layout/Layout";
import { useStateReducer } from "../../base/context/authContext";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faCogs, faDiceFour } from '@fortawesome/free-solid-svg-icons'

// https://fontawesome.com/v5/cheatsheet
// import { github } from '@fortawesome/free-solid-svg-icons'

//import InputField from "../components/forms/InputField";
//import TextArea from "../components/forms/TextArea";

import useRequest from "../../base/hooks/useRequest";
import useLazyRequest from "../../base/hooks/useLazyRequest";
import ApiClient from "../../base/api/client";
import DisplayPanel from "../components/DisplayPanel";
import { checkAreaRange } from "../helpers/areas";

const element = <p className='has-text-danger is-size-1'>Test Has Text</p>

//<FontAwesomeIcon icon={ faDiceFour } />
//const element1 = <FontAwesomeIcon icon={ github} />
const element1 = null //<FontAwesomeIcon icon={["fab", "coffee"]} />



const options = [
  {key: '1', name: 'All'},
  {key: '2', name: 'A Priority'},
  {key: '3', name: 'B Priority'},
  {key: '4', name: '0 Attempts'},
  {key: '5', name: '1 Attempt'},
  {key: '6', name: '2+ Attempts'},
  {key: '7', name: 'Delivered Today'},
  {key: '8', name: 'Delivered YTD'},
]

const areaOptions = [
  {key: '1', name: 'All Areas'},
  {key: '2', name: 'Etobicoke +'},
  {key: '3', name: 'West End'},
  {key: '4', name: 'Central South'},
  {key: '5', name: 'Central'},
  {key: '6', name: 'Central North'},
  {key: '7', name: 'East End'},
  {key: '8', name: 'Scarborough +'},
  {key: '9', name: 'North Toronto'},
]


const WineList = () => {
  const [message, setMessage] = useState(null);
  const [postcode, setPostcode] = useState('');
  const [perly, setPerly] = useState('');
  const [range, setRange] = useState('All');
  const [areaRange, setAreaRange] = useState('All Areas');
  const [wineClients, setWineClients] = useState([]);
  const [loadWineDelivered, setLoadWineDelivered] = useState(false);
  const [deliveryPerson, setDeliveryPerson] = useState("")
  const [needEmail, setNeedEmail] = useState(0)
  const [num, setNum] = useState(0)
  const [{ user, userLoading }] = useStateReducer();

  const ccForm = useForm();

  const { register, handleSubmit, setValue } = ccForm;

  const loadWineClients = async () => {
    if (user) {
      const result = await ApiClient.request(`/api/tasks/fetch_wine_clients`);
      console.log(result)
      setWineClients(result.wine_list)
      setDeliveryPerson(result.delivery_person)
    }
  };

  const loadWineClientsDelivered = async () => {
    if (user) {
      const result = await ApiClient.request(`/api/tasks/fetch_wine_clients_delivered`);
      console.log(result)
      setWineClients(result.wine_list)
      setDeliveryPerson(result.delivery_person)
    }
  };

  useEffect(() => {
    if (loadWineDelivered) {
      loadWineClientsDelivered();
      setLoadWineDelivered(false);
    }
  }, [loadWineDelivered]);

  useEffect(() => {
    if(range === 'Delivered YTD'){
      loadWineClientsDelivered();
//      setLoadWineDelivered(true);
    }else{
//      setLoadWineDelivered(false);
      loadWineClients();
    }

  }, [range])


  useEffect(() => {
    if (user) {
      loadWineClients();
    }
  }, [user]);


  useEffect(() => {
    let num = 0
    wineClients.forEach((wc) => {
      if((wc.email_sent === null) && (wc.delivery_date === 'Today')){
        num += 1
      }
    })
    console.log("Num:", num)
    setNeedEmail(num)
  }, [wineClients]);

  // const onSubmit = async (data) => {
  //   if (data.name != "" && data.address != "" && data.phone != "") {
  //     var updatedData = { ...data, hrid: user.hrid };
  //     console.log("UD", updatedData);
  //     const result = await ApiClient.post(
  //       `/api/tasks/save_new_client`,
  //       updatedData
  //     );
  //     console.log("Result", result);
  //     setMessage(result.message);
  //     setValue("name", "");
  //     setValue("address", "");
  //     setValue("postcode", "");
  //     setValue("phone", "");
  //     setValue("email", "");
  //     setValue("notes", "");
  //
  //     // setButtonClass("button is-danger");
  //     // setSubmitButtonClass("button is-danger is-fullwidth");
  //     window.scrollTo(0, 0);
  //     loadNewClients();
  //   } else {
  //     setMessage("Submit Failed No Data");
  //     window.scrollTo(0, 0);
  //   }
  // };

  const wineDelivered = async (id) => {
    const result = await ApiClient.request(`/api/tasks/${id}/save_wine_delivered`);
    setWineClients(wineClients.map((wc) => {
        if(wc.id === id){
          wc.delivery_date = 'Today'
        }
        return wc
    }));
  }

  const wineUnDelivered = async (id) => {
    const result = await ApiClient.request(`/api/tasks/${id}/save_wine_undelivered`);
    setWineClients(wineClients.map((wc) => {
        if(wc.id === id){
          wc.delivery_date = null
        }
        return wc
    }));
  }

  const wineAttempt = async (id) => {
    const result = await ApiClient.request(`/api/tasks/${id}/save_wine_attempt`);
    setWineClients(wineClients.map((wc) => {
        if(wc.id === id){
          wc.attempts = wc.attempts + 1
        }
        return wc
    }));
  }

  const filterWineClients = (wineClients) => {
    const result = wineClients.filter((wc) => {

      if(wc && wc.postcode && wc.perly && wc.postcode.toLowerCase().includes(postcode.toLowerCase()) && wc.perly.toLowerCase().includes(perly.toLowerCase())){

        if (areaRange !== 'All Areas'  && !wc.delivery_date){
          return areaRange ? checkAreaRange(wc, areaRange) : null ;
        } else if (range === 'All' && !wc.delivery_date) {
          return true
        } else if(range === 'A Priority' && wc.priority === 'A' && !wc.delivery_date) {
          return true
        } else if(range === 'B Priority' && wc.priority === 'B' && !wc.delivery_date) {
          return true
        } else if(range === '0 Attempts' && wc.attempts === 0 && !wc.delivery_date) {
          return true
        } else if(range === '1 Attempt' && wc.attempts === 1 && !wc.delivery_date) {
          return true
        } else if(range === 'Delivered Today' && wc.delivery_date === 'Today') {
          return true
        } else if(range === 'Delivered YTD') {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    })
    return result
  }

  const onPostCodeChange = (e) => {
    setPostcode(e.target.value)
  }

  const onPerlyChange = (e) => {
    setPerly(e.target.value)
  }

  const onChangeRange = (e) => {
    setRange(e.target.value)
  }

  const onChangeArea = (e) => {
    setAreaRange(e.target.value)
  }


  const emailThankYou = async () => {
    console.log('Email Sent')
    const result = await ApiClient.request(`/api/tasks/send_wine_emails`);
    setWineClients(wineClients.map((wc) => {
      wc.email_sent = 'Today'
      return wc
    }));
  }


  const onSubmit = async (data) => {
      var updatedData = { ...data, hrid: user.hrid };
      console.log("UD", updatedData);
      // const result = await ApiClient.post(
      //   `/api/tasks/save_new_client`,
      //   updatedData
      // );
      // console.log("Result", result);
      // setMessage(result.message);
      setValue("name", "");

  };


  return (
    <Layout>
      <div className="container">

        <div className="filters--ind-center">
          <select
            name="range"
            className="filters__input  filters__input--range"
            onChange={onChangeRange}
            value={range === null ? "" : range}
          >
            {options.map((op) => (
              <option key={op.key} value={op.name}>
                {op.name}
              </option>
            ))}
          </select>
        </div>

        <div className="filters--ind-center">
          <select
            name="areaRange"
            className="filters__input  filters__input--range"
            onChange={onChangeArea}
            value={areaRange === null ? "" : areaRange}
          >
            {areaOptions.map((op) => (
              <option key={op.key} value={op.name}>
                {op.name}
              </option>
            ))}
          </select>
        </div>

        <div className="filters--set">
          <input
            className="filters__input filters__input--lp"
            onKeyUp={onPostCodeChange}
            placeholder="Post Code"
          />
          <input
            className="filters__input"
            onKeyUp={onPerlyChange}
            placeholder="Perly"
          />
        </div>

        <div className="filters--ind-center">
          <button className="button-wine__red" onClick={()=>{emailThankYou()}}>Email Thank You All {needEmail}</button>
        </div><br/><br/><br/>

      <div className="filters--ind-center filters--ind-center--bold"># Clients: {filterWineClients(wineClients).length}</div>
      {wineClients && (
        <div className="margin-top">
          {filterWineClients(wineClients).map((wc) => (
            <DisplayPanel
              key = {wc.id}
              id = {wc.id}
              cfid = {wc.cfid}
              name = {wc.name}
              address = {wc.address}
              phone = {wc.phone}
              email = {wc.email}
              perly = {wc.perly}
              postcode = {wc.postcode}
              priority = {wc.priority}
              attempts = {wc.attempts}
              delivery_date = {wc.delivery_date}
              delivered_by = {wc.delivered_by}
              wineDelivered = {wineDelivered}
              wineUnDelivered = {wineUnDelivered}
              wineAttempt = {wineAttempt}
            />
          ))}
        </div>
      )}

      </div>
    </Layout>
  );
};

export default WineList;


