import React from 'react';

import { useStateReducer } from "../../base/context/authContext";



const linkStyle = {
  textDecoration: 'underline'
}

const EditPersonalInfoForm = ({
  register,
  getValues,
  setValue,
  handleSubmit,
  submitForm,
  title,
  details,
  message
}) => {
  const [{ client }, dispatch] = useStateReducer();

  const sameAsCell = () => {
    const cell = getValues('cellphone');
    setValue('phone', cell);
  }

  const sameAsHome = () => {
    const home = getValues('phone');
    setValue('cellphone', home);
  }

  return (<div className='box'>
    <h2 className='subtitle is-3 has-text-centered has-text-success'>{message}</h2><br/>
    <h2 className='title is-4 has-text-centered'>Edit Personal Info</h2>
    <form onSubmit={handleSubmit(submitForm)}>
      <div className='field'>
        <div className='control'>
          <label className='label' htmlFor='honorific'>Honorific</label>
          <input
            type='text'
            className='input'
            id='honorific'
            {...register('honorific')}
            defaultValue={ client && client.honorific }
        />
        </div>
      </div>
      <div className='field'>
        <div className='control'>
          <label className='label' htmlFor='firstname'>First Name</label>
          <input
            type='text'
            className='input'
            id='firstname'
            {...register('firstname')}
            defaultValue={ client && client.firstname }
          />
        </div>
      </div>
      <div className='field'>
        <div className='control'>
          <label className='label' htmlFor='lastname'>Last Name</label>
          <input
            type='text'
            className='input'
            id='lastname'
            {...register('lastname')}
            defaultValue={ client && client.lastname}
          />
        </div>
      </div>
      <div className='field'>
        <div className='control'>
          <label className='label' htmlFor='cellphone'>Phone 1
            (<a style={linkStyle} className="panel-block is active has-text-link has-text-weight-bold is-inline is-underlined" onClick={() => sameAsCell()}>
              same as phone 2
            </a>)
          </label>

          <input
            type='text'
            className='input'
            id='phone'
            {...register('phone')}
            defaultValue={ client && client.phone}
          />
        </div>
      </div>
      <div className='field'>
        <div className='control'>
          <label className='label' htmlFor='cellphone'>Phone 2
            (<a style={linkStyle} className="panel-block is active has-text-link has-text-weight-bold is-inline is-underlined" onClick={() => sameAsHome()}>
              same as phone 1
            </a>)
          </label>

          <input
            type='text'
            className='input'
            id='cellphone'
            {...register('cellphone')}
            defaultValue={ client && client.cellphone}
          />
        </div>
      </div>
      <div className='field'>
        <div className='control'>
          <label className='label' htmlFor='email'>Email</label>
          <input
            type='text'
            className='input'
            id='email'
            {...register('email')}
            defaultValue={ client && client.email}
          />
        </div>
      </div>
      <div className='field'>
        <div className='control'>
          <label className='label' htmlFor='second_email'>Second Email</label>
          <input
            type='text'
            className='input'
            id='second_email'
            {...register('second_email')}
            defaultValue={ client && client.second_email}
          />
        </div>
      </div>
      <button className='button is-primary' type='submit'>Edit Personal Info</button>

    </form>

    <nav className="level mt-5">
      <div className="level-left">
        <div className="level-item">
          <p className="subtitle is-5">
            <strong className="has-text-link">
              If you want to add/remove properties or change billing address,
            </strong>
          </p>
        </div>
      </div>
    </nav>

    <nav className="level">
      <div className="level-left">
        <div className="level-item">
          <p className="subtitle is-5">
            <strong className="has-text-link">
              please email us at dashboard@whiteshark.ca.
            </strong>
          </p>
        </div>
      </div>
    </nav>


  </div>)
}

export default EditPersonalInfoForm;


// <ul className="steps is-balanced">
//       <li className="steps-segment">
//         <span className="steps-marker"></span>
//         <div className="steps-content">
//           <p className="is-size-4">Step 1</p>
//           <p>This is the first step, which means you start here.</p>
//         </div>
//       </li>
//       <li className="steps-segment">
//         <span className="steps-marker"></span>
//         <div className="steps-content">
//           <p className="is-size-4">Step 2</p>
//           <p>This is the second step. Once you complete the first step, you will end up here.</p>
//         </div>
//       </li>
// </ul>
//
