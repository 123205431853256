import React from "react";
import { Link } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import Loader from "../Loader";
import TypeRangeOptions from "./TypeRangeOptions";
import LocationOptions from "./LocationOptions";

import { locationData } from "../../helpers/lists";

const PropertyFilters = ({
  numberTrans,
  searchText,
  type,
  location,
  startDate,
  finishDate,
  onSearchTextChange,
  onTypeChange,
  onLocationChange,
  onStartDateChange,
  onFinishDateChange,
}) => {
  const { data: descriptionOptions, loading } = useRequest(
    "/api/props/fetch_descriptions"
  );

  return loading ? (
    <Loader />
  ) : (
    <div className="panel"><br/><br/>
      <div className="level">
          <input
            className="level-item is-size-4"
            onChange={onSearchTextChange}
            value={searchText}
            placeholder="Search Text"
          />

          <LocationOptions
            className="level-item is-size-4"
            onChange={onLocationChange}
            value={location === "" || location === null ? "" : location}
            options={locationData}
            name="location"
          />
        
        <div className="level-item">
          <TypeRangeOptions onTypeChange={onTypeChange} value={type} />
        </div><br/><br/>

        <div className="level-item">
          <input
            className="is-size-4"
            type="date"
            value={startDate}
            onChange={onStartDateChange}
            name="startDate"
          />
          <input
            className="is-size-4"
            type="date"
            value={finishDate}
            onChange={onFinishDateChange}
            name="finishDate"
          />
        </div>
      </div><br/>  
      <div className="level">
        <span className="level-item is-size-4">#Transactions: {numberTrans}</span>
        <span className="level-item is-size-4"><Link to="/transactions/new">Create Transaction</Link></span>
        <span className="level-item is-size-4"><Link to="/descriptions/new">Create Description</Link></span>
      </div>
    </div>
  );
};

export default PropertyFilters;
