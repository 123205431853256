import React, { useReducer, useState, useCallback, useEffect } from "react";
import ActionType from "../base/context/actionType";
import LoginAPI from "../base/api/login";
import AppRouter from "./routers/AppRouter";
import { stateReducer, initialState } from "../base/context/stateReducer";
import { AuthContext } from "../base/context/authContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

function App({ crew_master }) {

  const stateTuple = useReducer(stateReducer, initialState);
  const [{ user, userLoading }, dispatch] = stateTuple;
  //const [user, setUser] = useState(null);

  const fetchUser = useCallback(async () => {
    // check if signed in(run loader while waiting)
    // if yes, dispatch object that sets the user
    // check request finished/error(show message)
    // if (userLoading) return;
    const user  = await LoginAPI.verifySession();
   // setUser(user);
    dispatch({
      type: ActionType.SetUser,
      payload: {
        ...user,
        crewMaster: crew_master
      }
    });

    console.log("USer: ", user);

    const crew = {
      crew_name: user.fullname,
      crew_hrid: user.hrid,
      crew_master: crew_master
    }

    console.log("CrEW: ", crew);

    dispatch({
      type: ActionType.SetCrew,
      payload: crew,
    });

   
    const mgr_session  = await LoginAPI.verifyMgr();

    console.log('MGR 1', mgr_session);

   
    if(mgr_session){
      dispatch({
        type: ActionType.SetMgr,
        payload: mgr_session,
      });
    }

  }, [dispatch, userLoading]);

  useEffect(() => {
    if(!user){
      fetchUser();
    } else {
      console.log("USer: ", user);
    }
  }, [fetchUser]);

  return (
    <AuthContext.Provider value={stateTuple}>
      <AppRouter />
    </AuthContext.Provider>
  );
}

export default App;
