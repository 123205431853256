import React from "react";
import SHARK from "../assets/sharkloader-2.gif";

const Loader = () => {
  return (
   <div className="columns is-flex is-justify-content-center is-vcentered mt-5">
    <figure className="image is-256x256">
      <img
        className="is-rounded"
        src={SHARK}
        alt="shark"
      />
    </figure>


    </div>
  );
};

export default Loader;
