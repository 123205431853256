import React from "react";
import Loader from "./Loader";
import SelectOptions from "./SelectOptions";

import { shopOptions } from "../helpers/lists";
import useRequest from "../hooks/useRequest";
import SelectOptionsObj from "./SelectOptionsObj";

const CrewSetUpForm = ({ onSubmit, register, ocid, errors }) => {
  const { data: lists, loading } = useRequest("/api/crews/crew_set_up_lists");
  if (loading) {
    return <Loader />;
  }

  return (
    <form className="form crew-setup-form" onSubmit={onSubmit}>
      <div className="form__row">
        <label className="form__label" htmlFor="date">
          Date
        </label>
        <input
          id="date"
          className="form__input form__input__date"
          type="date"
          {...register("ocdate")}
        />
      </div>
      <SelectOptionsObj
        options={lists.mgrOptions}
        label="Mgr"
        {...register("closemgr")}
        errors={errors}
      />
      <SelectOptionsObj
        options={lists.driverOptions}
        label="Driver"
        {...register("driver")}
        errors={errors}
      />
      <SelectOptionsObj
        options={lists.partnerOptions}
        label="Partner"
        {...register("partner")}
        errors={errors}
      />
      <SelectOptionsObj
        options={lists.partnerOptions}
        label="Partner 1"
        {...register("partner1")}
        errors={errors}
      />

      <SelectOptions
        options={shopOptions}
        label="Shop"
        {...register("shop")}
        errors={errors}
      />

      <SelectOptions
        options={lists.vehicleOptions}
        label="Truck"
        {...register("car")}
        errors={errors}
      />

      <SelectOptions
        options={lists.gascardOptions}
        label="Gas Card"
        {...register("gascard")}
        errors={errors}
      />

      <SelectOptions
        options={lists.tabletOptions}
        label="Tablet"
        {...register("laptop")}
        errors={errors}
      />

      <button className="form__input form__input__button" type="submit">
        {ocid ? "Update" : "Create"}
      </button>
    </form>
  );
};

export default CrewSetUpForm;
