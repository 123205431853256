import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router";
import { createBrowserHistory } from "history";

import Home from "../layout/Home";
import Services from "../views/Services";
import Dashboard from "../views/Dashboard";
import About from "../views/About";
import Contact from "../views/Contact";
import ResetPassword from "../views/ResetPassword";
import SmartSearch from "../views/SmartSearch";

export const history = createBrowserHistory({ basename: "crews/react_client_dashboard" });

const AppRouter = () => {
  return (
    <Router history = {history} basename = "/crews/react_client_dashboard">
      <Routes>
        <Route path="/" exact element={ <Home />} />
        <Route path="/services" element={ <Services /> } />
        <Route path="/dashboard" element={ <Dashboard /> } />
        <Route path="/about" element={ <About /> } />
        <Route path="/contact" element={ <Contact /> } />
        <Route path="/reset_password" element={ <ResetPassword /> } />
        <Route path="/smart_search" element={ <SmartSearch /> } />
      </Routes>
    </Router>
  )
};

export default AppRouter;


// <Route index element={ <Dashboard /> } />
// <Route path="/home" element={ <Home /> } />
       
