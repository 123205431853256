import React, { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';

import { useStateReducer } from "../../../base/context/authContext";
import ActionType from "../../../base/context/actionType";
import ApiClient from "../../../base/api/client";
import { formatCurrency } from "../../helpers/helper";
import { ErrorMsg } from "../../components/ErrorMsg";

const JobAddress = ({ register, getValues, errorMsgs, onChangeJobSpecs }) => {
  const [{ props }, dispatch] = useStateReducer();
  const[address, setAddress] = useState(null);
  const[numAddress, setNumAddress] = useState(null);

  const[prices, setPrices] = useState(null);
  const[search, setSearch] = useState('');

  const MagnifyingGlass = <FontAwesomeIcon icon={ faSearchengin } />;

  const fetchPrices = async (jobinfoid) => {
    const prices = await ApiClient.request(`/api/properties/${jobinfoid}`);
    setPrices(prices);
  }

  useEffect(() => {
    console.log('Props Out: ', props.length);
    if(props.length === 1){
      console.log('Props In: ', props.length);
      loadPriceBarOneProperty();
    }
  }, [props])

  useEffect(() => {
    console.log('Props Out: ', props.length);
    if(props.length === 1){
      console.log('Props In: ', props.length);
      loadPriceBarOneProperty();
    }else{
      console.log('Address', getValues("address"));
      const jobinfoid = getValues("address");
      if(jobinfoid !== 'None Selected'){
        const temp = props.filter(prop => prop.jobinfoid === jobinfoid)
        setAddress(temp[0] ? temp[0].address : 'None Selected');
       
        fetchPrices(jobinfoid);
        dispatch({type: ActionType.SetSelectedJobInfoId, payload: jobinfoid});
        onChangeJobSpecs();
      }
    }
  }, [])

  // fetchPrices(jobinfoid);
  // dispatch({type: ActionType.SetSelectedJobInfoId, payload: jobinfoid});
  // setAddress(props[0].address);

  // onChangeJobSpecs();


  const priceBar = () => {
    return prices ?
    <span>
      <span className='mr-3'>W1:{ formatCurrency(prices.w1) }</span>
      <span className='mr-3'>EH:{ formatCurrency(prices.eh)}</span>
      <span className='mr-3'>EG:{ formatCurrency(prices.eg)}</span>
    </span> : null
  }

  const selectProperty = (e) => {
    const jobinfoid = e.target.value;
    const temp = props.filter(prop => prop.jobinfoid === jobinfoid)
    setAddress(temp[0] ? temp[0].address : 'None Selected');
    if(jobinfoid !== 'None Selected'){
      fetchPrices(jobinfoid);
      dispatch({type: ActionType.SetSelectedJobInfoId, payload: jobinfoid});
    } else {
      dispatch({type: ActionType.SetSelectedJobInfoId, payload: null});
    }
    onChangeJobSpecs();
  }

  const loadPriceBarOneProperty = () => {
    const jobinfoid = props[0].jobinfoid;
    console.log("LOAD jobinfoid", jobinfoid);
    fetchPrices(jobinfoid);
    dispatch({type: ActionType.SetSelectedJobInfoId, payload: jobinfoid});
    setAddress(props[0].address);
  
    onChangeJobSpecs();
  }

  const addressOptions = (search) => {
    return props.filter(prop => prop.address.toLowerCase().includes(search.toLowerCase())).map((prop, index) => <option key={index} value={prop.jobinfoid}>{ prop.address }</option>)
  }

  const updateSearch = (e) => {
    setSearch(e.target.value);
  }

  const getPrices = () => {
    return prices && address && address!=='None Selected' ?
     (<div className='mt-3'>
        <div className='is-size-6-mobile has-background-info is-size-5 has-text-weight-bold p-2' htmlFor='pricebar'>
          Prices for { address }:&nbsp; { priceBar() }
        </div>
    </div>) : null;
  }

  const searchBar = () => {
    return props.length > 10 ?
    <div className='columns'>
      <div className='column is-two-fifths'>
        <div className='field'>
          <div className='control has-icons-left'>
            <input type='text' className='input' placeholder="Search Address" onChange={updateSearch} />
            <span className='icon is-medium is-left mt-2 ml-1'>
              <i className='icon'>{ MagnifyingGlass }</i>
            </span>
          </div>
        </div>
      </div>
    </div> : null
  }

  return (
    <div>
      { props && searchBar() }
      <div className='field mb-2'>
        <div className="control">
          <label className='label' htmlFor='address'>Address { props && props.length > 10 ? addressOptions(search).length : null }</label>
          <div className='select'>
            <select className='select' {...register('address')} onChange={ selectProperty }>
              { props.length === 1 ? null : <option value="None Selected">None Selected</option>}
              { props && addressOptions(search)};
            </select>
          </div>
          <ErrorMsg msg={errorMsgs.addressMustBeFilled} num={ Object.keys(errorMsgs).length-1 }/>
          { getPrices() }
        </div>
      </div>
    </div>

  )
}

export default JobAddress;





















// -------------------------------------------------------------------------------------------
