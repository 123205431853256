
import React, { useState } from 'react';

import Layout from "../layout/Layout";
import { useForm } from "react-hook-form";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser } from '@fortawesome/free-regular-svg-icons';
import { faLock, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faCcVisa, faCcAmex, faCcMastercard, faCcStripe } from '@fortawesome/free-brands-svg-icons';

import ApiClient from "../../base/api/client";


const ENVELOPE = <FontAwesomeIcon icon={ faEnvelope } />;
const LOCK = <FontAwesomeIcon icon={ faLock } />;
const USER = <FontAwesomeIcon icon={ faUser } />;
const PHONE = <FontAwesomeIcon icon={ faPhone } />;
const HOME = <FontAwesomeIcon icon={ faHome } />;
const VISA = <FontAwesomeIcon icon={ faCcVisa } />;
const AMEX = <FontAwesomeIcon icon={ faCcAmex } />;
const STRIPE = <FontAwesomeIcon icon={ faCcStripe } />;
const MC = <FontAwesomeIcon icon={ faCcMastercard } />;


//const NUT = <FontAwesomeIcon icon="fa-brands fa-cc-visa" />


const Contact = () => {
  const [buttonState, setButtonState] = useState('button is-primary mt-5');
  const [messageState, setMessageState] = useState('is-5 has-text-success has-text-weight-bold is-hidden');
  const { register, setValue, getValues, handleSubmit } = useForm();




  const submitForm = async (data) => {
    setButtonState('button is-primary mt-5 is-loading');
    const result = await ApiClient.post("/api/messages/create_contact_email", data);
    setValue('name', '');
    setValue('address', '');
    setValue('phone', '');
    setValue('email', '');
    setValue('message', '');
    setButtonState('button is-primary mt-5');
    setMessageState('is-5 has-text-success has-text-weight-bold');
  }

  const killMessage = () => {
    setMessageState('is-5 has-text-success has-text-weight-bold is-hidden');
  }

  return (
    <Layout>
    <section className='section'>
      <div className='container'>
        <h2 className='title is-1 has-text-centered'>Contact</h2>

        <div className='columns is-tablet'>
          <div className='column'>
            <h4 className='title'>Address:</h4>
            <address>
              <strong>White Shark Ltd</strong><br/>
              36 Morse Street<br/>
              Toronto<br/>
              <i className='icon'>{ PHONE } </i><a href="tel:+14163637420">416-363-7420</a><br/>
              <i className='icon'>{ ENVELOPE } </i><a href="mailto:customerservice@whiteshark.ca">customerservice@whiteshark.ca</a>
            </address>
          </div>
          <div className='column'>
            <h4 className='title'>Opening Hours:</h4>
            <p>
            Monday to Friday: 9am - 5pm<br/>
            Saturday: 9am - 12pm<br/>
            Sunday: Closed<br/>
            </p>
          </div>
        </div>


        <div className='columns'>

          <div className='column'>
            <h4 className='title'>Send us a message:</h4>
            <form onSubmit={handleSubmit(submitForm)}>
              <div className='columns'>
                <div className='column'>
                  <div className='field'>
                    <label className='label' htmlFor="name">Name:</label>
                    <div className='control has-icons-left'>
                      <input type='text' id='name' {...register("name")} onClick={killMessage} className='input' placeholder="Firstname Lastname" required />
                      <span className='icon is-small is-left'>
                        <i className='icon'>{ USER }</i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className='column'>
                  <div className='field'>
                    <label className='label' htmlFor="address">Address:</label>
                    <div className='control has-icons-left'>
                      <input type='text' id='address' {...register("address")} onClick={killMessage} className='input' placeholder="1 Your Street" required />
                        <span className='icon is-small is-left'>
                          <i className='icon'>{ USER }</i>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='columns'>
                <div className='column'>
                  <div className='field'>
                    <label className='label' htmlFor="telephone">Telephone:</label>
                    <div className='control has-icons-left'>
                      <input type='text' id='telephone' {...register("phone")} onClick={killMessage} className='input' placeholder="123-456-7890" required />
                      <span className='icon is-small is-left'>
                        <i className='icon'>{ PHONE }</i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className='column'>
                  <div className='field'>
                    <label className='label' htmlFor="name">Email:</label>
                    <div className='control has-icons-left'>
                      <input type='email' id='email' {...register("email")} onClick={killMessage} className='input' placeholder="example@example.com" required />
                      <span className='icon is-small is-left'>
                        <i className='icon'>{ ENVELOPE }</i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='field'>
                <label className='label' htmlFor="message">Message:</label>
                <div className='control'>
                  <textarea rows='5' className='textarea' id='message' onClick={killMessage} {...register('message')} placeholder='Message...' required></textarea>
                </div>
              </div>
              <button className={buttonState} type='submit' >Submit</button><br/><br/>
              <h3 className={messageState}>Message sent successfully</h3>

            </form>
          </div>


          <div className='column'>
            <h4 className='title'>Where to find us:</h4>
            <figure className='image is-4by3'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.617643370283!2d-79.3417694845891!3d43.65612286025985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb74935e61a3%3A0x3be1b39226fe9b37!2s36%20Morse%20St%2C%20Toronto%2C%20ON%20M4M%202P6%2C%20Canada!5e0!3m2!1sen!2sus!4v1641848821905!5m2!1sen!2sus" width="600" height="450" style={{border:'0'}} allowFullScreen="" loading="lazy"></iframe>
            </figure>
          </div>
        </div>





      </div>
    </section>
    </Layout>
  )
}

export default Contact


// <button className='button is-primary' type='submit'>Submit</button>
// <button className='button' type='reset'>Reset</button>



// <div className='columns'>
//
//   <div className='column'>
//     <h4 className='title'>Send us a message:</h4>
//     <form>
//       <div className='columns'>
//         <div className='column'>
//           <div className='field'>
//             <label className='label' for="name">Name:</label>
//             <div className='control has-icons-left'>
//               <input type='text' id='name' name="name" className='input' placeholder="Firstname Lastname" required />
//               <span className='icon is-small is-left'>
//                 <i className='icon'>{ USER }</i>
//               </span>
//             </div>
//           </div>
//         </div>
//         <div className='column'>
//           <div className='field'>
//             <label className='label' for="name">Email:</label>
//             <div className='control has-icons-left'>
//               <input type='email' id='email' name="email" className='input' placeholder="example@example.com" required />
//               <span className='icon is-small is-left'>
//                 <i className='icon'>{ ENVELOPE }</i>
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className='columns'>
//         <div className='column'>
//           <div className='field'>
//             <label className='label' for="telephone">Telephone:</label>
//             <div className='control has-icons-left'>
//               <input type='text' id='telephone' name="phone" className='input' placeholder="123-456-7890" required />
//               <span className='icon is-small is-left'>
//                 <i className='icon'>{ PHONE }</i>
//               </span>
//             </div>
//           </div>
//         </div>
//         <div className='column'>
//           <div className='field'>
//             <label className='label' for="select">Department:</label>
//             <div className='control has-icons-left'>
//               <div className='select'>
//                 <select id='select' name="dept" required>
//                   <option value='0'>- select here -</option>
//                   <option value='1'>Sales</option>
//                   <option value='2'>Production</option>
//                   <option value='3'>Receivables</option>
//                 </select>
//               </div>
//               <span className='icon is-small is-left'>
//                 <i className='icon'>{ HOME }</i>
//               </span>
//             </div>
//             <small className='form-text'>select a department</small>
//           </div>
//         </div>
//       </div>
//       <div className='field'>
//         <label className='label' for="message">Message:</label>
//         <div className='control'>
//           <textarea rows='5' className='textarea' id='message' placeholder='Message...' required></textarea>
//         </div>
//       </div>
//       <button className='button is-primary' type='submit'>Submit</button>
//       <button className='button' type='reset'>Reset</button>
//     </form>
//   </div>
//
//
//   <div className='column'>
//     <h4 className='title'>Where to find us:</h4>
//     <figure className='image is-4by3'>
//       <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.617643370283!2d-79.3417694845891!3d43.65612286025985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb74935e61a3%3A0x3be1b39226fe9b37!2s36%20Morse%20St%2C%20Toronto%2C%20ON%20M4M%202P6%2C%20Canada!5e0!3m2!1sen!2sus!4v1641848821905!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
//     </figure>
//   </div>
// </div>
