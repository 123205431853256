import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import { NavLink, Redirect, useLocation, useNavigate, useParams } from 'react-router-dom';
//import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const CHECK = <FontAwesomeIcon icon={ faCheck } />;

import ApiClient from "../../base/api/client";
import ActionType from "../../base/context/actionType";
import Layout from '../layout/Layout';
import Loader from "../../base/layout/Loader";
import WSLOGO from "../../base/assets/white_shark.png";
import EMLOGO from "../../base/assets/extramile.png";


import { useStateReducer } from "../../base/context/authContext";
import LoaderBox from "../forms/LoaderBox";
import MessageInfo from "../forms/MessageInfo";
import SaleBlocked from "../forms/SaleBlocked";
import BookWindowsSummerForm from "../forms/BookWindowsSummerForm";
import BookWindowsFallForm from "../forms/BookWindowsFallForm";
import BookEavesSummerForm from "../forms/BookEavesSummerForm";
import BookEavesFallForm from "../forms/BookEavesFallForm";
import RequestMiscJobForm from "../forms/RequestMiscJobForm";
import DNFForm from "../forms/DNFForm";
import FeedbackForm from "../forms/FeedbackForm";
import SignPickUpForm from "../forms/SignPickUpForm";
import UpcomingJobs from "../components/UpcomingJobs";
import CurrentJobs from "../components/CurrentJobs";
import CompletedJobs from "../components/CompletedJobs";
import JobDetails from "../components/JobDetails";
import SelectOptionPrompt from "../components/SelectOptionPrompt";
import EditPersonalInfo from "../forms/EditPersonalInfo";
import { formatCurrency, validateCreateSaleData, validateRequestData, validEmail, validateFeedbackData } from "../helpers/helper";
import { parseSalesData, fullname } from "../helpers/parseData";

import { StripeCardSetup } from "../forms/StripeCardSetup";
import { StripeJobPaySecure } from "../forms/StripeJobPaySecure";
import { fetchCustomer, createCustomer, fetchSetupIntent, fetchPaymentIntent, markJobsToPay, markCcStatusRequired, secureUpcomingJobs, fetchCardList } from '../helpers/stripe';

const boxStyle = {
  height: '100%',
}

// const salePanelStyle = {
//   height: '100%',
//   marginBottom: 'auto'
// };

const Dashboard = () => {
  const [{ client, user, type, props, dashboardShowFunction, env }, dispatch] = useStateReducer();
  const [message, setMessage] = useState('');
  const [showFunction, setShowFunction] = useState(null);
  const [jobid, setJobid] = useState(null);
  const [jobplus, setJobplus] = useState(null);
  const [dnfs, setDnfs] = useState(null);
  const [fltrDateClass, setFltrDateClass] = useState('field is-hidden');
  const [apptDateClass, setApptDateClass] = useState('field is-hidden');
  const [eavesFltrCheckedClass, setEavesFltrCheckedClass] = useState('checked');
  const [eavesApptCheckedClass, setEavesApptCheckedClass] = useState('');
  const [eavesFltrClass, setEavesFltrClass] = useState('field');
  const [eavesApptClass, setEavesApptClass] = useState('field is-hidden');
  const [errorMsgs, setErrorMsgs] = useState({});
  const [triedSubmit, setTriedSubmit] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(true);
  const [saleok, setSaleok] = useState(true);
  const [balance15, setBalance15] = useState(0);

  const { register, setValue, getValues, handleSubmit } = useForm();
  const search = useLocation().search;
  const navigate = useNavigate();

  const focusDivDashboard = useRef(null);
  const focusDivFunctionWindow = useRef(null);
  const focusDivBookedJobs = useRef(null);
  const focusDivSpecialtyJobs = useRef(null);
  const focusDivBookJob = useRef(null);

  const urlMessage = new URLSearchParams(window.location.search).get('message');

  useEffect(() => {
    if(urlMessage) {
      setMessage(urlMessage);
      clientAction('message', null);
    }
  }, [urlMessage]);

  useEffect(() => {
    async function makeRocketGo() {
      console.log("GOT HERE*******************")
      const si_id = new URLSearchParams(search).get('setup_intent');
      const pi_id = new URLSearchParams(search).get('payment_intent');

      if(pi_id && user){
        const payment_intent = await fetchPaymentIntent(pi_id);
        if(payment_intent && payment_intent.status === 'succeeded'){
          const result = await markJobsToPay({pi_id: pi_id, user: user})
          const message = encodeURIComponent('Stripe Payment of ' + formatCurrency(payment_intent.amount/ 100) + ' Successful');
          navigate(`/dashboard?message=${message}`);
          return;
        } else {
          const message = encodeURIComponent(`There was a problem processing your payment. Please try again or call our office at 416-363-7420`);
          navigate(`/dashboard?message=${message}`);
          return;
        }
      } else if(si_id && user){
        const setup_intent = await fetchSetupIntent(si_id);
        let message;
        if(setup_intent && setup_intent.status == 'succeeded'){
          await secureUpcomingJobs(setup_intent.metadata.cfid);

          if(setup_intent.metadata.jobid){
            message = encodeURIComponent(`Your booking [${setup_intent.metadata.jobid}] is complete and credit card added successfully. Job confirmation has been sent to your email. View booking details in Upcoming Jobs in left menu.`);
            navigate(`/dashboard?message=${message}`);
            return;
          } else {
            message = encodeURIComponent("Credit card added successfully");
            navigate(`/dashboard?message=${message}`)
            return;
          }

        } else {
          message = encodeURIComponent("Credit card was not added. Please try again or call our office at 416-363-7420");
          navigate(`/dashboard?message=${message}`)
          return;
        }
      }
    }
    makeRocketGo();
  }, [user])

  const getMiscMessages = async () => {
    const result = await ApiClient.request(`/api/messages/${client.cfid}/misc_jobs_client`);
  }

  const getSalesOk = async () => {
    const result = await ApiClient.request(`/api/clients/${client.cfid}/get_balance_15`);
    setBalance15(result.tot);
  }

  useEffect(() => {
    if(client){
      getMiscMessages();
      if(client.contactstatus==="We don't want the client" || client.contactstatus=="Ask Client to Pay Promptly"){
        setSaleok(false);
      }
      if(balance15.to_f>25.00){
        setSaleok(false);
      }
    }
   // console.log('Balance 15:', balance15);
   // console.log('Sale OK:', saleok);
  }, [client, balance15])

  useEffect(() => {
    if(client){
      getSalesOk()
    }
  }, [client])

   useEffect(() => {
     getFunctionWindow();
   },[showFunction])

   useEffect(() => {
     if(dashboardShowFunction){
       setShowFunction(dashboardShowFunction);
       clientAction(dashboardShowFunction, null);
       dispatch({
         type: ActionType.SetDashboardShowFunction,
         payload: null,
       });
     }
   }),[dashboardShowFunction]

  const clientAction = (type, focus) => {
    type && setShowFunction(type);
    if(type!=='message'){
      setMessage('');
    }
    if(type==='bookWindowsSummer' ||
      type==='bookWindowsFall' ||
      type==='bookEavesSummer' ||
      type==='bookEavesFall'){
        setErrorMsgs({});
    }
    switch (focus) {
      case focus === 'Top Dashboard':
        focusDivDashboard && focusDivDashboard.current && focusDivDashboard.current.scrollIntoView({ behavior: 'smooth' });
        break;
      case focus === 'Booked Jobs':
        focusDivBookedJobs && focusDivBookedJobs.current && focusDivBookedJobs.current.scrollIntoView({ behavior: 'smooth' });
        break;
      case focus === 'Specialty Jobs':
        focusDivSpecialtyJobs && focusDivSpecialtyJobs.current && focusDivSpecialtyJobs.current.scrollIntoView({ behavior: 'smooth' });
        break;
      case focus === 'Book Job':
        focusDivBookJob && focusDivBookJob.current && focusDivBookJob.current.scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        focusDivFunctionWindow && focusDivFunctionWindow.current && focusDivDashboard.current.scrollIntoView({ behavior: 'smooth' });
        break;
    }
  }

  const scrollToTopPage = () => {
    focusDivDashboard && focusDivDashboard.current && focusDivDashboard.current.scrollIntoView({ behavior: 'smooth' });
  }

  const requestSignPickUp = async(action, jobid) => {
    clientAction(action);
    const responseJob = await ApiClient.request(`/api/jobs/${jobid}`);
    setJobplus(responseJob);
  }

  const submitSignPickUpRequest = async (data) => {
    let register = null;
    if(user){
      register = user;
    }
    const signPickUpData = {
      ...data,
      jobid: jobplus.job.jobid,
      register: register,
    }
    const response = await ApiClient.post(`/api/signs`, signPickUpData);
    clientAction('completedJobs', 'Top Dashboard')
    if(response.success){
      setMessage('Sign Pick Up Request Successful')
    } else {
      setMessage('Sign Pick Up Request UnSuccessful')
    }
  }

  const sendReceipt = async (jobid) => {
    const result = await ApiClient.request(`/api/jobs/${jobid}/send_receipt`)
    clientAction('completedJobs', null);
    if(result){
      setMessage('Receipt Sent Successfully')
    } else {
      setMessage('Receipt Sent UnSuccessfully')
    }
    scrollToTopPage();
  }

  const sendConfirmation = async (jobid) => {
    const result = await ApiClient.request(`/api/jobs/${jobid}/send_job_confirmation`)
    clientAction('upcomingJobs', null);
    if(result){
      setMessage('Confirmation Sent Successfully')
    } else {
      setMessage('Confirmation Sent UnSuccessfully')
    }
    scrollToTopPage();
  }

  const reportDNFHelper = async(action, jobid) => {
    clientAction(action);
    const responseJob = await ApiClient.request(`/api/jobs/${jobid}`);
    setJobplus(responseJob);
    const responseDNFs = await ApiClient.request(`/api/dnfs/${jobid}/job_dnfs`);
    setDnfs(responseDNFs);
  }

  const submitDNFForm = async (data) => {
    let register = null;
    if(user){
      register = user;
    }
    const dnfData = {
      ...data,
      jobid: jobplus.job.jobid,
      register: register,
      contact: jobplus.client
    }
    const response = await ApiClient.post(`/api/dnfs`, dnfData);
    if(response.success){
      setMessage('Quality Issue Recorded Successfully')
    } else {
      setMessage('Quality Issue Recorded UnSuccessfully')
    }
    clientAction('completedJobs', 'Top Dashboard')
    scrollToTopPage();
  }

  const reportSatHelper = async(action, jobid) => {
    clientAction(action);
    const responseJob = await ApiClient.request(`/api/jobs/${jobid}`);
    setJobplus(responseJob);
  }

  const submitFeedbackForm = async (data) => {
    const errors = validateFeedbackData(data , true, getValues);
    if(Object.keys(errors).length>0){
      setErrorMsgs(errors);
      setTriedSubmit(true);
    }else{
      let caller = null;
      if(user){
        caller = user;
      }

      const satData = {
        ...data,
        jobid: jobplus.job.jobid,
        caller: caller,
        source: 'Dashboard'
      }
      const response = await ApiClient.post(`/api/sats`, satData);
      clientAction('completedJobs', 'Top Dashboard')
      if(response.success){
        setMessage('Feedback Recorded Successfully')
      } else {
        setMessage('Feedback Recorded UnSuccessfully')
      }
      scrollToTopPage();
    }
  }



  const onChangeJobSpecs = () => {
    if(triedSubmit){
      const errors = validateCreateSaleData(null , true, getValues);
      if(errors){
        setErrorMsgs(errors);
      }
    }
  }

  const changeJobType = (e) => {
    const jobType = e.target.value;
    if(jobType === 'W1(Floater)'){
        setFltrDateClass('field');
        setApptDateClass('field is-hidden');
    } else if(jobType === 'W1(Appt Reqd)' || jobType === 'W2(Appt Reqd)' || jobType === 'W3(Appt Reqd)' || jobType === 'W4(Appt Reqd)'){
        setApptDateClass('field');
        setFltrDateClass('field is-hidden');
    }
    onChangeJobSpecs();
  }

  const fetchStripeCustomer = async (cfid) => {
    let customer = await fetchCustomer(cfid);
    return customer;
  }

  // const fetchStripeSetupIntents = async (stripeCustomerId) => {
  //   return await fetchSetupIntents(stripeCustomerId);
  // }

  const createSale = async (data, jobNotes) => {
    const result = await ApiClient.post(`/api/sales`, data);
    setJobid(result.job.jobid);
    if(result.success){
      if(Object.keys(jobNotes).length>0){
        const jobNotesData = { hrid: user, jobid: result.job.jobid, jobnotes: jobNotes }
        await ApiClient.post(`/api/sales/save_job_notes`, jobNotesData);
      }
      let cardList = [];
      const stripeCustomer = await fetchStripeCustomer(client.cfid);
      //console.log('Stripe Customer', stripeCustomer);
      if(stripeCustomer && stripeCustomer.customer){
        const response = await fetchCardList(stripeCustomer.customer.id);
        cardList = response.cards.data;
      }
      if(cardList.length == 0){
        clientAction('obtainCreditCardData', 'FunctionWindow');
      }else{
        setMessage(`Job[${result.job.jobid}] created successfully and confirmation sent to your email. View details in Upcoming Jobs.`)
        clientAction('message', null);
        await markCcStatusRequired(client.cfid);
      }
    }
  }

  const submitSaleForm = (data) => {
    const errors = validateCreateSaleData(data , true, getValues);
    if(Object.keys(errors).length>0){
      setErrorMsgs(errors);
    }else{
      setAllowSubmit(false);
      const jobNotes = data.jobnotes;
      const parsedData = parseSalesData(data, user, type);
      createSale(parsedData, jobNotes);
      setAllowSubmit(true);
    }
  }

  const submitRequestForm = async (data) => {
    let recorder = null;
    if(type === 'INTERNAL_USER'){
      recorder = user.hrid;
    }else if(type === 'WEB_USER'){
      recorder = client.cfid;
    }else if(type === 'CLIENT_USER'){
      recorder = client.cfid;
    }
    data.recorder = recorder;
    const errors = validateRequestData(data , true, getValues);
    if(Object.keys(errors).length>0){
      setErrorMsgs(errors);
      setTriedSubmit(true);
    }else{
      if(showFunction === 'requestPainting'){
        data.requestMessage = 'Paint Est [' + data.requestMessage + ']'
        const response = await ApiClient.post('/api/messages', data);
        if(response.success){
          setMessage('Request for painting submitted successfully');
          clientAction('message', null);
        }
      } else if (showFunction === 'requestOddJob'){
        data.requestMessage = 'Book Odd Job [' + data.requestMessage + ']'
        const response = await ApiClient.post('/api/messages', data);
        if(response.success){
          setMessage('Request for odd job submitted successfully');
          clientAction('message', null);
        }
      } else if (showFunction === 'requestLawnCare'){
        data.requestMessage = 'Book Lawn [' + data.requestMessage + ']'
        const response = await ApiClient.post('/api/messages', data);
        if(response.success){
          setMessage('Request for lawn care submitted successfully');
          clientAction('message', null);
        }
      } else if (showFunction === 'requestPowerWashing'){
        data.requestMessage = 'Book Power Wash [' + data.requestMessage + ']'
        const response = await ApiClient.post('/api/messages', data);
        if(response.success){
          setMessage('Request for power washing submitted successfully');
          clientAction('message', null);
        }
      }
    }

  }


  const savePersonalInfo = async (data) => {
    clientAction('loaderBox', null);
    const updatedClient = await ApiClient.patch(`/api/clients/${client.cfid}`, data);
    dispatch({ type: ActionType.SetClient, payload: updatedClient});
    setMessage('Personal Info Edited Successfully');
    clientAction('message', null);
  }

  const submitEditPersonalInfoForm = (data) => {
    savePersonalInfo(data);
    getFunctionWindow();
  }

  const deleteJob = async(id) => {
    const deleteJob = await ApiClient.delete(`/api/jobs/${id}`);
    if(deleteJob.success){
      setMessage(`Job[${id}] Deleted Successfully`);
    } else {
      setMessage(`Job[${id}] Deleted UnSuccessfully`);
    }
    clientAction('message', null);

  }

  const getFunctionWindow = () => {
    if(showFunction === 'bookWindowsSummer'){
      if(saleok){
        return <BookWindowsSummerForm
          register={register}
          handleSubmit={handleSubmit}
          submitForm={submitSaleForm}
          changeJobType={changeJobType}
          onChangeJobSpecs={onChangeJobSpecs}
          fltrDateClass={fltrDateClass}
          apptDateClass={apptDateClass}
          getValues={getValues}
          errorMsgs={errorMsgs}
          setErrorMsgs={setErrorMsgs}
          setDashboardMessage={setMessage}
          setDashboardShowFunction={setShowFunction}
          allowSubmit={allowSubmit}
         />;
      } else {
        return <SaleBlocked
                 boxStyle={boxStyle}
               />;
      }
    } else if(showFunction === 'bookWindowsFall'){
        if(saleok){
          return <BookWindowsFallForm
          register={register}
          handleSubmit={handleSubmit}
          submitForm={submitSaleForm}
          changeJobType={changeJobType}
          onChangeJobSpecs={onChangeJobSpecs}
          fltrDateClass={fltrDateClass}
          apptDateClass={apptDateClass}
          getValues={getValues}
          errorMsgs={errorMsgs}
          setErrorMsgs={setErrorMsgs}
          setDashboardMessage={setMessage}
          setDashboardShowFunction={setShowFunction}
          allowSubmit={allowSubmit}
        />
        } else {
          return <SaleBlocked
                   boxStyle={boxStyle}
                 />;
        }
      } else if(showFunction === 'bookEavesSummer'){
          if(saleok){
            return <BookEavesSummerForm
              register={register}
              handleSubmit={handleSubmit}
              submitForm={submitSaleForm}
              changeJobType={changeJobType}
              onChangeJobSpecs={onChangeJobSpecs}
              fltrDateClass={fltrDateClass}
              apptDateClass={apptDateClass}
              eavesFltrCheckedClass={eavesFltrCheckedClass}
              setEavesFltrCheckedClass={setEavesFltrCheckedClass}
              eavesApptCheckedClass={eavesApptCheckedClass}
              setEavesApptCheckedClass={setEavesApptCheckedClass}
              eavesFltrClass={eavesFltrClass}
              setEavesFltrClass={setEavesFltrClass}
              eavesApptClass={eavesApptClass}
              setEavesApptClass={setEavesApptClass}
              getValues={getValues}
              setValue={setValue}
              errorMsgs={errorMsgs}
              setErrorMsgs={setErrorMsgs}
              setDashboardMessage={setMessage}
              setDashboardShowFunction={setShowFunction}
              allowSubmit={allowSubmit}
           />
         } else {
           return <SaleBlocked
                    boxStyle={boxStyle}
                  />;
         }

     } else if(showFunction === 'bookEavesFall'){
        if(saleok){
          return <BookEavesFallForm
            register={register}
            handleSubmit={handleSubmit}
            submitForm={submitSaleForm}
            changeJobType={changeJobType}
            onChangeJobSpecs={onChangeJobSpecs}
            fltrDateClass={fltrDateClass}
            apptDateClass={apptDateClass}
            eavesFltrCheckedClass={eavesFltrCheckedClass}
            setEavesFltrCheckedClass={setEavesFltrCheckedClass}
            eavesApptCheckedClass={eavesApptCheckedClass}
            setEavesApptCheckedClass={setEavesApptCheckedClass}
            eavesFltrClass={eavesFltrClass}
            setEavesFltrClass={setEavesFltrClass}
            eavesApptClass={eavesApptClass}
            setEavesApptClass={setEavesApptClass}
            getValues={getValues}
            setValue={setValue}
            errorMsgs={errorMsgs}
            setErrorMsgs={setErrorMsgs}
            setDashboardMessage={setMessage}
            setDashboardShowFunction={setShowFunction}
            allowSubmit={allowSubmit}
          />
        } else {
          return <SaleBlocked
                   boxStyle={boxStyle}
                 />;
        }
     } else if(showFunction === 'requestPainting'){
       return <RequestMiscJobForm
         register={register}
         handleSubmit={handleSubmit}
         submitForm={submitRequestForm}
         title='Request Painting Estimate'
         details='Paint Estimate Details'
         props={props}
         errorMsgs={errorMsgs}
       />
    } else if(showFunction === 'requestOddJob'){
       return <RequestMiscJobForm
         register={register}
         handleSubmit={handleSubmit}
         submitForm={submitRequestForm}
         title='Request Odd Job'
         details='Odd Job Details'
         props={props}
         errorMsgs={errorMsgs}
       />
    } else if(showFunction === 'requestLawnCare'){
      return <RequestMiscJobForm
        register={register}
        handleSubmit={handleSubmit}
        submitForm={submitRequestForm}
        title='Request Lawn Care'
        details='Lawn Care Details'
        props={props}
        errorMsgs={errorMsgs}
     />
    } else if(showFunction === 'requestPowerWashing'){
      return <RequestMiscJobForm
        register={register}
        handleSubmit={handleSubmit}
        submitForm={submitRequestForm}
        title='Request Power Washing'
        details='Power Washing Details'
        props={props}
        errorMsgs={errorMsgs}
       />
    } else if(showFunction === 'upcomingJobs'){
        return <UpcomingJobs
                 client={client}
                 deleteJob={deleteJob}
                 clientAction={clientAction}
                 sendConfirmation={sendConfirmation}
               />
    } else if(showFunction === 'currentJobs'){
        return <CurrentJobs />
    } else if(showFunction === 'completedJobs'){
        return <CompletedJobs
                 client={client}
                 clientAction={clientAction}
                 reportDNFHelper={reportDNFHelper}
                 reportSatHelper={reportSatHelper}
                 requestSignPickUp={requestSignPickUp}
                 sendReceipt={sendReceipt}
               />

    } else if(showFunction === 'reportDNF'){
        return <DNFForm
             client={client}
             clientAction={clientAction}
             submitForm={submitDNFForm}
             handleSubmit={handleSubmit}
             register={register}
             jobplus={jobplus? jobplus : null}
             dnfs={dnfs}
             getValues={getValues}
             setValue={setValue}
           />

       } else if(showFunction === 'requestSignPickUp'){
         return <SignPickUpForm
              client={client}
              clientAction={clientAction}
              submitForm={submitSignPickUpRequest}
              handleSubmit={handleSubmit}
              register={register}
              jobplus={jobplus? jobplus : null}
              getValues={getValues}
              setValue={setValue}
            />

    } else if(showFunction === 'reportFeedback'){
        return <FeedbackForm
            client={client}
            clientAction={clientAction}
            submitForm={submitFeedbackForm}
            handleSubmit={handleSubmit}
            jobplus={jobplus? jobplus : null}
            register={register}
            getValues={getValues}
            setValue={setValue}
            errorMsgs={errorMsgs}

           />

    } else if(showFunction === 'editPersonalInfo'){
        return <EditPersonalInfo
          submitForm={submitEditPersonalInfoForm}
          handleSubmit={handleSubmit}
          register={register}
          getValues={getValues}
          setValue={setValue}
          message={message}
      />
    } else if(showFunction === 'message'){
      return <MessageInfo
          message={message}
        />
    } else if(showFunction === 'loaderBox'){
      return <LoaderBox
          boxStyle={boxStyle}
        />
    } else if(showFunction === 'jobDetails'){
      return <JobDetails boxStyle={boxStyle} jobid={jobid} setShowFunction={setShowFunction}/>

    } else if(showFunction === 'stripeJobPay'){
      return <StripeJobPaySecure
          boxStyle={boxStyle}
          setMessage={setMessage}
          clientAction={clientAction}
        />
    } else if(showFunction === 'obtainCreditCardData'){
      return <StripeCardSetup
                boxStyle={boxStyle}
                propTitle={`Enter card info to complete your order [${jobid}]`}
                jobid={jobid}
                clientAction={clientAction}
             />
    } else if(showFunction === 'manageCreditCards'){
      return <StripeCardSetup
                boxStyle={boxStyle}
                propTitle="Manage(add and delete) credit cards"
                clientAction={clientAction}
             />
    } else {
      return <div style={boxStyle} className='box'>
        <h2 className='is-hidden-tablet title is-4 has-text-centered has-text-link'>Select an option from above menus</h2>
        <h2 className='is-hidden-mobile title is-4 has-text-centered has-text-link'>Select an option from left menus</h2>
        <section className='hero is-bold is-fullheight'>
          <div className='hero-body'>
            <div className='container'>
              <div className='columns is-centered'>
                <div className='column is-half'>
                  <figure className='image is-32by32 mr-4'>
                    <img src={WSLOGO} />
                  </figure>
                </div>
              </div><br/><br/>

              <div className='columns is-centered'>
                <div className='column is-half'>
                  <figure className='image is-96by96 ml-7'>
                    <img src={EMLOGO} />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    }
  }

   if (!true) {
     return <Loader />;
   }

   return (<Layout>
      <section className='section'>
        <div className='container'>
          <div className='columns'>
            <div className='column'>
              <div className='tile'>
                <div className='tile is-child notification is-primary-light has-background-info' ref={focusDivDashboard}>
                  { message ? <h2 className='title is-success has-text-centered'>{message}</h2> : null }
                  <h1 className='title is-2 has-text-centered mb-3 has-text-link'>Personal Dashboard</h1><br/>
                  <div className='columns'>
                    <div className='column is-2 is-hidden-mobile'>
                      <h2 className='is-size-4 has-text-weight-bold'>Name: </h2>
                      <h2 className='is-size-4 has-text-weight-bold'>Address: </h2>
                      <h2 className='is-size-4 has-text-weight-bold'>Phone 1: </h2>
                      <h2 className='is-size-4 has-text-weight-bold'>Phone 2: </h2>
                      <h2 className='is-size-4 has-text-weight-bold'>Email: </h2>
                    </div>

                    <div className='column is-two-thirds'>
                      <h2 className="has-text-weight-bold is-size-4">{ client && fullname(client) ? fullname(client) : "no name on file" }</h2>
                      <h2 className="has-text-weight-bold is-size-4">
                        { client && client.address ? client.address : "no address on file"}
                        [{client && client.postcode ? client.postcode : "no postcode on file"}]
                      </h2>
                      <h2 className="has-text-weight-bold is-size-4">{ client && client.phone ? client.phone : "no phone on file"}</h2>
                      <h2 className="has-text-weight-bold is-size-4">{ client && client.cellphone ? client.cellphone : "no cell on file" }</h2>
                      <h2 className="has-text-weight-bold is-size-4">{ client && client.email ? client.email : "no email on file"}</h2>
                    </div>
                    
                    <div className='column is-one-third'>
                      <div className='panel'>
                        <a className="panel-block is-active has-text-text has-text-weight-bold" onClick={() => clientAction('stripeJobPay')}>
                          Apply Payment
                        </a>
                        <a className="panel-block is-active has-text-text has-text-weight-bold" onClick={() => clientAction(null, 'Book Job')}>
                          Book Job
                        </a>
                        <a className="panel-block is-active has-text-text has-text-weight-bold" onClick={() => clientAction('editPersonalInfo', null)}>
                          Edit Personal Info
                        </a>
                        <a className="panel-block is-active has-text-text has-text-weight-bold" onClick={() => navigate('/reset_password')}>
                          Reset Password
                        </a>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>


          </div><br/>

          <div className='columns'>
            <div className='column is-one-quarter'>
              <nav className="panel">
                <p className="panel-heading is-size-4 has-text-centered has-text-info has-background-link">
                  Book Jobs
                </p>
                <a className="panel-block is-active has-text-weight-bold" onClick={() => clientAction('bookWindowsSummer', null)}>
                  Book Windows Spring/Summer 2025
                  { showFunction && showFunction === 'bookWindowsSummer' ? <span className='ml-3'>{CHECK}</span> : null }
                </a>
                <a className="panel-block is-active has-text-weight-bold" onClick={() => clientAction('bookEavesSummer', null)}>
                  Book Eaves Spring/Summer 2025
                  { showFunction && showFunction === 'bookEavesSummer' ? <span className='ml-3'>{CHECK}</span> : null }
                </a>
                <a className="panel-block is-active has-text-weight-bold" onClick={() => clientAction('bookWindowsFall', null)}>
                    Book Windows Fall 2025
                    { showFunction && showFunction === 'bookWindowsFall' ? <span className='ml-3'>{CHECK}</span> : null }
                </a>
                <a className="panel-block is-active has-text-weight-bold" onClick={() => clientAction('bookEavesFall', null)}>
                    Book Eaves Fall 2025
                    { showFunction && showFunction === 'bookEavesFall' ? <span className='ml-3'>{CHECK}</span> : null }
                </a>
                <a className="panel-block is-active has-text-weight-bold" onClick={() => clientAction('editPersonalInfo', null)}>
                  Edit Personal Info
                  { showFunction && showFunction === 'editPersonalInfo' ? <span className='ml-3'>{CHECK}</span> : null }
                </a>
              </nav>
              <nav className="panel">
                <p className="panel-heading is-size-4 has-text-centered has-text-info has-background-link">
                  Specialty Services
                </p>
                <a className="panel-block is-active has-text-weight-bold" onClick={() => clientAction('requestPainting', null)}>
                  Request Painting
                  { showFunction && showFunction === 'requestPainting' ? <span className='ml-3'>{CHECK}</span> : null }   </a>
                <a className="panel-block is-active has-text-weight-bold" onClick={() => clientAction('requestOddJob', null)}>
                  Request Odd Jobs
                  { showFunction && showFunction === 'requestOddJob' ? <span className='ml-3'>{CHECK}</span> : null }   </a>
                <a className="panel-block is-active has-text-weight-bold" onClick={() => clientAction('requestLawnCare', null)}>
                  Request Lawn Care
                  { showFunction && showFunction === 'requestLawnCare' ? <span className='ml-3'>{CHECK}</span> : null }   </a>
                <a className="panel-block is-active has-text-weight-bold" onClick={() => clientAction('requestPowerWashing', null)}>
                  Request Power Washing
                  { showFunction && showFunction === 'requestPowerWashing' ? <span className='ml-3'>{CHECK}</span> : null }   </a>
              </nav>
              <nav className="panel">
                <p className="panel-heading is-size-4 has-text-centered  has-text-info has-background-link" ref={focusDivBookedJobs}>
                  Booked Jobs
                </p>
                <a className="panel-block is-active has-text-weight-bold" onClick={() => clientAction('upcomingJobs', null)}>
                  Upcoming Jobs
                  { showFunction && showFunction === 'upcomingJobs' ? <span className='ml-3'>{CHECK}</span> : null }   </a>
                <a className="panel-block is-active has-text-weight-bold" onClick={() => clientAction('completedJobs', null)}>
                  Completed Jobs
                  { showFunction && showFunction === 'completedJobs' ? <span className='ml-3'>{CHECK}</span> : null }   </a>
              </nav>

              <nav className="panel">
                <p className="panel-heading is-size-4 has-text-centered  has-text-info has-background-link" ref={focusDivBookedJobs}>
                  Apply/Manage Payments
                </p>
                <a className="panel-block is-active has-text-weight-bold" onClick={() => clientAction('stripeJobPay', null)}>
                  Apply Payment
                  { showFunction && showFunction === 'stripeJobPay' ? <span className='ml-3'>{CHECK}</span> : null }   </a>
                <a className="panel-block is-active has-text-weight-bold" onClick={() => clientAction('manageCreditCards', 'Top Dashboard')}>
                  Manage Credit Cards
                  { showFunction && showFunction === 'manageCreditCards' ? <span className='ml-3'>{CHECK}</span> : null }  </a>
              </nav>


            </div>
            <div style={ boxStyle } className='column is-three-quarters' ref={focusDivFunctionWindow}>
              { getFunctionWindow() }
            </div>

          </div>
        </div>
      </section>
    </Layout>)
}

export default Dashboard;

// <a className="panel-block is-active" onClick={() => clientAction('currentJobs', null)}>
//   Current Jobs
//   { showFunction && showFunction === 'currentJobs' ? <span className='ml-3'>{CHECK}</span> : null }   </a>



// <a className="panel-block is-active" onClick={() => clientAction('bookWindowsSummer', null)}>
//   Book Windows Spring/Summer 2024
//   { showFunction && showFunction === 'bookWindowsSummer' ? <span className='ml-3'>{CHECK}</span> : null }
// </a>
// <a className="panel-block is-active" onClick={() => clientAction('bookEavesSummer', null)}>
//   Book Eaves Spring/Summer 2024
//   { showFunction && showFunction === 'bookEavesSummer' ? <span className='ml-3'>{CHECK}</span> : null }
// </a>
