import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { formatCurrency } from '../helpers/helper'

const style = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}

const styleFooter = {
  marginTop: 'auto'
}

const JobCard = ({
  jobid,
  cfid,
  num,
  client,
  email,
  address,
  postcode,
  city,
  jobdesc,
  price,
  perly,
  sdate,
  fdate,
  stime,
  reportstime,
  reportftime,
  nextdest,
  recstatus,
  holding_cell,
  status,
  prodcomm,
  setMessage,
  ApiClient,
  state_crew,
  user
}) => {
  const [button, setButton] = useState(null);
  const [header, setHeader] = useState(null);
  const [googleSearch, setGoogleSearch] = useState(null);

  useEffect(() => {
    setGoogleSearch(`https://www.google.ca/maps/place/${address} ${postcode}`);
  }, [postcode, address])

  useEffect(() => {
    if(status){
      if(status === 'Completed'){
        setButton('button card-footer-item is-black has-text-weight-bold');
        setHeader('card-header-title is-centered has-background-black has-text-white')
      }else if(status === 'Started'){
        setButton('button card-footer-item is-danger has-text-weight-bold');
        setHeader('card-header-title is-centered has-background-danger')
      }else if(sdate!==fdate){
        setButton('button card-footer-item is-primary has-text-weight-bold');
        setHeader('card-header-title is-centered has-background-primary')
      }else if(sdate===fdate){
        setButton('button card-footer-item is-success has-text-weight-bold');
        setHeader('card-header-title is-centered has-background-success')
      }
    }
  }, [status])

  const maxSize = (jobdesc) => {
    if(jobdesc.length>20){
      jobdesc = jobdesc.substring(0,20)
    }
    return jobdesc
  }

  const jobType = () => {
    let typeDesc = 'Floater'
    if(sdate===fdate){
      typeDesc = `Appt[${stime}]`
    }
    return typeDesc
  }

  const renderActionLink = () => {
    if(status==='Started'){
      return <Link to={`/job-details/${jobid}`} className={button}>Report Job</Link>
    } else if(status==='Pending' && sdate==fdate){
      return <Link to={`/job-details/${jobid}`} className={button}>Start Job - Appointment</Link>
    } else if(status==='Pending' && sdate!==fdate){
      return <Link to={`/job-details/${jobid}`} className={button}>Start Job - Floater</Link>
    } else {
      return <Link to={`/job-details/${jobid}`} className={button}>Edit Job</Link>

    }
  }

  const sendStripeLink = () => {
    const myParams = { hrid: state_crew.crew_hrid };
    const qs = new URLSearchParams(myParams).toString();

    const result = ApiClient.request(`/api/jobs/${jobid}/send_link_to_pay?${qs}`);
    if(result){
      setMessage('Stripe Link successfully sent to ' + email);
    }else{
      setMessage('Stripe Link unsuccessful');
    }
    window.scrollTo(0, 0);

  }


  return(
    <div style={style} className='card'>
      <div className='card-header'>
        <div className={header}>
          <div className='columns'>
            <div className='column has-text-centered'>
              <p>Job Order # {num.charAt(0)==='0' ? num.substring(1) : num}</p>
              <p>
                <a href={ googleSearch } target="_blank">
                  {address} [{postcode}]
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='card-content'>
        <div className='content'>
          { prodcomm === '**Emergency**' && <React.Fragment>
            <p className='has-text-weight-bold has-text-link has-text-centered is-size-5'>{ prodcomm }</p><br/>
            </React.Fragment>
          }
          { holding_cell && <React.Fragment>
            <p className='has-text-weight-bold has-text-danger has-text-centered is-size-5'>**Holding Cell**</p><br/>
            </React.Fragment>
          }
          <p className='has-text-weight-bold'>JobID:{ jobid }</p><br/>
          <p className='has-text-weight-bold'>{ jobType() }</p>
          <p className='has-text-weight-bold'>{ jobdesc }</p>
          <p>{ formatCurrency(price) }</p>
          { status === 'Completed' ?
            (<React.Fragment>
              <br/>

              { recstatus === 'Receivable' ?
                <div>
                <p className='has-text-weight-bold'><span>Receivable</span><br/>
                    <a href={`/crews/react_client_dashboard?cfid=${cfid}`}>
                    <u>Pay By Stripe</u>
                  </a><br/>
                </p>
                <p>
                { email!=='' ?
                  <a className='has-text-link' onClick={ sendStripeLink }><u>Send Link<br/>({email})</u></a>

                  : ' / [No Email]' }
                </p>
                </div>
               : <p>{ recstatus }</p>}

              <br/>
              <p className='has-text-weight-bold'>Next:{ nextdest }</p>
              { user && user.crewMaster ? <Link to={`/partner_inspection/${jobid}`}>Inspection</Link> : null}
            </React.Fragment>)
            : null }
        </div>
      </div>
      <footer style={styleFooter} className="card-footer">
        { renderActionLink() }
      </footer>
    </div>
  )
}

export default JobCard
