import React, { useState, useEffect } from 'react';
import { Link, Redirect, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import Loader from "../../../../base/layout/Loader";
import { useStateReducer } from "../../../../base/context/authContext";
import ApiClient from "../../../../base/api/client";
import { makeNiceDate, formatCurrency } from "../../../helpers/helper";
import { hours, mins } from "../../../helpers/lists";
import { ErrorMsg } from "../../../components/ErrorMsg"



const InspectionForm = () => {
  const [jobid, setJobid] = useState(null);
  const [crew, setCrew] = useState(null);
  const [driver, setDriver] = useState(null);
  const [partner, setPartner] = useState(null);
  const [partner1, setPartner1] = useState(null);
  const [client, setClient] = useState(null);
  const [address, setAddress] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [perly, setPerly] = useState(null);
  const [job, setJob] = useState(null);
  const [jobPics, setJobPics] = useState(null);
  const [message, setMessage] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [{ mgr, user, userLoading }] = useStateReducer();
  const [errorMsgs, setErrorMsgs] = useState({});


  const form = useForm();
  const { register, handleSubmit, getValues, setValue } = form;

  const params = useParams();
  console.log('PARAMS:', params.id);



  const fetchInspection = async () => {
    setShowLoader(true);
  //  const { mess, client, property, jobs, close_properties } = await ApiClient.request(`/api/inspections/${msid}`);

    //const myParams = { list: 'live_inspection_info', jobid: params.id, hrid: mgr.hrid };
    //const qs = new URLSearchParams(myParams).toString();
    const result = await ApiClient.request(`/api/inspections/${params.id}`);

    const job_pics = await ApiClient.request(`/api/ben/${params.id}/job_images`);
    console.log('Job Pics', job_pics);
    setJobPics(job_pics.stuff);


  //  setMessage(mess);
    setDriver(result.driver);
    setPartner(result.partner);
    setPartner1(result.partner1);
    setAddress(result.address);
    setClient(result.client);
    setCrew(result.crew);
    setJob(result.job);
    setPostcode(result.postcode);
    setPerly(result.perly);
    setShowLoader(false);
  }

  useEffect(() => {
    if(user){
      fetchInspection();
    }
  }, [user])

  const showInfo = () => {
    return <div >
      <h1 className='subtitle'>{client}</h1>
      <h1 className='subtitle'>
        <a href={`https://www.google.ca/maps/place/${address} Toronto`} target="_blank">
          { address } { postcode }
        </a>
      </h1>
      <h1 className='subtitle'>Perly: {perly}</h1>
      <h1 className='subtitle has-text-link has-text-weight-bold'>Crew: {crew}</h1>
      { message ? <h1 className='subtitle has-text-link has-text-weight-bold'>Message: {message}</h1> : null }
      <hr/>
    </div>
  }



  const returnInspection = async (data) => {
    const result = await ApiClient.post(`/api/inspections`, data);
    setMessage('Inspection recorded successfully');
    window.scrollTo(0, 0);
  }

  const checkData = (e) => {
    const {name, value} = e.target
    setValue(name, value);
    const vals = getValues();
    if(vals.startHour && vals.startMinute && vals.finishHour && vals.finishMinute){
      setShowButton(true);
    }else{
      setShowButton(false);
    }
  }

  const showJobPics = () => {
    console.log("In Job Pics");
    const job_pic_images = jobPics.map(pic => (
      <div className='column is-one-third'>
        <div className="card">
          <div className="card-image">
            <figure className="image is-4by3">
            <img src={pic.image_url} alt="Job Image" height='500' width="300"/>
            </figure>
          </div>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-4">{ pic.kind }</p>
                <p className="title is-4">{ pic.title }</p>
                <hr/>
                <time datetime="2016-1-1">{ pic.created_at }</time>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))

    return(
      <div className='columns is-multiline'>
        { job_pic_images }
      </div>
    )
  }



  return(<div>
      { showLoader ? <Loader /> :
      <div className='container box has-background-info-light'>
        <div className='columns multiline'>
          <div className='column'>
            <Link to="/stats" className='subtitle has-text-link'><u>Back To Stats</u></Link>
          </div>
          <div className='column'>
            <Link to="/crew-estimates-signs" className='subtitle has-text-link'><u>Back To Estimates/Signs</u></Link><br/><br/>
            {message ? <h3 className='title is-4 has-text-success has-text-centered'>{message}<br/><br/></h3> : null}
          </div>
          <div className='column'>
          </div>
          <div className='column'>
          </div>
        </div>
        <h1 className='title has-text-centered'>Inspection Details</h1>

         { showInfo() }

        <form className='form' onSubmit={handleSubmit(returnInspection)}>
        { client &&
        <div>
          <input
            type='hidden'
            {...register("inspector")}
            value={ user.hrid }
          />
          <input
            type='hidden'
            {...register("location")}
            value={ params.id }
          />

        <div className='columns multiline'>


          <div className='column'>
            <div className='field'>
              <div className='control'>
                <label className='label' htmlFor='start_label'>Start Time:</label>
                <div className='select' id='start_label'>
                  <select 
                    {...register("startHour")} 
                    onChange={checkData}
                  >
                    { hours.map((hour)=>(<option key={hour} value={hour}>{hour}</option>)) }
                  </select>
                </div> :
                <div className='select' >
                  <select 
                    {...register("startMinute")} 
                    onChange={checkData}
                  >
                    { mins.map((min)=>(<option key={min} value={min}>{min}</option>)) }
                  </select>
                </div>
              </div>
              <ErrorMsg msg={errorMsgs.startHourCannotBeBlank} num={ Object.keys(errorMsgs).length }/>
              <ErrorMsg msg={errorMsgs.startMinuteCannotBeBlank} num={ Object.keys(errorMsgs).length }/>
            </div>
          </div>

          <div className='column'>
            <div className='field'>
              <div className='control'>
                <label className='label' htmlFor='finish_label'>Finish Time:</label>
                <div className='select' id='finish_label'>
                  <select 
                    {...register("finishHour")} 
                    onChange={checkData}
                  >
                    { hours.map((hour)=>(<option key={hour} value={hour}>{hour}</option>)) }
                  </select>
                </div> :
                <div className='select' >
                  <select 
                    {...register("finishMinute")} 
                    onChange={checkData}
                  >
                    { mins.map((min)=>(<option key={min} value={min}>{min}</option>)) }
                  </select>
                </div>
              </div>
              <ErrorMsg msg={errorMsgs.finishHourCannotBeBlank} num={ Object.keys(errorMsgs).length }/>
              <ErrorMsg msg={errorMsgs.finishMinuteCannotBeBlank} num={ Object.keys(errorMsgs).length }/>
            </div>
          </div>

          <div className='column'>
            <div className='field'>
              <div className='control'>
                <label className='label' htmlFor='type_label'>Type:</label>
                <div className='select' id='type_label'>
                  <select {...register("itype")} >
                    <option key='live' value='Live Inspection'>Live Job Inspection</option>
                    <option key='post' value='Post Inspection'>Post Job Inspection</option>
                  </select>
                </div>
              </div>
              <ErrorMsg msg={errorMsgs.typeCannotBeBlank} num={ Object.keys(errorMsgs).length }/>
            </div>
          </div>

          <div className='column'>
            <div className='field'>
              <div className='control'>
                <label className='label' htmlFor='home_label'>Client Home:</label>
                <div className='select' id='home_label'>
                  <select {...register("clienthm")} >
                    <option key='yeshome' value='Yes'>Client Home</option>
                    <option key='nohome' value='No'>Client Not Home</option>
                  </select>
                </div>
              </div>
              <ErrorMsg msg={errorMsgs.clienthmCannotBeBlank} num={ Object.keys(errorMsgs).length }/>
            </div>
          </div>
        </div>


        <div className='columns'>

          <div className='column'>
            <div className='field'>
              <div className='control'>
                <label className="label" forhtml='send_email'>
                  <input
                    type='checkbox'
                    id='send_email'
                    {...register("send_email")}
                    defaultChecked='checked'
                  />
                <span className='pl-3'>Send Email</span>
                <span className='pl-1'>({ client.email })</span>
                  </label>
              </div>
            </div>
          </div>

          <div className='column'>
            <div className='field'>
              <div className='control'>
                <label className="label" forhtml='crewsite'>
                  <input
                    type='checkbox'
                    id='crewsite'
                    {...register("crewsite")}
                    defaultChecked='checked'
                  />
                  <span className='pl-3'>Site Organized</span>
                </label>
              </div>
            </div>
          </div>

          <div className='column'>
            <div className='field'>
              <div className='control'>
                <label className="label" forhtml='crewuni'>
                  <input
                    type='checkbox'
                    id='crewuni'
                    {...register("crewuni")}
                    defaultChecked='checked'
                  />
                  <span className='pl-3'>Crew Uniform</span>
                </label>
              </div>
            </div>
          </div>

      </div>


      <div className='columns'>

        <div className='column'>
          <div className='field'>
            <div className='control'>
              <label className="label" forhtml='smsign'>
                <input
                  type='checkbox'
                  id='smsign'
                  {...register("smsign")}
                  defaultChecked='checked'
                />
                <span className='pl-3'>WS Sign</span>
              </label>
            </div>
          </div>
        </div>

        <div className='column'>
          <div className='field'>
            <div className='control'>
              <label className="label" forhtml='safesign'>
                <input
                  type='checkbox'
                  id='safesign'
                  {...register("safesign")}
                  defaultChecked='checked'
                />
                <span className='pl-3'>Safety Sign</span>
              </label>
            </div>
          </div>
        </div>

        <div className='column'>
          <div className='field'>
            <div className='control'>
              <label className="label" forhtml='safeladdp'>
                <input
                  type='checkbox'
                  id='safeladdp'
                  {...register("safeladdp")}
                  defaultChecked='checked'
                />
                <span className='pl-3'>Crew Working Safely</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='columns'>
        <div className='column is-full'>
          <div className='field'>
            <div className='control'>
              <label className="label" forhtml='comments'>
                Inspection Notes
                </label>
                <textarea
                  className='textarea'
                  rows='4'
                  id='comments'
                  placeholder='Inspection Notes...'
                  {...register("comments")}
                />
            </div>
          </div>
        </div>
      </div>


        <div className='field'>
          <div className='control'>
            <label className='label' htmlFor='grade_label'>{driver} Grade:</label>
            <div className='select' id='grade_label'>
                <select {...register("driver_grade")} >
                <option key='Pass' value='Pass'>Pass</option>
                <option key='Fail' value='Fail'>Fail</option>
              </select>
            </div>
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <label className='label' htmlFor='grade_label'>{partner} Grade:</label>
            <div className='select' id='grade_label'>
                <select {...register("partner_grade")} >
                <option key='Pass' value='Pass'>Pass</option>
                <option key='Fail' value='Fail'>Fail</option>
              </select>
            </div>
          </div>
        </div><br/>

      { partner1 ? <React.Fragment><div className='field'>
          <div className='control'>
            <label className='label' htmlFor='grade_label'>{partner1} Grade:</label>
            <div className='select' id='grade_label'>
                <select {...register("partner1_grade")} >
                <option key='Pass' value='Pass'>Pass</option>
                <option key='Fail' value='Fail'>Fail</option>
              </select>
            </div>
          </div>
        </div><br/></React.Fragment> : null }


        <div className='has-text-centered'>
          { showButton ?
          <button name='return_inspection' type='submit' className='button is-link is-rounded has-text-weight-bold'>Return Inspection</button> :
          <button disabled name='return_inspection' type='submit' className='button is-link is-rounded has-text-weight-bold'>Return Inspection</button> }
        </div>
      </div>
      }
      </form>

      <hr/>
        {job &&
        <u>
          <a className="navbar-item is-fullwidth is-size-5 has-text-weight-bold" href={`/job_images?job_id=${job.jobid}&source=Inspection`}>
            Add Inspection Image
          </a><br/><br/>
        </u>}

        { job && jobPics && showJobPics() }



    </div>}
    </div>
  )

}

export default InspectionForm;


