import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";

import Login from "../views/Login";
import StuffToDo from "../views/StuffToDo";
import MakePackages from "../views/MakePackages";
import JobDetails from "../views/JobDetails";
import Home from "../layout/Home";
import CrewSetUp from "../views/CrewSetUp";
import CrewPay from "../views/CrewPay";
import CrewProfile from "../views/CrewProfile";
import EmployeeDetails from "../views/EmployeeDetails";
import PropertyManagement from "../views/property-management/PropertyManagement";
import NewPropertyTransaction from "../views/property-management/NewPropertyTransaction";
import EditPropertyTransaction from "../views/property-management/EditPropertyTransaction";
import NewDescription from "../views/property-management/NewDescription";

// export const history = createBrowserHistory({ basename: "/crews/react" });

const AppRouter = () => (
  <Router  basename = "/crews/react">
    <Routes>
      <Route path="/login" exact element={ <Login/> } />
      <Route path="/jobs/:id" exact element={ <JobDetails/> } />
      <Route path="/makepackages" exact element={ <MakePackages/> } />
      <Route path="/stufftodo" exact element={ <StuffToDo/> } />
      <Route path="/crewsetup" exact element={ <CrewSetUp/> } />
      <Route path="/crewsetup/:ocid/edit" exact element={ <CrewSetUp/> } />
      <Route path="/crewpay" exact element={ <CrewPay/> } />
      <Route path="/crewprofile" exact element={ <CrewProfile/> } />
      <Route path="/employee_details" exact element={ <EmployeeDetails/> } />
      <Route path="/transactions" exact element={ <PropertyManagement/> } />
      <Route path="/transactions/new" exact element={ <NewPropertyTransaction/> }/>
      <Route
        path="/transactions/:id/edit"
        exact element={ <EditPropertyTransaction/> }
      />
      <Route path="/descriptions/new" exact element={ <NewDescription/> } />
      <Route path="/" exact element={ <Home/> } />
    </Routes>
  </Router>
);

export default AppRouter;
