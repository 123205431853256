import React from "react";

const TypeRangeOptions = ({ onTypeChange, value }) => {
  return (
    <select
      className="is-size-4"
      onChange={onTypeChange}
      name="type"
      value={!value ? "" : value}
    >
      <option value="All">All</option>
      <option value="Revenue">Revenue</option>
      <option value="Expense">Expense</option>
    </select>
  );
};

export default TypeRangeOptions;
