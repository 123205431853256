import React, { useState, useEffect } from 'react';
import { Link, Redirect, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import Loader from "../../../../base/layout/Loader";
import { useStateReducer } from "../../../../base/context/authContext";
import ApiClient from "../../../../base/api/client";
import { makeNiceDate, formatCurrency } from "../../../helpers/helper";
import { hours, mins } from "../../../helpers/lists";
import { ErrorMsg } from "../../../components/ErrorMsg"



const PickUpForm = ({ register, handleSubmit, returnPickUp, msid }) => {
  const [client, setClient] = useState(null);
  const [email, setEmail] = useState(null);
  const [type, setType] = useState(null);
  const [property, setProperty] = useState(null);
  const [message, setMessage] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [{ user, mgr, userLoading }] = useStateReducer();
  const [errorMsgs, setErrorMsgs] = useState({});

  console.log('MSID:', msid);

  const fetchPickUp = async () => {
    setShowLoader(true);
  //  const { mess, client, property, jobs, close_properties } = await ApiClient.request(`/api/inspections/${msid}`);

  //  const myParams = { list: 'live_inspection_info', jobid: jobid, hrid: mgr.hrid };
    //const qs = new URLSearchParams(myParams).toString();
    const result = await ApiClient.request(`/api/pick_ups/${msid}`);



    console.log('Resultsis:', result);
  //  setMessage(mess);
    setClient(result.client);
    setEmail(result.email);
    setProperty(result.property);
    setMessage(result.message);
    setType(result.type);
    setShowLoader(false);
  }

  useEffect(() => {
    if(user){
      fetchPickUp();
    }
  }, [user])


  const showInfo = () => {
    return <div >
      <h1 className='subtitle'>{client}</h1><br/>
      <h1 className='subtitle'>
        <a href={`https://www.google.ca/maps/place/${property.address} Toronto`} target="_blank">
          { property.address } { property.postcode }<br/>
        </a>[{ property.perly }]
      </h1><br/>
      { email ? <span><h1 className='subtitle has-text-link has-text-weight-bold'>Email: {email}</h1><br/></span> : null }
      { message ? <span><h1 className='subtitle has-text-link has-text-weight-bold'>Message: {message}</h1><br/></span> : null }
      <hr />
    </div>
  }




  return(<div>
      { showLoader ? <Loader /> :
      <div className='container box has-background-info'>
        <h1 className='title has-text-centered'>Pick Up Details</h1>

         { property && showInfo() }

        <form className='form' onSubmit={handleSubmit(returnPickUp)}>
        { client &&
        <div>
        <div className='columns multiline'>
          <input
            type='hidden'
            value={client}
            {...register("client")}
          />

          <input
            type='hidden'
            name='address'
            value={property.address}
            {...register("address")}
          />

          <input
            type='hidden'
            value={type}
            {...register("pick_up_type")}
          />

          <div className='column'>
            <div className='field'>
              <div className='control'>
                <label className="label" forhtml='pick_up'>
                  <input
                    type='radio'
                    id='pick_up'
                    value='Pick Up Successful and Send Email'
                    {...register("pick_up")}
                    defaultChecked={ email ? 'checked' : null }
                    disabled={ email ? false : true }
                  />
                  <span className='pl-3'>Pick Up Successful and Send Email</span>
                </label>
              </div>
            </div>
          </div>

          <div className='column'>
            <div className='field'>
              <div className='control'>
                <label className="label" forhtml='pick_up'>
                  <input
                    type='radio'
                    id='pick_up'
                    value='Pick Up Successful'
                    {...register("pick_up")}
                    defaultChecked={ !email ? 'checked' : null }
                  />
                  <span className='pl-3'>Pick Up Successful</span>
                </label>
              </div>
            </div>
          </div>

          <div className='column'>
            <div className='field'>
              <div className='control'>
                <label className="label" forhtml='pick_up'>
                  <input
                    type='radio'
                    id='pick_up'
                    value='Pick Up UnSuccessful and Send Email'
                    {...register("pick_up")}
                    disabled={ email ? false : true }
                  />
                  <span className='pl-3'>Pick Up UnSuccessful and Send Email</span>
                </label>
              </div>
            </div>
          </div>

          <div className='column'>
            <div className='field'>
              <div className='control'>
                <label className="label" forhtml='pick_up'>
                  <input
                    type='radio'
                    id='pick_up'
                    value='Pick Up UnSuccessful'
                    {...register("pick_up")}
                  />
                  <span className='pl-3'>Pick Up UnSuccessful</span>
                </label>
              </div>
            </div>
          </div>
        </div>


          <div className='has-text-centered'>
            <button type='submit' className='button is-link is-rounded has-text-weight-bold'>Record Pick Up</button>
          </div>
        </div>
        }
        </form>
      </div>}
    </div>
  )

}

export default PickUpForm;



