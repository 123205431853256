import React from "react";
import { formatCurrency } from "../helpers/helper";
import dayjs from "dayjs";

const EmpNotes = ({ empNotes }) => {
  return (
    <div class="box mb-4">
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Emp Notes</th>
          </tr>
        </thead>
        <tbody>
          {empNotes.map((note) => {
            return (
              <tr key={note.id + note.hrid}>
                <td>{ note.employee }</td>
                <td>{ note.notes }</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EmpNotes;
