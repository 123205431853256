import React from 'react';

import { ErrorMsg } from "../components/ErrorMsg";

const RequestMiscJobForm = ({
  register,
  handleSubmit,
  submitForm,
  title,
  details,
  props,
  errorMsgs
}) => {

  const addressOptions = (search) => {
    return props.filter(prop => prop.address.toLowerCase().includes(search.toLowerCase())).map((prop, index) => <option key={index} value={prop.jobinfoid}>{ prop.address }</option>)
  }

  return (<div className='box'>
    <h2 className='title is-4 has-text-centered'>{ title }</h2>
    <form onSubmit={handleSubmit(submitForm)}>

      <div className='field'>
        <div className="control">
          <label className='label' htmlFor='address'>Address</label>
          <div className='select'>
            <select className='select'id='address' {...register('jobinfoid')} >
              <option value="None Selected">None Selected</option>
              { props && addressOptions('')};
            </select>
          </div>
          <ErrorMsg msg={errorMsgs.addressMustBeFilled} num={ Object.keys(errorMsgs).length-1 }/>
        </div>
      </div>

      <div className='field'>
        <div className='control'>
          <label className='label' htmlFor='miscNotes'>{ details }</label>
          <textarea className='textarea' id='miscNotes' {...register('requestMessage')} placeholder='Enter details here...'/>
        </div>
        <ErrorMsg msg={errorMsgs.miscNotesMustBeFilled} num={ Object.keys(errorMsgs).length-1 }/>
      </div>
      <button className='button is-primary' type='submit'>Submit Request</button>

    </form>
  </div>)
}

export default RequestMiscJobForm;
