import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import AUDIT from "../assets/audit.png";
import TRASH from "../assets/trashcan.jpg";
import { formatCurrency, formatCurrencyZero, calcPercentage } from "../helpers/helper";

const DailyPay = ({ payData, auditPay, deletePay, crewProfileParams, crewActivity }) => {
  console.log("Pay Data", payData);
  console.log("Crew Activity", crewActivity);

  const [revenue, setRevenue] = useState(null);
  const [payout, setPayout] = useState(null);

  const calcRevenue = () => {
    let total = 0
    crewActivity.forEach((job) => {
      total += parseFloat(job.price);
    })
    setRevenue(total);
  }

  const calcPay = () => {
    let total = 0
    payData.forEach((pay) => {
      total += parseFloat(pay.pay);
    })
    setPayout(total);
  }

  useEffect(() =>{
    calcRevenue();
    calcPay();
  }, [payData])

  return (
      <div class="box">
        <table className="table has-background-primary">
          <thead>
            <tr>
              <th>Prod</th>
              <th>Labour</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody>
            <tr key="1">
              <td>{ formatCurrency(revenue) }</td>
              <td>{ formatCurrency(payout) }</td>
              <td>{ calcPercentage(payout, revenue)}</td>
            </tr>
          </tbody>
        </table><br/><hr/>

        <table className="table">
          <thead>
            <tr>
              <th>Delete</th>
              <th>Date</th>
              <th>Mgr</th>
              <th>Name</th>
              <th>Start</th>
              <th>Finish</th>
              <th>Hours</th>
              <th>Rate</th>
              <th>Pay</th>
              <th>Audit</th>
              <th>Emp Details</th>
            </tr>
          </thead>
          <tbody>
            {payData.map((pay) => (
              <tr key={pay.id}>
                <td className="crewpay-table__body crewpay-table__body--pad">
                  <img
                    className="image is-32x32"
                    src={TRASH}
                    alt="delete"
                    onClick={() => deletePay(pay.id)}
                  />
                </td>
                <td className="crewpay-table__body crewpay-table__body--pad">
                  {pay.date}
                </td>
                <td className="crewpay-table__body crewpay-table__body--pad">
                  {pay.mgr}
                </td>
                <td className="crewpay-table__body crewpay-table__body--pad">
                  {pay.name}
                </td>
                <td className="crewpay-table__body crewpay-table__body--pad">
                  { pay.stime ? pay.stime.substring(11, 16) : null }
                </td>
                <td className="crewpay-table__body crewpay-table__body--pad">
                  { pay.ftime ? pay.ftime.substring(11, 16) : null }
                </td>
                <td className="crewpay-table__body crewpay-table__body--pad">
                  { pay.hours ? pay.hours : null }
                </td>
                <td className="crewpay-table__body crewpay-table__body--pad">
                  {formatCurrencyZero(pay.rate ? pay.rate : 0)}
                </td>
                <td className="crewpay-table__body crewpay-table__body--pad">
                  {formatCurrency(pay.pay)}
                </td>
                <td className="crewpay-table__body crewpay-table__body--pad">
                  {pay.ok ? (
                    <span
                      className="crewpay-table--pointer"
                      onClick={() => auditPay(pay.id)}
                    >
                      {pay.ok}
                    </span>
                  ) : (
                    <img
                      className="image is-64x64 has-text-centered"
                      style={{width: '10rem'}}
                      src={AUDIT}
                      alt="audit"
                      onClick={() => auditPay(pay.id)}
                      onHover = {true}
                    />
                  )}
                </td>
                <td className="crewpay-table__body crewpay-table__body--pad">
                  <Link
                    to={`/employee_details?${crewProfileParams}&empDetails=${pay.name}&empHrid=${pay.hrid}`}
                  >
                    Employee Details
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
  );
};

export default DailyPay;
