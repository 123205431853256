import React, { useState, useEffect } from "react";
// import GoogleMapReact from "google-map-react";
import { Link } from "react-router-dom";

import Layout from "../layout/Layout";

import { useStateReducer } from "../../base/context/authContext";
import ApiClient from "../../base/api/client";

<link rel="stylesheet" type="text/css" href="./style.css" />

var mapOptions = {
  center: {
    lat: 44.550100,
    lng: -79.660350
  },
  zoom: 12,
  zoomControl: true,
  mapTypeControl: true
}


const Maps = () => {
  const [setMapsLoaded] = useState(false);
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [setMarker] = useState(null);
  const [setInfoWindow] = useState(null);
  const [geoMarker, setGeoMarker] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [setCrew] = useState(null);
  const [setDate] = useState(null);
  const [markersJobs, setMarkersJobs] = useState([]);
  const [service, setService] = useState(null);
  const [directionOptions, setDirectionOptions] = useState([]);

  const [{ crew: state_crew }] = useStateReducer();

  const fetchData = async (hrid) => {
    if (hrid) {
      //    setShowLoader(true)
      let result = await ApiClient.request(`/api/crews/${hrid}/daily_jobs`);
      //    console.log('RESULT:', result);

      setJobs(result.jobs);
      setCrew(result.today_crew);
      setDate(result.date);
      //      setShowLoader(false);
    }
  }

  const createDirectionOptions = (jobs) => {
    let ans = jobs.map(job => <option key={job.jobid} value={job.address + job.postcode}>{job.address} {job.num}</option>)
    ans = ans.concat(<option key="Morse Shop" value="36 Morse Street M4M 2P6">Morse Shop</option>)
    ans = ans.concat(<option key="Osler Shop" value="88 Osler Street M6P 4A2">Osler Shop</option>)
    setDirectionOptions(ans);
  }

  useEffect(() => {
    if (jobs) {
      createDirectionOptions(jobs);
    }
  }, [jobs])



  useEffect(() => {
    if (state_crew && state_crew.crew_hrid) {
      fetchData(state_crew.crew_hrid);
    }
  }, [state_crew])


  const createContentString = (job) => ('<div>' +
    "<h1>Num: " + job.num + "</h1>" +
    "<h1>" + job.jobid + "</h1>" +
    "<h1>" + job.address + "</h1>" +
    "<h2>" + job.jobdesc + "</h2>" +
    "<h2>Price: " + job.price + "</h2>" +
    "</div>"
  )

  const createMarkerLabel = (job) => {
    let label = "";
    if (job.sdate === job.fdate) {

    } else {
      label = "F-" + job.num
    }
    return label;
  }

  const createPin = async (geocoder, address, label, contentString, icon) => {
    let localGeoMarker_lat;
    let localGeoMarker_lng;

    geocoder.geocode({ 'address': address }, async (results, status) => {
      //  if ( status == maps.GeocoderStatus.OK) {
      //    map.setCenter(results[0].geometry.location);
      //
      //  }
      localGeoMarker_lat = results[0].geometry.location.lat();
      localGeoMarker_lng = results[0].geometry.location.lng();
      console.log("Address", address);
      console.log('Lat', localGeoMarker_lat);
      console.log('Lng', localGeoMarker_lng);
      setGeoMarker(results[0].geometry.location);

      const marker = new maps.Marker({
        map,
        position: { lat: localGeoMarker_lat, lng: localGeoMarker_lng },
        size: 'mid',
        label,
        title: label,
        icon
      });
      setMarker(marker);

      const infoWindow = new maps.InfoWindow({
        content: contentString,
        ariaLabel: 'xxxxx',
      });

      marker.addListener('click', function() {
        map.setCenter(marker.getPosition());
        infoWindow.open({
          anchor: marker,
          map
        });
      });

      setInfoWindow(infoWindow);
    })

  }

  useEffect(() => {
    if (geoMarker) {
      console.log('Use Effect Lat', geoMarker.lat());
      console.log('Use Effect Lng', geoMarker.lng());
      let lat = geoMarker.lat();
      let lng = geoMarker.lng();
      setMarkersJobs(markersJobs.concat({ lat, lng }));
    }
  }, [geoMarker])



  const onMapLoaded = async (map, maps) => {
    //fitBounds(map, maps);
    setMapsLoaded(true);
    setMap(map);
    setMaps(maps);
    const directionsRenderer = new maps.DirectionsRenderer();
    const directionsService = new maps.DirectionsService();

    directionsRenderer.setMap(map);
    directionsRenderer.setPanel(document.getElementById("sidebar"));

    const { PlacesService } = await maps.importLibrary("places")
    var mapService = new PlacesService(map);

    setService(mapService);

    const control = document.getElementById("floating-panel");

    map.controls[google.maps.ControlPosition.TOP_CENTER].push(control);

    const onChangeHandler = function() {
      calculateAndDisplayRoute(directionsService, directionsRenderer);
    };

    document.getElementById("start").addEventListener("change", onChangeHandler);
    document.getElementById("end").addEventListener("change", onChangeHandler);

    function calculateAndDisplayRoute(directionsService, directionsRenderer) {
      const start = document.getElementById("start").value;
      const end = document.getElementById("end").value;

      directionsService
        .route({
          origin: start,
          destination: end,
          travelMode: google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          directionsRenderer.setDirections(response);
        })
        .catch((e) => window.alert("Directions request failed due to " + status));
    }

  }

  const runGeoCoder = (maps, jobs) => {
    let geocoder = new maps.Geocoder();
    for (var i = 0; i < jobs.length; i++) {
      createPin(geocoder, (jobs[i].address + "," + jobs[i].postcode), createMarkerLabel(jobs[i]), createContentString(jobs[i]), null)
    }
    createPin(geocoder, '88 Osler Street, Toronto M6P 4A2', 'Os', 'Osler Shop', 'http://maps.gstatic.com/mapfiles/ms2/micons/rangerstation.png')
    createPin(geocoder, '36 Morse Street, Toronto M4M 2P6', 'Mo', 'Morse Shop', 'http://maps.gstatic.com/mapfiles/ms2/micons/rangerstation.png')
  }

  const lookForGasAndFood = (lat, lng, maps, map, service) => {
    var request = {
      location: new maps.LatLng(lat, lng),
      radius: '15000',
      name: "Esso",
      types: ['gas']
    };

    service.nearbySearch(request, function(results, status) {
      if (status === maps.places.PlacesServiceStatus.OK) {
        for (var i = 0; i < results.length; i++) {
          createEssoMarker(results[i]);
        }
      }
    });

    function createEssoMarker(place) {
      if (!place.geometry || !place.geometry.location) return;

      const marker = new maps.Marker({
        map,
        position: place.geometry.location,
        icon: "http://maps.gstatic.com/mapfiles/ms2/micons/gas.png"

      });

      const infoWindow = new maps.InfoWindow({
        content: place.name || "",
        ariaLabel: 'xxx',
      });

      marker.addListener('click', function() {
        infoWindow.open({
          anchor: marker,
          map
        });
      })
    }

    request = {
      location: new maps.LatLng(lat, lng),
      radius: '15000',
      name: "Tim Hortons",
      //  types: ['gas']
    };

    service.nearbySearch(request, function(results, status) {
      if (status === maps.places.PlacesServiceStatus.OK) {
        for (var i = 0; i < results.length; i++) {
          createTimmiesMarker(results[i]);
        }
      }
    });


    function createTimmiesMarker(place) {
      if (!place.geometry || !place.geometry.location) return;
      const marker = new maps.Marker({
        map,
        position: place.geometry.location,
        icon: "http://maps.gstatic.com/mapfiles/ms2/micons/snack_bar.png"
      });

      const infoWindow = new maps.InfoWindow({
        content: place.name || "",
        ariaLabel: 'xxxxx',
      });

      marker.addListener('click', function() {
        //  map.setZoom(9);
        map.setCenter(marker.getPosition());

        infoWindow.open({
          anchor: marker,
          map
        });
      })
    }
  }

  useEffect(() => {
    if (markersJobs && service && maps && map) {
      console.log("JOB MARKERS ****** ", markersJobs);
      for (let markerJob of markersJobs) {
        lookForGasAndFood(markerJob.lat, markerJob.lng, maps, map, service);
      }
    }
  }, [service, map, maps, markersJobs])


  const fitBounds = (map, maps) => {
    var bounds = new maps.LatLngBounds()
    for (let markerJob of markersJobs) {
      bounds.extend(
        new maps.LatLng(markerJob.lat, markerJob.lng)
      )
    }
    map.setCenter(bounds.getCenter());

    map.setZoom(8);
    map.fitBounds(bounds)
  }

  useEffect(() => {
    if (markersJobs && map && maps && maps !== undefined && maps !== null) {
      console.log('markersJobs', markersJobs);
      fitBounds(map, maps);
    }
  }, [markersJobs, map, maps])


  useEffect(() => {
    if (jobs && maps && maps !== undefined && maps !== null) {
      runGeoCoder(maps, jobs);
    }
  }, [maps, jobs])

  // Old API: AIzaSyCmACJq9NUfvvFxEyIG0U_pO82nn6iscpE

  // New API: AIzaSyCmACJq9NUfvvFxEyIG0U_pO82nn6iscpE

  return (
    // Important! Always set the container height explicitly

    <Layout>
      <div className="container"><br />
        <nav className='breadcrumb'>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li>
              <a href="/menu_items/crew_menu">
                Back to Crew Menu
              </a>
            </li>
            <li><Link to="/runsheet">Runsheet</Link></li>
            <li className='is-active'><Link to="/maps">Maps</Link></li>
          </ul>
        </nav>


        <div id="floating-panel">
          <strong>Start:</strong>
          <select id="start">
            {directionOptions}
          </select>
          <br />
          <strong>End:</strong>
          <select id="end">
            {directionOptions}
          </select>
        </div>


        <div style={{ height: "700px", width: "80%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyCmACJq9NUfvvFxEyIG0U_pO82nn6iscpE" }}
            defaultCenter={mapOptions.center}
            defaultZoom={mapOptions.zoom}
            options={{ mapTypeControl: mapOptions.mapTypeControl }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => onMapLoaded(map, maps)}>
          </GoogleMapReact>
          <div id='sidebar'>
          </div>

        </div>

        <div style={{ display: 'none' }}>
          <div id="floating-panel">
            <strong>Start:</strong>
            <select id="start">
              {directionOptions}
            </select>
            <br />
            <strong>End:</strong>
            <select id="end">
              {directionOptions}
            </select>
          </div>
        </div>
      </div>
    </Layout>
  );

}

export default Maps;

// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyCmACJq9NUfvvFxEyIG0U_pO82nn6iscpE'
// })(Maps)


// {mapsLoaded ? afterMapLoadChanges() : ''}

// const essos = https://maps.googleapis.com/maps/api/place/nearbysearch/json
//   ?keyword=esso
//   &location=44.550100%2C-79.660350
//   &radius=50
//   &type=gas
//   &key=AIzaSyCmACJq9NUfvvFxEyIG0U_pO82nn6iscpE
