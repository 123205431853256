import React, { useEffect } from "react";
import useRequest from "../../base/hooks/useRequest";
import ApiClient from "../api/client";
import { formatCurrency } from "../helpers/helper";

import CrewNameOptions from "./CrewNameOptions";
import NumberButton from "./NumberButton";
import UnassignButton from "./UnassignButton";

const CrewJobs = ({
  crewJobs,
  crewName,
  schDate,
  killNumJob,
  refetchJobs,
  refetchCrewJobs,
}) => {

  const numJob = async (job) => {
    const response = await ApiClient.request(
      `/api/jobs/${job.jobid}/ass_num`,
      null
    );
    refetchJobs();
    refetchCrewJobs();
  };

  const tag = (job) => {
    console.log('PROD COMM:', job.prodcomm);
    let tag = "";
    let emgy = "";
    if (job.prodcomm === "**Emergency**") {
      emgy = "-EM";
    }

    let stime = "";
    if (job.stime !== "" && job.stime !== null) {
      stime = "-" + job.stime;
    }

    tag = "[F" + emgy + "] ";
    if (job.sdate === job.fdate) {
      tag = "[A" + emgy + stime + "] ";
    }
    tag = tag.replace("Critical", "Cr");
    tag = tag.replace("Anytime", "Any");
    tag = tag.replace("8:00", "8");
    tag = tag.replace("10:00", "10");
    //console.log(job.jobdesc + "  " + jobdesc);
    return tag + '[' + job.datesold + ']';
  };

  return (
    <div>
      <div className="box table-container">
        <table class="table is-size-5 has-background-light is-fullwidth">
          <thead>
            <tr>
              <th colSpan={3}>
                {crewName} {schDate}
              </th>
            </tr>
          </thead>
          <tbody>
            {crewJobs
              ? crewJobs.map((job) => (
                  <tr
                    key={job.jobid}
                    className={job.prodcomm && job.prodcomm.includes("Emerg") ? "display-panel-container__margin-b--red" : "display-panel-container__margin-b"}
                  >
                    <td>{job.num}</td>
                    <td>{tag(job)}</td>
                    <td>{job.jobdesc}</td>
                    <td>{job.address}</td>
                    <td>
                      {job.perly}[{job.postcode.substring(0, 3)}]
                    </td>
                    <td>{job.jobdesc}</td>
                    <td>{formatCurrency(job.price)}</td>
                    {job.num ? (
                      <td>
                        <NumberButton
                          jobAction={() => killNumJob(job.jobid)}
                          label="UnNumber"
                          className="button has-background-primary"
                        />
                      </td>
                    ) : (
                      <td>
                        <NumberButton 
                          jobAction={() => numJob(job)} 
                          label="Number"
                          className="button has-background-link has-text-white"
                        />
                      </td>
                    )}
                  </tr>
                ))
              : null}
          </tbody>
        </table><hr/>
      </div>
      <br/>          
    </div>
  );
};

export default CrewJobs;
