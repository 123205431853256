import React, { useState } from 'react';

import { ErrorMsg } from "../../components/ErrorMsg";
import JobDefinitions from "./JobDefinitions";

const underlineLink = {
  textDecoration: 'underline'
}

const JobType = ({ register, errorMsgs, changeJobType, type }) => {
  const [showDefinitions, setShowDefinitions] = useState(false);
  const options = (type) => {
    switch(type){
      case 'Windows':
        return (<select className='select' {...register('jobtype')} onChange={changeJobType}>
          <option value="None Selected">None Selected</option>
          <option value="W1(Floater)">W1(Floater- Date Range Options Shown Below)</option>
          <option value="W1(Appt Reqd)">W1 (Appt Date/Time Reqd)</option>
          <option value="W2(Appt Reqd)">W2 (Appt Date/Time Reqd)</option>
          <option value="W3(Appt Reqd)">W3 (Appt Date/Time Reqd)</option>
          <option value="W4(Appt Reqd)">W4 (Appt Date/Time Reqd)</option>
        </select>)
        break;

      case 'Eaves':
        return (<select className='select' {...register('jobtype')} onChange={changeJobType}>
          <option value="None Selected">None Selected</option>
          <option value="EH">EH(House Eaves)</option>
          <option value="EG">EG(Garage Eaves)</option>
          <option value="EH + EG">EH + EG(House and Garage Eaves)</option>
        </select>
        )
        break;
    }
  }

  const fireShowDefinitions = () => {
    setShowDefinitions(!showDefinitions);
  }

  return (
    <div>
      <br/>
      <div className='field'>
        <div className="control">
          <label className='label' htmlFor='jobtype'>Job Type [ <a style={underlineLink} className="ml-1 mr-1 is-active has-text-link is-size-6" onClick={() => fireShowDefinitions()}>
            { showDefinitions ? 'Hide Job Descriptions' : 'View Job Descriptions[Click here]' }
          </a> ]</label>
          <div className='select'>
            { options(type) }
          </div>
          <ErrorMsg msg={errorMsgs.jobtypeMustBeFilled} num={ Object.keys(errorMsgs).length-1 }/>
        </div>
      </div>

      <JobDefinitions showDefinitions={ showDefinitions } />
      <br/>

      <div className='field mb-3'>
        <div className="control">
          <label className='label' htmlFor='custom'>Custom Work</label>
          <textarea className='textarea' rows='2' id='custom' {...register('customdesc')} placeholder='Enter custom work(optional)...' />
        </div><br/>
      </div>

    </div>
  )
}

export default JobType;



