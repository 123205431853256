import React from "react";
import { locationData } from "../../helpers/lists";

import Loader from "../../components/Loader";
import useRequest from "../../hooks/useRequest";

const FormFields = ({ register }) => {
  const { data: descriptions, loading } = useRequest(
    `/api/props/fetch_descriptions`
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <input type="hidden" {...register("id")} />
      
      <div class="field">
        <label class="label">Name</label>
        <div class="control">
          <input
            type="date"
            className="input"
            {...register("date")}
          />
        </div>  
      </div>  

      <div class="field">
        <label class="label">Location</label>
        <div class="control">
          <select className="input" {...register("location")}>
            <option key="xx" value="">
              None Selected
            </option>
            {locationData.map((location) => (
              <option key={location.address} value={location.address}>
                {location.address}
              </option>
            ))}
          </select>
        </div>  
      </div>

      <div class="field">
        <label class="label">Type</label>
        <div class="control">
            <select
              className="input"
              {...register("typeIncome")}
            >
              <option value="Expense">Expense</option>
              <option value="Revenue">Revenue</option>
            </select>
        </div>
      </div>  

      <div class="field">
        <label class="label">Name</label>
        <div class="control">
            <select
              className="input"
              {...register("description")}
            >
              <option key="xx" value="">
                None Selected
              </option>
              {descriptions.map((description) => (
                <option key={description.id} value={description.description}>
                  {description.description}
                </option>
              ))}
            </select>
          </div> 
      </div>

      <div class="field">
        <label class="label">Amount</label>
        <div class="control">
            <input className="input" {...register("amount")} />
        </div>
      </div>

      <div class="field">
        <label class="label">Notes</label>
        <div class="control">
            <textarea
              className="textarea"
              name="notes"
              {...register("notes")}
              placeholder="Description..."
            />
        </div>
      </div>

      <div class="field">
        <div class="control">
          <button className="button is-link">Create Transaction</button>
        </div>  
      </div>  


    </div>
  );
};

export default FormFields;
