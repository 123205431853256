import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";

const OpenMgt = ({handleDailyReport, user, checkReportIssued, fireDateChanged, currentDate , area}) => {
  const ccForm = useForm();

  const { register, handleSubmit, setValue, getValues } = ccForm;
  const {
    daily_notes,
    daily_a_notes,
  } = getValues();

  return(
    <div>
      <h2 className='title is-3 has-text-centered'>Open Mgt{area} [{ user && user.user_name }] </h2>
      <form className='form' onSubmit={handleSubmit(handleDailyReport)}>
        <input
          type='hidden'
          {...register("hrid")}
          defaultValue = { user && user.hrid }
        />
        <input
          type='hidden'
          {...register("area")}
          defaultValue = { 'Open Mgt' + area}
        />

        <div className='field'>
          <div className='control'>
            <input
              type='date'
              {...register("date")}
              defaultValue = { currentDate }
              onChange = { () => fireDateChanged(getValues('date')) }
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line1")}
              defaultValue={ "[Arrive at 6:45am]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line2")}
              defaultValue={ "[Make Sure Rags Are Bagged]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line3")}
              defaultValue={ "[Get Keys/Tablets Out]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line4")}
              defaultValue={ "[Sign Gas Cards Out]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line5")}
              defaultValue={ "[Mark Attendance as Crews Arrive]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line6")}
              defaultValue={ "[Safety Talk and Sign Sheet]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line7")}
              defaultValue={ "[Stretch and Jacks]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line8")}
              defaultValue={ "[Direct Drivers Out]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line9")}
              defaultValue={ "[Stop each truck and ask gear, rags, soap, debris bags, signs/frames]"}
            />
          </div>
        </div><br/>

        <div className='has-text-centered'>
            <button type='submit' className='button is-link is-rounded has-text-weight-bold'>Send Open Mgt Report</button>
        </div>
      </form>
    </div>

  )


}

export default OpenMgt
