import React, { useState, useEffect, useReducer } from "react";
import { useLocation, useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../layout/Layout";
import Loader from "../../base/layout/Loader";
import { useStateReducer } from "../../base/context/authContext";
import { useForm } from "react-hook-form";
import { hours, mins } from "../helpers/lists";
import { ErrorMsg, ErrorSumm } from "../components/ErrorMsg"
import { formatCurrencyNoSign, validateDnfData } from "../helpers/helper";
import dayjs from "dayjs";
import Maps from './Maps';


import ApiClient from "../../base/api/client";




const DnfDetails = () => {

  const navigate = useNavigate();
  const params = useParams();

  const [dnf, setDnf] = useState(null);
  const [dnfPics, setDnfPics] = useState([]);
  const [job, setJob] = useState(null);
  const [client, setClient] = useState(null);
  const [email, setEmail] = useState(null);
  const [address, setAddress] = useState(null);
  const [nextDests, setNextDests] = useState([]);
  const [times, setTimes] = useState(false);
  const [chqNumClass, setChqNumClass] = useState('column is-3 is-5-mobile is-hidden');
  const [errorMsgs, setErrorMsgs] = useState({});
  const [showSubmitButton, setShowSubmitButton] = useState(null);
  const [buttonText, setButtonText] = useState(null);
  const [{ crew: state_crew }] = useStateReducer();
  const [showCash, setShowCash] = useState('is-hidden');
  const [showCashAmount, setShowCashAmount] = useState('is-hidden');

  const ccForm = useForm();
  const { register, handleSubmit, setValue, getValues } = ccForm;
  const {
    startHour,
    startMinute,
    finishHour,
    finishMinute
  } = getValues();
  let dnfMinutes;
  const pad = 2;


  const fetchDnfData = async () => {
    const dnf_pics = await ApiClient.request(`/api/ben/${params.id}/dnf_images`);
    console.log('DNF Pics', dnf_pics);
    setDnfPics(dnf_pics);

    const result = await ApiClient.request(`/api/dnfs/${params.id}`);
    console.log('DNF Result', result);
    console.log('rec Cond:',result.job.recstatus.includes('Receivable'));
    setDnf(result.dnf);
    setJob(result.job);
    setClient(result.client);
    setEmail(result.email);
    setAddress(result.address);
    setNextDests(result.nextDests);

    if(result.workTimes.startHour){
      setValue('startHour', result.workTimes.startHour);
      setValue('startHourPrior', result.workTimes.startHour);
    }
    if(result.workTimes.startMinute){
      setValue('startMinute', result.workTimes.startMinute);
      setValue('startMinutePrior', result.workTimes.startMinute);
    }
    if(result.workTimes.finishHour){
      setValue('finishHour', result.workTimes.finishHour);
    }
    if(result.workTimes.finishMinute){
      setValue('finishMinute', result.workTimes.finishMinute);
    }
    setValue('dnfNotes', result.dnf.prodcomm);
    setValue('valid', result.dnf.is_valid ? result.dnf.is_valid : 'None Selected');
    setValue('clientHome', result.dnf.clienthm ? result.dnf.clienthm : 'None Selected');


    if(!result.dnf.reportstime){
      setShowSubmitButton(false);
      setButtonText('Start DNF First');
    }else{
      setShowSubmitButton(true);
      setButtonText('Return DNF');
    }
    setValue('jobDesc', result.job.jobdesc);
    setValue('dnfDesc', result.dnf.dnfdesc);
    setValue('price', formatCurrencyNoSign(result.job.price));
    setValue('hst', formatCurrencyNoSign(result.job.price * .07));
    setValue('totalPrice', formatCurrencyNoSign(result.job.price * 1.07));
    setValue('recStatus', result.job.recstatus.includes('PAID') ? result.job.recstatus : 'None Selected');
  }


  // >>>>>>>>>>>>>>>>>> SET RECSTATUS IN DNF AND JOB


  useEffect(() => {
    state_crew && fetchDnfData();
  }, [state_crew])

  useEffect(() => {
    setTimes(false);
  }, [times])


  const fillHoursAndPay = () => {
    const stime = dayjs().hour(Number(startHour)).minute(Number(startMinute));
    const ftime = dayjs().hour(Number(finishHour)).minute(Number(finishMinute));
    dnfMinutes = ftime.diff(stime, "minutes", true).toString();
  };
  fillHoursAndPay();

  const editRecStatus = () => {
    // if esf[:recstatus] == 'Client Did Not Pay'
    //   job.recstatus = 'Receivable'
    // elsif esf[:recstatus] == 'Client Paid Cash'
    //   job.recstatus = 'Receivable - crew (cash)'
    // elsif esf[:recstatus] == 'Client Paid By Cheque'
    //   job.recstatus = 'Receivable - crew (#'+esf[:chqnum]+')'
    // end
    const racstatus = getValues('recStatus');
    const recStatus55 = job.recstatus;
    if(racstatus.includes('Cheque')){
      setChqNumClass('column is-2 is-5-mobile');
    } else {
      setChqNumClass('column is-2 is-5-mobile is-hidden');
    }
    if(racstatus.includes('Cash')){
      setShowCash('column is-2 is-5-mobile');
      setShowCashAmount('column is-2 is-5-mobile');
    }else{
      setShowCash('is-hidden');
      setShowCashAmount('is-hidden');
    }
  }

  const nextDestOptions = () => {
    if(nextDests){
      return nextDests.map((dest) => <option key={dest.jobid} value={dest.jobid}>{dest.address}[{dest.num}]-{dest.type}</option>);
    }
  }

  const sendReturnDNFData = async (updatedData) => {
    console.log('Updated Data', updatedData);
    const result = await ApiClient.patch(`/api/dnfs/${dnf.dnfid}`, updatedData);
    console.log('Result', result);
    const msg = 'DNF at ' + address + ' returned successfully';
    navigate(`/runsheet/${msg}`);
  }

  const returnDNF = (data) => {
    let temp = validateDnfData(data);
    console.log('DNF Data:', data);
    var count= Object.keys(temp).length;
    if(count===0){
      let updatedData = { ...data, hrid: state_crew.crew_hrid };
      sendReturnDNFData(updatedData)
    }else{
      setErrorMsgs(temp);
    }
  }

  const onChange = () => {
    setTimes(true);
  }

  const showDnfPics = () => {
    console.log("In DNF Pics");
    const dnf_pic_images = dnfPics.map(pic => (
      <div key={pic.created_at} className='column is-one-third'>
        <div className="card">
          <div className="card-image">
            <figure className="image is-4by3">
            <img src={pic.image_url} alt="Job Image" height='500' width="300"/>
            </figure>
          </div>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-4">{ pic.kind }</p>
                <p className="title is-4">{ pic.title }</p>
                <hr/>
                <time dateTime="2016-1-1">{ pic.created_at }</time>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))

    return(
      <div className='columns is-multiline'>
        { dnf_pic_images }
      </div>
    )
  }

  const recbleLayout = () => (
    <div className='columns'>
      <div className='column is-2'>
        <div className='field'>
          <div className='control'>
            <label className="label" htmlFor='recStatus_label'>Rec Status [{ job ? job.recstatus : null }]</label>
            <div className='select' id='recStatus_label'>
              <select onChange={editRecStatus} {...register("recStatus")} >
                <option value='None Selected'>None Selected</option>
                <option value="Receivable">Receivable</option>
                <option value="Paid By Cheque">Paid By Cheque</option>
                <option value="Paid By Cash">Paid By Cash</option>
              </select>
            </div>
            <ErrorMsg msg={errorMsgs.recStatusMustBeSelected} num={ Object.keys(errorMsgs).length-1 }/>
          </div>
        </div>
      </div>
        <div className={ showCash }>
          <div className='field'>
            <div className='control'>
            <label className="checkbox">
              <input 
                type="checkbox" 
                defaultChecked='checked' 
                {...register("sendCashReceipt")}
              />
              Send Paid Cash Receipt{ email ? '[' + email + ']' : null }
            </label>
            </div>
          </div>
        </div>
        <div className={showCashAmount}>
          <div className='field'>
            <div className='control'>
              <label className="label" htmlFor='cash_amount_label'>Cash Amount</label>
              <input 
                className='input' 
                id='cash_amount_label' 
                type='text' 
                {...register("cashAmount")}
              />
            </div>
            <ErrorMsg msg={errorMsgs.cashAmountMustBeEntered} num={ Object.keys(errorMsgs).length-1 }/>
            <ErrorMsg msg={errorMsgs.cashAmountMustBeANumber} num={ Object.keys(errorMsgs).length-1 }/>
            <ErrorMsg msg={errorMsgs.cashAmountMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
          </div>
        </div>

        <div className={chqNumClass}>
          <div className='field'>
            <div className='control'>
              <label className="label" htmlFor='chqnum_label'>Chq Num</label>
              <input 
                className='input' 
                id='chqnum_label' 
                type='text' 
                {...register("chqNum")}
              />
            </div>
          </div>
        </div>
    </div>
  )


const paidLayout = () => (
  <div className='mt-4 columns'>
    <div className='column is-4 is-5-mobile'>
      <div className='field'>
        <div className='control'>
          <label className="label has-text-link" htmlFor='recStatus_label'>Receivable Status [{ job ? job.recstatus : null }]</label>
          <input 
            className='input' 
            id='recStatus_label' 
            type='hidden' 
            {...register("recStatus")}
          />
        </div>
      </div>
    </div>
  </div>
)


  return (
    <Layout>
      <div className='section'>
        <div className="container content">
          <nav className='breadcrumb'>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/runsheet">Runsheet</Link></li>
              <li className='is-active'><Link to={`/dnf-details/${dnf && dnf.dnfid}`}>DNF Details</Link></li>
            </ul>
          </nav>
          <h2 className='title is-3'>{client}</h2>
          <h3 className='subtitle is-4'>{address}</h3><br/>
          <hr/>
            {dnf &&
            <u>
              <a className="navbar-item is-fullwidth" href={`/dnf_images?dnfid=${dnf.dnfid}&source=react_dnf_details`}>
                Add DNF Image
              </a>
            </u>}

            { dnf && dnfPics && showDnfPics() }
            <hr/>


          <form className='form' onSubmit={handleSubmit(returnDNF)}>
            <div className='columns multiline'>
              <div className='column is-3'>
                <div className='field'>
                  <div className='control'>
                    <label className='label' htmlFor='start_label'>Start Time:</label>
                    <div className='select is-small' id='start-label'>
                      <select onChange={onChange} {...register("startHour")} >
                        { hours.map((hour)=>(<option key={hour} value={hour}>{hour}</option>)) }
                      </select>
                    </div> :
                    <div className='select is-small' >
                      <select name='startMinute' onChange={onChange} {...register("startMinute")} >
                        { mins.map((min)=>(<option key={min} value={min}>{pad && min!=='' ? String(min).padStart(pad, "0") : min}</option>)) }
                      </select>
                    </div>
                    <ErrorMsg msg={errorMsgs.startHourCannotBeBlank} num={ Object.keys(errorMsgs).length-1 }/>
                    <ErrorMsg msg={errorMsgs.startMinuteCannotBeBlank} num={ Object.keys(errorMsgs).length-1 }/>
                  </div>
                </div>
              </div>


              <div className='column is-3'>
                <div className='field'>
                  <div className='control'>
                    <label className='label' htmlFor='finish_label'>Finish Time:</label>
                    <div className='select is-small' id='finish_label'>
                      <select name='finishHour' onChange={onChange} {...register("finishHour")} >
                        { hours.map((hour)=>(<option key={hour} value={hour}>{hour}</option>)) }
                      </select>
                    </div> :
                    <div className='select is-small' >
                      <select name='finishMinute' onChange={onChange} {...register("finishMinute")} >
                        { mins.map((min)=>(<option key={min} value={min}>{pad && min!=='' ? String(min).padStart(pad, "0") : min}</option>)) }
                      </select>
                    </div>
                    <ErrorMsg msg={errorMsgs.finishHourCannotBeBlank} num={ Object.keys(errorMsgs).length-1 }/>
                    <ErrorMsg msg={errorMsgs.finishMinuteCannotBeBlank} num={ Object.keys(errorMsgs).length-1 }/>
                  </div>
                </div>
              </div>

              <div className='column is-2 is-5-mobile'>
                <div className='field'>
                  <div className='control'>
                    <label className="label" htmlFor='totmins'>Total Minutes</label>
                    <input 
                      id='totmins' 
                      size='10' 
                      className='input is-small' 
                      type="text" 
                      {...register("minutes")}
                      value={dnfMinutes}
                      readOnly 
                    />
                  </div>
                  <ErrorMsg msg={errorMsgs.dnfMinutesGreaterThanZero} num={Object.keys(errorMsgs).length-1}/>
                </div>
              </div>
            </div>


            <div className='field'>
              <div className='control'>
                <label className="label" forhtml='jobdesc_input'>Job Description</label>
                  <textarea
                    className='textarea'
                    rows='2'
                    id='jobdesc_input'
                    {...register("jobDesc")}
                    readOnly
                  />
              </div>
            </div>

            <div className='field'>
              <div className='control'>
                <label className="label" forhtml='dnfdesc_input'>DNF Description</label>
                  <textarea
                    className='textarea'
                    rows='2'
                    id='dnfdesc_input'
                    {...register("dnfDesc")}
                    readOnly
                />
              </div>
            </div>

            <div className='columns'>
              <div className='column is-2 is-5-mobile'>
                <div className='field'>
                  <div className='control'>
                    <label className="label" htmlFor='price_label'>Price</label>
                    <input 
                      className='input' 
                      type='text' 
                      id='price_label' 
                      {...register("price")}
                      readOnly 
                    />
                  </div>
                </div>
              </div>
              <div className='column is-2 is-5-mobile'>
                <div className='field'>
                  <div className='control'>
                    <label className="label" htmlFor='hst_label'>HST</label>
                    <input 
                      className='input' 
                      type='text' 
                      id='hst_label' 
                      {...register("hst")}
                      readOnly 
                    />
                  </div>
                </div>
              </div>
              <div className='column is-2 is-5-mobile'>
                <div className='field'>
                  <div className='control'>
                    <label className="label" htmlFor='totalPrice_label'>Total</label>
                    <input 
                      className='input' 
                      type='text' 
                      {...register("totalPrice")}
                      id='totalPrice_label'  
                      readOnly 
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr/>

            <div className='field'>
              <div className='control'>
                <label className="label" forhtml='dnfnotes_input'>DNF Notes</label>
                  <textarea
                    className='textarea'
                    rows='4'
                    id='dnfnotes_input'
                    {...register("dnfNotes")}
                  />
                <ErrorMsg msg={errorMsgs.dnfNotesMustBeFilled} num={Object.keys(errorMsgs).length-1}/>
              </div>
            </div>

            <div className='field'>
              <div className='control'>
                <label className="label" htmlFor='valid_label'>Valid</label>
                <div className='select' id='valid_label'>
                  <select {...register("valid")} >
                    <option value='None Selected'>None Selected</option>
                    <option value='VALID'>Valid</option>
                    <option value='INVALID'>Invalid</option>
                    <option value='Client Service'>Client Service</option>
                  </select>
                </div>
                <ErrorMsg msg={errorMsgs.validMustBeSelected} num={ Object.keys(errorMsgs).length-1 }/>
              </div>
            </div>

            <hr/>

            <div className='field'>
              <div className='control'>
                <label className="label" htmlFor='clientHome_label'>Client Home</label>
                <div className='select' id='clientHome_label'>
                  <select {...register("clientHome")} >
                    <option value='None Selected'>None Selected</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                  </select>
                </div>
                <ErrorMsg msg={errorMsgs.clientHomeMustBeSelected} num={ Object.keys(errorMsgs).length-1 }/>
              </div>
            </div>


            { job && job.recstatus === 'PAID'? paidLayout() : recbleLayout() }


            <div className='field'>
              <div className='control'>
                <label className="label" htmlFor='nextDest_label'>Next Destination</label>
                <div className='select' id='nextDest_label'>
                  <select {...register("nextDest")} >
                    { nextDestOptions() }
                  </select>
                </div>
                <ErrorMsg msg={errorMsgs.nextDestinationMustBeSelected} num={ Object.keys(errorMsgs).length-1 }/>
              </div>
            </div><br/>
            { Object.keys(errorMsgs).length-1 > 0 ?
              <ErrorMsg msg={errorMsgs.totalErrors} num={ Object.keys(errorMsgs).length-1 } /> : null }

            <button name='return_job' type='submit' className='button is-link is-large is-rounded is-fullwidth has-text-weight-bold'>Return DNF</button>

          </form>

        </div>
      </div>
    </Layout>
  );
};

export default DnfDetails;


//   <Maps /><br/>
