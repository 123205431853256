import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import FormCard from "../../components/FormCard";
import useRequest from "../../hooks/useRequest";
import Layout from "../../layout/Layout";
import ApiClient from "../../api/client";

const NewDescription = () => {
  const history = useNavigate();
  const { register, handleSubmit, reset } = useForm();

  const { data: descriptionOptions, loading } = useRequest(
    "/api/props/fetch_descriptions"
  );

  const onSubmit = async (params) => {
    console.log("PARAMS: ", params);
    const response = await ApiClient.post(`/api/props/add_description`, params);
    if (response) {
      history.push("/transactions");
    }
  };

  return (
    <Layout>
      
      <div className="container"> 
        <button className="button button-assign" onClick={() => history.goBack()}>
          Back
        </button><br/><hr/>
        <div className="columns"> 
          <div className="column-one-third"> 
              <form onSubmit={handleSubmit(onSubmit)}>
                <div class="field">
                  <label class="label">Description</label>
                  <div class="control">
                    <input
                      type="text"
                      className="input"
                      {...register("description")}
                      placeholder="description"
                    />
                  </div>
                </div>  
                <button className="button">Create</button>
              </form>
          </div>
        </div>    
        <br/><br/>
        <div className="panel p-3">
          <h3 className="subtitle">Descriptions</h3>
          {descriptionOptions?.map((d) => (
            <h6 key={d.id}>{d.description}</h6>
          ))}
        </div>
      </div>     
    </Layout>
  );
};

export default NewDescription;
