import React, { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";
import LoginAPI from "../api/login";
import { useStateReducer } from "../context/authContext";
import ActionType from "../context/actionType";


const HeaderLink = ({ children, to, logOut, className, activeclassname }) => {
  return (
    <NavLink
      className={className}
      activeclassname={activeclassname}
      to={to}
      onClick={logOut}
      exact="true"
    >
      {children}
    </NavLink>
  );
};

const HeaderBulma = () => {
  const [, dispatch] = useStateReducer();
  const [burgerClass, setBurgerClass] = useState("button navbar-burger is-link");
  const [menuClass, setMenuClass] = useState("navbar-menu")
  
  const [{ user, mgr }] = useStateReducer();


  useEffect(() => {
    console.log('User in Prod Mgt', user);
  }, [user])

  const attemptLogout = async () => {
    await LoginAPI.logOut();
    dispatch({
      type: ActionType.SetUser,
      payload: null,
    });
  };

  const logOut = () => {
    attemptLogout();
  };


  const menuClick = () => {
    burgerClass === "button navbar-burger is-link" ?
    setBurgerClass("button navbar-burger is-link is-active") : setBurgerClass("button navbar-burger is-link")
    menuClass === "navbar-menu" ?
    setMenuClass("navbar-menu is-active") : setMenuClass("navbar-menu")
  }

  return (
        <nav className='navbar is-link' role='navigation' aria-label='Main Navigation'>
            <div className='navbar-brand'>
                <HeaderLink to="/" className="navbar-item has-text-weight-bold">
                White Shark
                </HeaderLink>
                <button className={burgerClass} data-target='navMenu' onClick={menuClick}>
                <span></span>
                <span></span>
                <span></span>
                </button>
            </div>
            <div className={menuClass} id='navMenu'>
                <div className='navbar-start'>
            
                    <HeaderLink to="/" className="navbar-item">
                        Home
                    </HeaderLink>

                    <HeaderLink
                        to="/makepackages"
                        className="navbar-item has-text-white"
                    >
                        Make Packages
                    </HeaderLink>
                    <HeaderLink
                        to="/crewsetup"
                        className="navbar-item has-text-white"
                    >
                        Crew Set Up
                    </HeaderLink>
                    <HeaderLink
                        to="/crewpay"
                        className="navbar-item has-text-white"
                    >
                        Crew Pay
                    </HeaderLink>

                    { user.hrid === 'HR00000001' || user.hrid === 'HR00000429' ?
                    <HeaderLink
                        to="/transactions"
                        className="navbar-item has-text-white"
                    >
                        Prop Mgt
                    </HeaderLink> : null }
                    <a className="navbar-item is-fullwidth" href="/menu_items/production_menu">
                        Production Menu
                    </a>

                </div>
            </div>
       </nav>
  );
  
};

export default HeaderBulma;


