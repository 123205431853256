import React, { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";
import LoginAPI from "../../base/api/login";
import { useStateReducer } from "../../base/context/authContext";
import ActionType from "../../base/context/actionType";

const HeaderLink = ({ children, to, logOut, className, activeclassname }) => {
  return (
    <NavLink
      className={className}
      activeclassname={activeclassname}
      to={to}
      onClick={logOut}
      exact="true"
    >
      {children}
    </NavLink>
  );
};

const Header = () => {
  const [{ mgr, user }, dispatch] = useStateReducer();
  const [menuLaunched, setMenuLaunched] = useState("menu-links-container");
  const [menuOpen, setMenuOpen] = useState(false);
  const [burgerClass, setBurgerClass] = useState("button navbar-burger is-link");
  const [menuClass, setMenuClass] = useState("navbar-menu")

  useEffect(() => {
    if(mgr){
    }
  }, [mgr, user])

  const attemptLogout = async () => {
    await LoginAPI.logOut();
    dispatch({
      type: ActionType.SetUser,
      payload: null,
    });
  };

  const logOut = () => {
    attemptLogout();
  };



  const menuClick = () => {
    burgerClass === "button navbar-burger is-link" ?
    setBurgerClass("button navbar-burger is-link is-active") : setBurgerClass("button navbar-burger is-link")
    menuClass === "navbar-menu" ?
    setMenuClass("navbar-menu is-active") : setMenuClass("navbar-menu")
  }

  return (
       <nav className='navbar is-link' role='navigation' aria-label='Main Navigation'>
        <div className='container'>
          <div className='navbar-brand'>
            <HeaderLink to="/" className="navbar-item has-text-weight-bold">
              White Shark
            </HeaderLink>
            <button className={burgerClass} data-target='navMenu' onClick={menuClick}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
          <div className={menuClass} id='navMenu'>
            <div className='navbar-start'>
                <HeaderLink to="/" className="navbar-item">
                  Home
                </HeaderLink>

                <HeaderLink to="/daily_reports" className="navbar-item">
                  Daily Reports
                </HeaderLink>

                <a className="navbar-item is-fullwidth" href="/sharktales/org_tree">
                  Organizational Tree
                </a>

            </div>
          </div>
        </div>
      </nav>
  );
};

export default Header;
