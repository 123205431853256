import React from "react";
import { Link } from "react-router-dom";
import { makeNiceDate } from "../helpers/helper";

const CrewProfileHeader = ({ newSearch, date, driver, partner, partner1 }) => {
  return (
    <div className="box has-text-weight-bold">
      <h3>
        <span className=" has-text-weight-bold subtitle mr-4">Crew Profile</span> 
        <span className="subtitle mr-4">[{makeNiceDate(date)}]</span>
        {driver ? (
          <span className="subtitle mr-4">{driver.name}</span>
        ) : null}{" "}
        {partner ? (
          <span className="subtitle mr-4">{partner.name}</span>
        ) : null}
        {partner1 ? (
          <span className="subtitle mr-4">{partner1.name}</span>
        ) : null}
        <span className="subtitle">
          <Link to={`/crewpay?${newSearch}`}>Go Back</Link>
        </span>
      </h3>
    </div>
  );
};

export default CrewProfileHeader;
