import React from "react";
import {
  hoursWithLabels,
  minsWithLabels,
} from "../../../base_project/helpers/lists";
import Select from "./Select";

const TimeSelect = React.forwardRef(({ baseName, label }, register) => {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="field is-grouped">
        <Select name={`${baseName}Hour`} options={hoursWithLabels} register={register} />
        <span className="form__colon">:</span>
        <Select name={`${baseName}Min`} options={minsWithLabels} register={register} />
      </div>
    </div>
  );
});

export default TimeSelect;
