import React from "react";
import { Link } from "react-router-dom";

import OPEN from "../assets/open.png";
import { calcPercentage } from "../helpers/helper";

const DailyProfile = ({
  dailyProfileDate,
  dailyProfileData,
  crewName,
  params,
}) => {
  if ((dailyProfileData && dailyProfileData.length === 0) || dailyProfileDate === null) {
    return null;
  }

  console.log("Daily Profile: ", dailyProfileDate);

  return (
    <div className="table">
      <table className="table">
        <thead>
          <tr>
            <th colSpan={7}>
              {dailyProfileDate}&nbsp;&nbsp;{params}
            </th>
          </tr>
          <tr>
            <th>Crew</th>
            <th>Amount</th>
            <th>Labour</th>
            <th>%</th>
            <th>No Pay</th>
            <th>No Audit</th>
            <th>Open</th>
          </tr>
        </thead>
        <tbody>
          {dailyProfileData.map((item) => (
            <tr key={item.crewname} className={item.crewname === crewName ? "has-background-danger" : undefined}>
              <td>
                {item.crewname}
              </td>
              <td>
                {item.total}
              </td>
              <td>
                {item.tot_labour}
              </td>
              <td>
                {calcPercentage(item.tot_labour, item.total)}
              </td>
              <td>
                {item.no_pays.length}
              </td>
              <td>
                {item.names_unaudited.length}
              </td>
              <td>
                <Link
                  to={`/crewprofile?${params.toString()}&crewName=${
                    item.crewname
                  }`}
                >
                  <img className="image is-32x32" src={OPEN} alt="open" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DailyProfile;
