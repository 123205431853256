import React, { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";
import LoginAPI from "../../base/api/login";
import { useStateReducer } from "../../base/context/authContext";
import ActionType from "../../base/context/actionType";

const HeaderLink = ({ children, to, logOut, className, activeclassname }) => {
  return (
    <NavLink
      className={className}
      activeclassname={activeclassname}
      to={to}
      onClick={logOut}
      exact="true"
    >
      {children}
    </NavLink>
  );
};

const Header = () => {
  const [{ mgr, user }, dispatch] = useStateReducer();
  const [burgerClass, setBurgerClass] = useState("button navbar-burger has-text-white");
  const [menuClass, setMenuClass] = useState("navbar-menu")

  useEffect(() => {
    if(mgr){
    }
  }, [mgr, user])

  const attemptLogout = async () => {
    await LoginAPI.logOut();
    dispatch({
      type: ActionType.SetUser,
      payload: null,
    });
  };

  const logOut = () => {
    attemptLogout();
  };



  const menuClick = () => {
    burgerClass === "button navbar-burger has-text-white" ?
    setBurgerClass("button navbar-burger has-text-white is-active") : setBurgerClass("button navbar-burger has-text-white")
    menuClass === "navbar-menu" ?
    setMenuClass("navbar-menu is-active") : setMenuClass("navbar-menu")
  }

  return (
       <nav className='navbar is-link' role='navigation' aria-label='Main Navigation'>
        <div className='container'>
          <div className='navbar-brand'>
            <HeaderLink to="/" className="navbar-item has-text-weight-bold is-size-4 mr-6">
              White Shark Ltd
            </HeaderLink>
            <button className={burgerClass} data-target='navMenu' onClick={menuClick}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
          <div className={menuClass} id='navMenu'>
            <div className='navbar-start'>
                <HeaderLink to="/" className="navbar-item">
                  Home
                </HeaderLink>

                <div className='navbar-item has-dropdown is-hoverable'>
                  <div className='navbar-link'>Daily</div>
                  <div className='navbar-dropdown is-boxed'>
                    <HeaderLink to="/runsheet" className="navbar-item">
                      Runsheet
                    </HeaderLink>
                    <HeaderLink to="/stats" className="navbar-item">
                      Stats
                    </HeaderLink>
                    <HeaderLink to="/crew-estimates-signs" className="navbar-item">
                      Estimates-Signs
                    </HeaderLink>
                  </div>
                </div>

                { (user && (user.hrid === 'HR00006217'   // Samantha
                  || user.hrid === 'HR00006229'  // Juwan
                  || user.hrid === 'HR00006221'  // Ryansh
                  || user.hrid === 'HR00006110'  // Malik
                  || user.hrid === 'HR00005884'  // Natalie
                  || user.hrid === 'HR00000429'  // Shanti
                  || user.hrid === 'HR00006094'  // Andrew R
                  || user.hrid === 'HR00006212'  // Tiana
                  || user.hrid === 'HR00005930'  // Jack
                  || user.hrid === 'HR00000001') // Roger
                  || (mgr && mgr.hrid)) ?
                  <React.Fragment>

                    <div className='navbar-item has-dropdown is-hoverable'>
                      <div className='navbar-link'>Crew Mgt</div>
                      <div className='navbar-dropdown is-boxed'>
                        <HeaderLink to="/open-close" className="navbar-item">
                          Open-Close
                        </HeaderLink>
                        <HeaderLink to="/safety-talk" className="navbar-item">
                          Safety Talk
                        </HeaderLink>
                        <HeaderLink to="/crew-payments" className="navbar-item">
                          Crew Payments
                        </HeaderLink>
                        <HeaderLink to="/recruiting" className="navbar-item">
                          Recruiting
                        </HeaderLink>
                      </div>
                    </div>

                    <HeaderLink to="/crew-estimates-signs" className="navbar-item">
                      Estimates-Signs
                    </HeaderLink>
                  </React.Fragment>
                : null }


              { mgr && ['HR00006257', 'HR00000429', 'HR00005495', 'HR00000762', 'HR00000001'].includes(mgr.hrid) ?
              <div className='navbar-item has-dropdown is-hoverable'>
                <div className='navbar-link'>Management</div>
                <div className='navbar-dropdown is-boxed'>
                  <HeaderLink to="/open-close" className="navbar-item">
                    Open-Close
                  </HeaderLink>
                  <HeaderLink to="/crew_needs" className="navbar-item">
                    Crew Needs
                  </HeaderLink>
                  <HeaderLink to="/maps" className="navbar-item">
                    Maps
                  </HeaderLink>
                  <HeaderLink to="/test" className="navbar-item">
                    Test
                  </HeaderLink>
                  <HeaderLink to="/partner_dashboard" className="navbar-item">
                    Dashboard
                  </HeaderLink>
                  <HeaderLink to="/live_action" className="navbar-item">
                    Live Action
                  </HeaderLink>
                </div>
              </div> : null }
              <a className="navbar-item is-fullwidth" href="/menu_items/crew_menu">
                Crew Menu
              </a>

            </div>
          </div>
        </div>
      </nav>
  );
};

export default Header;
