import React from "react";
import { Navigate } from "react-router";
import { useStateReducer } from "../context/authContext";
import Loader from "../components/Loader";
import HeaderBulma from "./HeaderBulma";
import Ticker from "./Ticker";

const Layout = ({ children }) => {
  const [{ user, userLoading }] = useStateReducer();

  if (userLoading) {
    return <Loader />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <title>White Shark</title>
      <div>
        <header>
          <HeaderBulma />
        </header>
        <main>{children}</main>
      </div>
    </div>
  );
};

export default Layout;


// <Ticker />
       