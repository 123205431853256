import React from "react";

const SelectTypeOptions = React.forwardRef(({ onChange }, register) => {
  return (
    <select
      className="filters__input  filters__input--range"
      {...register("type")}
      onChange={onChange}
    >
      <option value="All Estimates">All Estimates</option>
      <option value="Pending">Pending</option>
      <option value="Scheduled">Scheduled</option>
      <option value="Completed">Completed</option>
      <option value="Paid">Paid</option>
    </select>
  );
});

export default SelectTypeOptions;
