import React, { useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useStateReducer } from "../../base/context/authContext";

import dayjs from "dayjs";

import Layout from "../layout/Layout";
import Loader from "../layout/Loader";
import FiltersEstSumm from "../components/estimate-summ/FiltersEstSumm";
import ApiClient from "../../base/api/client";
import { formatCurrency } from "../helpers/helper";

const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const [{ user, userLoading }] = useStateReducer();

  const { register, watch } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    const loadTasks = async () => {
      const tasks = await ApiClient.request(
        `/api/tasks/${user.hrid}/estimates`
      );
      console.log("Tasks:", tasks);
      setTasks(tasks);
    };
    if (user) {
      loadTasks();
    }
  }, [user]);

  const filters = watch();

  const filteredTasks = useMemo(() => {
    if (!tasks) return [];
    return tasks.filter(
      (task) =>
        task.status.includes(filters.type) || filters.type === "All Estimates"
    );
  }, [tasks, filters]);

  if (!tasks) {
    return <Loader />;
  }

  const onDeleteTransaction = () => {};

  //const callClient = () => {};

  const sendEmail = async (id) => {
    const body = {
      id,
      user_id: user.hrid,
    };
    const response = await ApiClient.post(`/api/tasks/send_email`, body);
    console.log("Response", response);
  };

  const buttonLabel = (task) => {
    if (task.status.includes("Paid")) {
      return "Owed-" + formatCurrency(task.comm);
    } else if (task.status.includes("Completed")) {
      return "Pend-" + formatCurrency(task.comm);
    } else {
      return "No Activity";
    }
  };

  const fixLabel = (task) => {
    let label = `${task.w1}  ${task.eh}`;
    if (task.status.includes("Scheduled")) {
      label = `${task.jobdesc}`;
    } else if (
      task.status.includes("Produced") ||
      task.status.includes("Paid")
    ) {
      label = `${task.jobdesc} ${formatCurrency(task.price)} [${task.crew}]`;
    }
    return label;
  };

  return (
    <Layout>
      <div className="container">
        <FiltersEstSumm tasks={tasks} register={register} />
        {filteredTasks.map((task) => (
          <div key={task.id} className="display-panel-container-plain">
            <div className="display-panel-container__row">
              <span className="display-panel-container__item-1">
                {dayjs(task.messdate).format("ddd MMM DD YYYY")}
              </span>
              <span className="display-panel-container__item-1">
                {task.type}
              </span>
              <span className="display-panel-container__item-1">
                {task.holder}
              </span>
              <span className="display-panel-container__item-1">
                {task.address}
              </span>
            </div>
            <div className="display-panel-container__row display-panel-container__row">
              <span className="display-panel-container__item-2">
                {fixLabel(task)}
              </span>
              <span className="display-panel-container__item-2">
                {task.status} [Time:
                {task.complete_hours ? task.complete_hours : "??"}]
              </span>
            </div>
            <div className="display-panel-container__row display-panel-container__row__padding-tb display-panel-container__flex-row-start">
              {/* <span className="display-panel-container__item-2 display-panel-container__flex-row-start"> */}

              <div>
                <h3
                  className="button is-danger"
                  onClick={() => sendEmail(task.id)}
                >
                  Send Email
                </h3>
              </div>
              <div>
                <h3 className="button is-info">{buttonLabel(task)}</h3>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default Tasks;
