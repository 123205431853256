import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";

const FailureMgt = ({handleDailyReport, user, checkReportIssued, fireDateChanged, currentDate}) => {
  const ccForm = useForm();

  const { register, handleSubmit, setValue, getValues } = ccForm;
  const {
    daily_notes,
    daily_a_notes,
  } = getValues();

  return(
    <div>
      <h2 className='title is-3 has-text-centered'>Failure Mgt [{ user && user.user_name }] </h2>
      <form className='form' onSubmit={handleSubmit(handleDailyReport)}>
        <input
          type='hidden'
          {...register("hrid")}
          defaultValue = { user && user.hrid }
        />
        <input
          type='hidden'
          {...register("area")}
          defaultValue = { 'Failure Mgt' }
        />

        <div className='field'>
          <div className='control'>
            <input
              type='date'
              {...register("date")}
              defaultValue = { currentDate }
              onChange = { () => fireDateChanged(getValues('date')) }
            />
          </div>
        </div><br/>



        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line1")}
              defaultValue={ "[Change Person In Charge]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line2")}
              defaultValue={ "[Edit Questions Asked]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line3")}
              defaultValue={ "[New Category(+ who manages it + questions)]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line4")}
              defaultValue={ "[Instructions]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              {...register("line5")}
              defaultValue={ "[Need software to retrieve and show data]"}
            />
          </div>
        </div><br/>

        <div className='has-text-centered'>
            <button type='submit' className='button is-link is-rounded has-text-weight-bold'>Send Failure Mgt</button>
        </div>
      </form>
    </div>

  )


}

export default FailureMgt
