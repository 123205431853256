import React, { useState } from 'react';

import { ErrorMsg } from "../components/ErrorMsg";


const FeedbackForm = ({ client, title, handleSubmit, submitForm, register, clientAction, jobplus, getValues, setValue, errorMsgs }) => {

  const[chars, setChars] = useState(0);

  const changeMenu = (e) => {
    e.preventDefault();
    clientAction('completedJobs', null);
  }

  const countLetters = () => {
    const text = getValues("comments");
    if(text && text.length>100){
      setValue('comments', text.substring(0,100));
    }
    setChars(text.length);
  }

  return (
    <div className='box'>

      <nav className='breadcrumb'>
        <ol className='breadcrumb'>
          <li onClick={changeMenu}><a href="#">Completed Jobs</a></li>
          <li className='is-active' onClick={changeMenu}><a href="#">Report Feedback</a></li>
        </ol>
      </nav>

      <h2 className='title is-5 has-text-centered'>Report Feedback</h2>
      <form onSubmit={handleSubmit(submitForm)}>
        <div className='field has-text-weight-bold '>JobID: { jobplus && jobplus.job.jobid}</div>
        <div className='field has-text-weight-bold '>Address: { jobplus && jobplus.address}</div>

        <div className='field'>
          <div className='control'>
            <label className='label' htmlFor='comments'>Feedback(100 characters max { chars>0 ? '- ' + chars : null})</label>
            <textarea 
              className='textarea' 
              id='comments' 
              onKeyUp={countLetters} 
              {...register('comments')} 
              placeholder='Enter details here...'
            />
          </div>
        </div>
        <ErrorMsg msg={errorMsgs.comments} num={ Object.keys(errorMsgs).length-1 }/>
        <div className='field'>
          <div className='control'>
            <label className='label' htmlFor='rating'>Rating</label>
            <div className='select'>
              <select className='select' id='rating' {...register('rating')} >
                <option value="None Selected">None Selected</option>
                <option value="1">Excellent</option>
                <option value="2">Good</option>
                <option value="3">Poor</option>
              </select>
            </div>
            <ErrorMsg msg={errorMsgs.rating} num={ Object.keys(errorMsgs).length-1 }/>
          </div>
        </div>

        <button className='button is-primary' type='submit'>Submit Feedback</button>
      </form>
    </div>)
}

export default FeedbackForm;
