import React from "react";

import SHARK from "../assets/shark-pic.jpg";
import Layout from "./Layout";

const Home = () => {
  return (
    <Layout>
      <div className='hero'>
        <img className="home-image" src={SHARK} alt="Shark" />
      </div>
    </Layout>
  );
};

export default Home;
