import ReactOnRails from 'react-on-rails';

import HelloWorld from '../bundles/HelloWorld/components/HelloWorld';
import ProductionManagement from '../bundles/production_management/App';
import AreaManagement from '../bundles/area_management/App';
import WineDelivery from '../bundles/wine_delivery/App';
import CrewManagement from '../bundles/crew_management/App';
import ClientDashboard from '../bundles/client_dashboard/App';
import Reports from '../bundles/reports/App';

console.log('IN APPLICATION.JS &&&&&&&&&&&&&&&&&');

ReactOnRails.register({
  HelloWorld,
  ProductionManagement,
  AreaManagement,
  WineDelivery,
  CrewManagement,
  ClientDashboard,
  Reports
});
