import React, { useState, useEffect } from 'react';
import Layout from "../../../layout/Layout";
import { Link, Redirect , useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import PickUpForm from "../forms/PickUpForm";
import Loader from "../../../../base/layout/Loader";
import { useStateReducer } from "../../../../base/context/authContext";
import ApiClient from "../../../../base/api/client";



const PickUpAdminCrew = () => {
  const we = "Window Estimates";
  const [{ user, userLoading }] = useStateReducer();
  const routeParams = useParams();
  const [message, setMessage] = useState(null);
  const [errors, setErrors] = useState(null);
  const [errorClassName, setErrorClassName] = useState('is-hidden');
  const [showLoader, setShowLoader] = useState(false);

  const form = useForm();
  const { register, handleSubmit, getValues, setValue } = form;

  console.log('Route Params:', routeParams.id);



  const returnPickUp = async (data) => {
    setShowLoader(true);
    console.log('User', user);
    let updatedData = {...data, hrid: user.hrid, name: user.fullname, email: user.email}
    console.log('Updated Datasis', updatedData);
    // const qs = new URLSearchParams(data).toString();
    // console.log('QS', qs);
    let tempErrors = []
    const result = await ApiClient.patch(`/api/pick_ups/${routeParams.id}`, updatedData)
    setMessage(data.pick_up_type + ' Pick Up for ' + data.client + ' at ' + data.address + ' recorded successfully');
    setErrors(null);
    setErrorClassName('is-hidden');

    setShowLoader(false);
  }

  const showErrors = () => {
    const result = errors.map((error) => <h1 key={error} className={ errorClassName }>{ error }</h1>)
    return <div><br/><br/>{ result }<br/></div>
  }

  const showForm = () => { return (<Layout>
      <div className='container'>
      <h1 className='mt-3 subtitle has-text-centered has-text-success'>{user && user.name}</h1>
      <nav className='breadcrumb'>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/crew-estimates-signs">Estimates/Signs</Link></li>
          <li className='is-active'><Link to={`/pick_up_admin/${routeParams.id}`}>Return Pick Up</Link></li>
        </ul>
      </nav><br/>
      { errors && showErrors() }

      { message ? <div><br/><br/><h1 className='mt-1 subtitle has-text-centered has-text-link has-text-weight-bold'>{ message }</h1></div> :
      <PickUpForm
        register={ register }
        handleSubmit={ handleSubmit }
        returnPickUp={ returnPickUp }
        msid={ routeParams.id }
      /> }
      </div>
    </Layout>)

  }

  return (<div>
    { showLoader ? <Loader /> : showForm() };
  </div>)


}

export default PickUpAdminCrew;
