import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router";
// import { createBrowserHistory } from "history";

import InspectionForm from "../views/Partner/forms/InspectionForm";

import Home from "../layout/Home";
import Runsheet from "../views/Runsheet";
import Stats from "../views/Stats";
import CrewNeeds from "../views/CrewNeeds";
import PaintLeadAdmin from "../views/Partner/admin/PaintLeadAdmin";
import OddJobLeadAdmin from "../views/Partner/admin/OddJobLeadAdmin";
import WindowLeadAdmin from "../views/Partner/admin/WindowLeadAdmin";
import WindowLeadAdminCrew from "../views/Partner/admin/WindowLeadAdminCrew";
import PickUpAdmin from "../views/Partner/admin/PickUpAdmin";
import PickUpAdminCrew from "../views/Partner/admin/PickUpAdminCrew";
import PartnerDashboard from "../views/Partner/PartnerDashboard";
import Leads from "../views/Partner/Leads";
import Jobs from "../views/Partner/Jobs";
import Training from "../views/Partner/Training";
import EstimatesSigns from "../views/Partner/EstimatesSigns";
import Tasks from "../views/Partner/Tasks";
import Plan from "../views/Partner/Plan";
import Receivables from "../views/Partner/Receivables";
import Financials from "../views/Partner/Financials";
import Schedule from "../views/Partner/Schedule";
import Admin from "../views/Partner/Admin";
import ClientAdmin from "../views/Partner/components/ClientAdmin";
import OpenClose from "../views/OpenClose";
import SafetyTalk from "../views/SafetyTalk";
import CrewPayments from "../views/CrewPayments";
import Recruiting from "../views/Recruiting";
import CrewDetails from "../views/CrewDetails";
import JobDetails from "../views/JobDetails";
import DnfDetails from "../views/DnfDetails";
import Maps from "../views/Maps";
import LiveAction from "../views/LiveAction/LiveAction";
import EditLiveAction from "../views/LiveAction/EditLiveAction";
import CreateLiveAction from "../views/LiveAction/CreateLiveAction";

//export const history = createBrowserHistory({ basename: "crews/react_crew_mgt" });

// <Router //history={history} basename = "/crews/react_crew_mgt">   

const AppRouter = () => (
  <Router basename = "/crews/react_crew_mgt">   
    <Routes>
      <Route path="/" exact element={ <Home /> } />
      <Route path="/login" exact element = { <Home/> } />
      <Route path="/open-close" exact element = { <OpenClose/> } />
      <Route path="/safety-talk" exact element = { <SafetyTalk/> } />
      <Route path="/crew-payments" exact element = { <CrewPayments/> } />
      <Route path="/recruiting" exact element = { <Recruiting/> } />
      <Route path="/crew-estimates-signs" exact element = { <EstimatesSigns/> } />
      <Route path="/crew_needs" exact element = { <CrewNeeds/> } />
      <Route path="/crew-details/:id" exact element = { <CrewDetails/> } />
      <Route path="/partner_dashboard" exact element = { <PartnerDashboard/> } />
      <Route path="/partner_leads" exact element = { <Leads/> } />
      <Route path="/partner_leads/:list" exact element = { <Leads/> } />
      <Route path="/partner_jobs" exact element = { <Jobs/> } />
      <Route path="/partner_plan" exact element = { <Plan/> } />
      <Route path="/partner_schedule" exact element = { <Schedule/> } />
      <Route path="/partner_admin" exact element = { <Admin/> } />
      <Route path="/partner_receivables" exact element = { <Receivables/> } />
      <Route path="/partner_financials" exact element = { <Financials/> } />
      <Route path="/partner_training" exact element = { <Training/> } />
      <Route path="/partner_tasks" exact element = { <Tasks/> } />
      <Route path="/partner_inspection/:id" exact element = { <InspectionForm/> } />
      <Route path="/partner_client_admin/:cfid" exact element = { <ClientAdmin/> } />

      <Route path="/paint_lead_admin/:id" exact element = { <PaintLeadAdmin/> } />
      <Route path="/odd_job_lead_admin/:id" exact element = { <OddJobLeadAdmin/> } />
      <Route path="/window_lead_admin/:id" exact element = { <WindowLeadAdmin/> } />
      <Route path="/window_lead_admin_crew/:id" exact element = { <WindowLeadAdminCrew/> } />
      <Route path="/pick_up_admin/:id" exact element = { <PickUpAdmin/> } />
      <Route path="/pick_up_admin_crew/:id" exact element = { <PickUpAdminCrew/> } />
      <Route path="/runsheet" exact element = { <Runsheet/> } />
      <Route path="/runsheet/:success" exact element = { <Runsheet/> } />
      <Route path="/stats" exact element = { <Stats/> } />
      <Route path="/job-details/:id" exact element = { <JobDetails/> } />
      <Route path="/dnf-details/:id" exact element = { <DnfDetails/> } />
      <Route path="/maps" exact element = { <Maps/> } />
      <Route path="/live_action" exact element = { <LiveAction/> } />
      <Route path="/live_action/:msg" exact element = { <LiveAction/> } />
      <Route path="/create_live_action" exact element = { <CreateLiveAction/> } />
      <Route path="/edit_live_action/:id" exact element = { <EditLiveAction/> } />
    </Routes>
  </Router>
);

export default AppRouter;
