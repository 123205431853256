import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";

import Home from "../layout/Home";
import Tasks from "../views/Tasks";
import ReturnEstimate from "../views/ReturnEstimate";
import ReturnDNF from "../views/ReturnDNF";
import ReturnSign from "../views/ReturnSign";
import EstimateSumm from "../views/EstimateSumm";
import PropertyNotes from "../views/PropertyNotes";
import NewClients from "../views/NewClients";

export const history = createBrowserHistory({ basename: "/crews/react_area" });
const AppRouter = () => (
  <Router history={history}>
    <Routes>
      <Route path="/" component={Home} exact="true" />
      <Route path="/tasks" component={Tasks} exact="true" />
      <Route
        path="/tasks/:id/return_estimate"
        component={ReturnEstimate}
        exact="true"
      />
      <Route
        path="/estimate/:id/property-notes"
        component={PropertyNotes}
        exact="true"
      />
      <Route path="/tasks/:id/return_sign" component={ReturnSign} exact="true" />
      <Route path="/tasks/:id/return_dnf" component={ReturnDNF} exact="true" />
      <Route path="/estimates" component={EstimateSumm} exact="true" />
      <Route path="/new_clients" component={NewClients} exact="true" /> New
    </Routes>
  </Router>
);

export default AppRouter;
