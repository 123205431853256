import React, { useState } from 'react';


const DNFForm = ({
    client,
    title,
    handleSubmit,
    submitForm,
    register,
    clientAction,
    jobplus,
    dnfs,
    getValues,
    setValue
  }) => {

  const[chars, setChars] = useState(0);

  const changeMenu = (e) => {
    e.preventDefault();
    clientAction('completedJobs', null);
  }

  const countLetters = () => {
    const text = getValues("dnfdesc");
    if(text && text.length>100){
      setValue('dnfdesc', text.substring(0,100));
    }
    setChars(text.length);
  }

  const displayCurrentDnfs = () => {
    return dnfs && dnfs.map((dnf) =>
      <div key={dnf.dnfNotesMustBeFilled}>
        <span className='mr-3'>{dnf.dnfid}</span>
        <span className='mr-4'>{dnf.dnfdesc}</span>
        <span className='mr-4'>{ dnf.datebi ? 'Resolved' : 'Unresolved' }</span>
      </div>
    )
  }

  return (
    <div className='box'>

      <nav className='breadcrumb'>
        <ol className='breadcrumb'>
          <li onClick={changeMenu}><a href="#">Completed Jobs</a></li>
          <li className='is-active' onClick={changeMenu}><a href="#">Quality Issue</a></li>
        </ol>
      </nav>

      <h2 className='title is-5 has-text-centered'>Report Quality Issue</h2>
      <form onSubmit={handleSubmit(submitForm)}>
        <div className='field has-text-weight-bold '>JobID: { jobplus && jobplus.job.jobid}</div>
        <div className='field has-text-weight-bold '>Address: { jobplus && jobplus.address}</div>

        <div className='field'>
          <div className='control'>
            <label className='label' htmlFor='dnfdesc'>Quality Issue(100 characters max { chars>0 ? '- ' + chars : null})</label>
            <textarea className='textarea' id='dnfdesc' onKeyUp={countLetters} {...register('dnfdesc')} placeholder='Enter details here...'/>
          </div>
        </div>
        <button className='button is-primary' type='submit'>Submit Quality Issue</button>
      </form>
      <hr/>
      { dnfs ? <span className='has-text-weight-bold is-danger mb-2'>Current Quality Issues</span> : null }
      { displayCurrentDnfs() }
    </div>)
}

export default DNFForm;
